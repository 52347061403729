/*
 * @Author: liumeikun
 * @Date: 2023-01-03 10:41:54
 * @LastEditors: Do not edit
 * @LastEditTime: 2023-10-27 18:15:34
 * @filePath: Do not edit
 */
export default [{
    path: '/home',
    title: '首页',
    component: () =>
        import ( /* webpackChunkName: "BackSys" */ '../views/BackSys/home.vue'),
}, {
    path: '/tenant',
    title: '租户管理',
    component: () =>
        import ( /* webpackChunkName: "BackSys" */ '../views/BackSys/tenant.vue'),
}, {
    path: '/module',
    title: '模块配置',
    component: () =>
        import ( /* webpackChunkName: "BackSys" */ '../views/BackSys/module.vue'),
}, {
    path: '/log',
    title: '日志管理',
    component: () =>
        import ( /* webpackChunkName: "BackSys" */ '../views/BackSys/log.vue'),
}]