import {
    post,
    get,
    post_old,
    Delete,
} from "../request"

export default {
    // 添加用户
    addUser(data) {
        return post('/user/add', data);
    },
    //查询用户列表
    searchUser(curPage, pageSize, id, keyword) {
        let params = {
            curPage: curPage,
            pageSize: pageSize,
            id: id,
            keyword: keyword
        }
        return post('/user/list', params);
    },
    //编辑用户
    editUser(data) {
        return post('/user/edit', data);
    },
    //删除用户
    delUser(data) {
        return Delete('/user/del', data);
    },
    //分配角色
    // delUser() {
    //     return post('/user/del', data);
    // },
    //用户查看-基本信息
    detailsUser(data) {
        return post_old('/tenant/queryTenantDetails', data);
    },
}