<template>
  <div class="progress-wrap">
    <div class="progress">
      <div
        class="progress-inner"
        :style="{ width: width > 100 ? 100 + '%' : width + '%' }"
      ></div>
    </div>
    <span class="p-text">{{ text ? text : width + "%" }}</span>
  </div>
</template>

<script>
export default {
  props: ["text", "width"],
  data() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
.progress-wrap {
  display: flex;
  align-items: center;
  .progress {
    width: 80%;
    height: 4px;
    margin-right: 12px;
    background: rgba(255, 255, 255, 0.1);
    border-radius: 2px;
    .progress-inner {
      height: 100%;
      background: linear-gradient(
        90deg,
        rgba(74, 155, 255, 0.1),
        rgba(74, 155, 255, 1)
      );
      border-radius: 2px;
      opacity: 1;
      transition: all 0.3s;
    }
    .p-text {
      font-size: 15px;
    }
  }
}
</style>