//首页api
import {
    post,
} from "../request"

export default {
    // 登录
    login(params) {
        return post('/auth/login', params);
    },
    logout(params) {
        return post('/auth/logout', params);
    },
    //系统名称及logo
    getSystemMsg(params) {
        return post('/systemMsg/querySystemMsg', params);
    },

    // // 获取服务器时间
    // getServerTime() {
    //     let data = {
    //         "SearchType": "ServerTime",
    //     }
    //     return post(url, data);
    // },
    // getMapList() {
    //     return post('../../../public/guangdong.json', )
    // }
}