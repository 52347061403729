import {
    post,
    get,
    post_old
} from "../request"

export default {
    ///设备状态通用颜色
    getStatusColor(data) {
        return post_old('/station/enum', data);
    },

    //大屏首页-基本信息
    getBaseMessage(data) {
        return post_old('/monitor/largeIndex/queryBaseMessage', data);
    },
    //大屏首页-发电量
    getGenerating(data) {
        return post('/monitor/largeIndex/queryGeneratingCapacity', data);
    },
    //大屏首页-发电量完成率
    getPowerRank(data) {
        return post_old('/monitor/largeIndex/queryActuallyDivPlanList', data);
    },
    //大屏首页-年实发-计划比
    getPlanList(data) {
        return post('/monitor/largeIndex/queryYearActuallyDivPlanList', data);
    },
    //新大屏首页-查询装机容量
    getCapacity(data) {
        return post('/monitor/largeIndex/queryCapacity', data);
    },
    //新大屏首页-查询能源类型占比
    getEnergy(data) {
        return post('/monitor/largeIndex/queryEnergyProportion', data);
    },
    //新大屏首页-中间基本信息
    getBaseMessage(data) {
        return post('/monitor/largeIndex/queryAllBaseMessage', data);
    },
    //新大屏首页-中间基本信息
    getThreeHourPowerCurve(data) {
        return post('/monitor/largeIndex/queryThreeHourPowerCurve', data);
    },
    //新大屏首页-业务指标
    getKqi(data) {
        return post('/monitor/largeIndex/queryKqi', data);
    },

    //大屏首页-累计安全运行天数
    getSafeTimeDays(data) {
        return post('/monitor/largeIndex/querySafeTimeDays', data);
    },
    //大屏首页-地图
    getStationMap(data) {
        return post_old('/monitor/largeIndex/queryStationMap', data);
    },

    // 大屏首页-查询运行状态
    getRunningStatus(data) {
        return post('/monitor/largeIndex/queryDynamicRunningStatus', data);
    },
    //大屏首页 -功率曲线
    getPowerCurve(data) {
        return post_old('/monitor/largeIndex/queryPowerCurve', data);
    },
    //大屏首页-节能减排
    getLessWaste(data) {
        return post('/monitor/largeIndex/queryLessWaste', data);
    },


    //场站矩阵-查询场站下拉框
    getMatrixSearchList(data) {
        return post_old('/monitor/matrix/queryStationSearchList', data);
    },
    //场站矩阵-查询场站实时数据
    getStationRealTimeData(data) {
        return post('/monitor/matrix/queryStationRealTimeDataList', data);
    },
    //场站矩阵-查询场站All设备状态
    getDeviceStatusSumList(data) {
        return post('/monitor/matrix/queryDeviceStatusSumList', data);
    },
    //场站矩阵-设备状态实时数据
    getDeviceStatus(data) {
        return post('/monitor/matrix/queryDeviceStatusRealTimeDataList', data);
    },
    //场站矩阵-查询场站列表
    getMatrixList(data) {
        return post('/monitor/matrix/queryStationMainParaList', data);
    },
    //场站矩阵-查询设备信息
    getMatrixMessage(data) {
        return post('/monitor/matrix/queryDeviceMessage', data);
    },
    //场站矩阵-查询设备信息
    getStationMainParaList(data) {
        return post('/monitor/matrix/queryStationMainParaList', data);
    },



}