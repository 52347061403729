import {
    post,
    get,
    post_old
} from "../request"

export default {
    // 总览
    queryOverView(data) {
        return post('/backIndex/overview/queryOverView', data);
    },
    //查询访问量折线图
    queryVisitLineChart(data) {
        return post_old('/backIndex/visitStatis/queryVisitLineChart', data);
    },

    //访问量排行榜
    queryMonthVisitRank(data) {
        return post_old('/backIndex/visitStatis/queryMonthVisitRank', data);
    },

    // 接入统计-查询场站数量饼图
    queryStationNumPieChart(data) {
        return post('/backIndex/accessStatis/queryStationNumPieChart', data);
    },
    //接入统计-查询设备数量柱状图
    queryDeviceNumBarChart(data) {
        return post('/backIndex/accessStatis/queryDeviceNumBarChart', data);
    },
    //接入统计-查询数据量折线图
    queryDataNumBarChart(data) {
        return post('/backIndex/accessStatis/queryDataNumBarChart', data);
    },
}