<template>
  <!-- 功率曲线(3小时) -->
  <Echarts ref="powerCurve" :options="options"></Echarts>
</template>
<script>
import Echarts from "@/components/charts/chartsModle.vue";
export default {
  components: {
    Echarts,
  },
  data() {
    return {
      options: null,
    };
  },
  methods: {
    initChart() {
      let echarts = this.$echarts;
      let option = {
        backgroundColor: "rgba(0,0,0,0)", //背景色
        series: [
          {
            type: "liquidFill", //水位图
            radius: "90%", //显示比例
            center: ["50%", "50%"], //中心点
            amplitude: 2, //水波振幅
            data: [0.35, 0.35], // data个数代表波浪数
            color: [
              {
                type: "linear",
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                colorStops: [
                  {
                    offset: 0,
                    color: "rgba(67, 204, 255, 0.6)",
                  },
                  {
                    offset: 1,
                    color: "rgba(40, 130, 240, 0.6)",
                  },
                ],
                globalCoord: false,
              },
            ], //波浪颜色
            backgroundStyle: {
              borderWidth: 1, //外边框
              // borderColor: '#23cc72', //边框颜色
              color: "RGBA(51, 66, 127, 0.7)", //边框内部填充部分颜色
            },
            label: {
              //标签设置
              position: ["50%", "45%"],
              formatter: "", //显示文本,
              textStyle: {
                fontSize: "10px", //文本字号,
                color: "#fff",
              },
            },
            outline: {
              borderDistance: 0,
              itemStyle: {
                borderWidth: 2,
                borderColor: {
                  type: "linear",
                  x: 0,
                  y: 0,
                  x2: 0,
                  y2: 1,
                  colorStops: [
                    {
                      offset: 0,
                      color: "#52CEEE",
                    },
                    {
                      offset: 0.5,
                      color: "#269EDC",
                    },
                    {
                      offset: 1,
                      color: "#0C82D2",
                    },
                  ],
                  globalCoord: false,
                },
              },
            },
          },
        ],
      };

      this.options = option;
    },
  },
  mounted() {
    this.initChart();
  },
};
</script>

<style lang="scss" scoped>
</style>