/*
 * @Author: liumeikun
 * @Date: 2023-09-20 18:29:18
 * @LastEditors: Do not edit
 * @LastEditTime: 2023-10-27 18:21:31
 * @filePath: Do not edit
 */
export default [{
        path: 'windResources',
        title: '风资源对比分析',
        name: "windResources",
        component: () =>
            import ( /* webpackChunkName: "analyse" */ '../views/analyse/windResources.vue'),
        meta: {
            title: "风资源对比分析",
            keepAlive: true
        },
    },
    {
        path: 'electricQuantitys',
        title: '电量对比分析',
        name: "electricQuantitys",
        component: () =>
            import ( /* webpackChunkName: "analyse" */ '../views/analyse/electricQuantity.vue'),
        meta: {
            title: "电量对比分析",
            keepAlive: true
        },
    },
    {
        path: 'faultComparison',
        title: '故障对比分析',
        name: "faultComparison",
        component: () =>
            import ( /* webpackChunkName: "analyse" */ '../views/analyse/faultComparison.vue'),
        meta: {
            title: "故障对比分析",
            keepAlive: true
        },
    },
    {
        path: 'PV',
        title: '光伏智能分析',
        name: "PV",
        component: () =>
            import ( /* webpackChunkName: "analyse" */ '../views/analyse/pv.vue'),
        meta: {
            title: "光伏智能分析",
            keepAlive: true
        },
    },
]