<template>
  <div class="footer-badge">
    <div class="badge">
      <el-badge  
        class="item" 
        v-for="(item,index) in falutStatus" 
        :key="index" 
        :max="999" 
        :value="item.value"
        :class="item.className">
        <span size="small" @click="openAlarm(item.name, item.index,item.value)">{{item.name}}</span>
      </el-badge>
    </div>
    <el-dialog
      :title="title"
      width="65%"
      :close-on-click-modal="false"
      :visible.sync="AlarmTableVisible"
      @close="search = ''"
    >
      <div class="header-part">
        <div style="width: 4rem">
          <el-input
            class="search-input"
            placeholder="输入场站名称、设备名称或告警内容"
            v-model.trim="search"
            clearable
            @input="doFilter()"
          >
            <i slot="prefix" class="el-input__icon icon-search"></i>
          </el-input>
        </div>
        <div class="header-btn">
          <el-button type="primary" size="small" @click="handle(1)"
            >确认</el-button
          >
          <el-button
            size="small"
            class="reset-button"
            type="primary"
            @click="handle(2)"
            >忽略</el-button
          >
          <el-button
            size="small"
            class="reset-button"
            type="primary"
            @click="handle(3)"
            >加入白名单</el-button
          >
        </div>
      </div>
      <div class="AlarmTable">
        <el-table
          ref="AlarmTable"
          :data="alarmData"
          @selection-change="handleSelectionChange"
          height="100%"
          style="width: 100%"
          v-loading="loading1"
          element-loading-text="loading"
          element-loading-background="rgba(16,41,82,0.5)"
        >
          <el-table-column
            type="selection"
            :selectable="selectable"
            min-width="5%"
          ></el-table-column>
          <el-table-column
           v-for="item in tableHeaders"
            :prop="item.prop"
            :label="item.label"
            align="center"
            :min-width="item.width"
            :key="item.prop"
            :show-overflow-tooltip="true">
            <template slot-scope="scope">
              <span v-if="item.prop=='runCode'" class="equipment" @click="jumpEQ(scope.row)">{{
                scope.row.runCode
              }}</span>
              <span v-else>{{ scope.row[item.prop]}}</span>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-sizes="[10, 20, 30, 50]"
        :page-size="pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </el-dialog>

    <el-dialog
      title="加入白名单"
      :visible.sync="whiteListDialogVisible"
      width="30%"
      :close-on-click-modal="false">
      <el-radio-group v-model="whiteList">
        <el-radio :label="1">当前设备</el-radio>
        <el-radio :label="2">当前场站</el-radio>
      </el-radio-group>
      <div slot="footer" class="dialog-footer">
        <el-button
          size="small"
          @click="(whiteListDialogVisible = false), (whiteList = 0)"
          >取 消</el-button>
        <el-button size="small" type="primary" @click="doOperation(0)"
          >确定</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { debounce } from "@/utils/mUtils.js";
export default {
  data() {
    return {
      fault: 0,
      alarm: 0,
      tips: 0,
      title: "",
      search: "",
      AlarmTableVisible: false,
      alarmData: [],
      multipleSelection: [],
      whiteListDialogVisible: false,
      whiteList: 0,
      currentPage: 1,
      pageSize: 10,
      total: 0,
      loading1: false,
      timer: null,
      type: "",
      tableHeaders: [
        // { prop: "number", label: "序号", width: "6%" },
        { prop: "happenTime", label: "发生时间", width: "15%" },
        { prop: "farmName", label: "场站名称", width: "20%" },
        { prop: "runCode", label: "设备名称", width: "12%" },
        { prop: "codeStr", label: "告警内容", width: "20%" },
      ],
      falutStatus:[
        {name:"故障",value:0,index:3,className:"fault"},
        {name:"警告",value:0,index:2,className:"alarm"},
        {name:"提示",value:0,index:1,className:"tips"},
      ]
    };
  },
  mounted() {
    this.searchHandler = debounce(() => {
      this.currentPage = 1;
      this.doSearch();
    }, 1000);
    this.getFaultList();
    this.timer = setInterval(() => {
      setTimeout(this.getFaultList, 0);
    }, 1000 * 10);
  },
  methods: {
    openAlarm(title, val,value) {
      this.title = title;
      this.type = val;
      let list = JSON.parse(sessionStorage.getItem("menuList"));
      let arr = [];
      let count = [];
      arr = [...list, ...list.map((a) => a.children).flat()];
      count = arr.filter((i) => {
        return i.path.includes("realTimeAlarm");
      });
      if (count.length > 0) {
        this.doSearch(val);
        if (value>0) {
          this.AlarmTableVisible = true;
        }
      }
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.doSearch();
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.doSearch();
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    selectable(row, index) {
      if (row.confirmTime) {
        return false; //不可勾选
      } else {
        return true; //可勾选
      }
    },
    jumpEQ(row) {
      if (row.farmType == 1) {
        this.$router.push({
          path: "/monitor/fanDetails",
          query: { id: row.deviceCode, code: row.farmCode },
        });
      } else if (row.farmType == 2) {
        this.$router.push({
          path: "/monitor/inverterDetails",
          query: { id: row.deviceCode, code: row.farmCode },
        });
      }
      this.AlarmTableVisible = false;
      this.whiteListDialogVisible = false;
    },
    handle(val) {
      if (this.multipleSelection.length <= 0) {
        this.$message({
          type: "warning",
          message: "请先选择数据",
        });
      } else {
        if (val == 1 || val == 2) {
          this.doOperation(val);
        } else {
          this.whiteListDialogVisible = true;
        }
      }
    },
    doFilter() {
      this.searchHandler();
    },
    async getFaultList() {
      try {
        let res = await this.$api.faultAlarm.getFaultList({ type: 1 });
        if (res.code == 20000) {
          let data = res.data || [];
          data.map((item) => {
            if (item.warnTypeStr == "故障") {
              this.falutStatus[0].value = item.sum;
            } else if (item.warnTypeStr == "警告") {
              this.falutStatus[1].value = item.sum;
            } else if (item.warnTypeStr == "提示") {
              this.falutStatus[2].value = item.sum;
            }
          });
        }
      } catch (error) {}
    },
    async doSearch(val) {
      this.alarmData = [];
      this.loading1 = true;
      try {
        let res = await this.$api.faultAlarm.getRealTimeWarnList({
          id: "",
          curPage: this.currentPage,
          keyword: this.search,
          pageSize: this.pageSize,
          warnType: this.type,
        });
        this.loading1 = false;
        if (res.code == 20000) {
          this.alarmData = res.data.records || [];
          this.total = res.data.total || 0;
          this.currentPage = res.data.current || 1;
          this.pageSize = res.data.size || 10;
          this.$nextTick(() => {
            this.$refs.AlarmTable.doLayout();
          });
        }
      } catch (error) {
        this.loading1 = false;
      }
    },
    async doOperation(val) {
      let whiteArr = [];
      this.multipleSelection.forEach((item) => {
        whiteArr.push(item.id);
      });
      try {
        let res = await this.$api.faultAlarm.doOperate({
          ids: whiteArr,
          isAllDevice: this.whiteList,
          type: val,
        });
        if (res.code == 20000) {
          this.whiteListDialogVisible = false;
          this.$message.success(res.message);
          this.getFaultList();
          this.doSearch();
          this.$refs.multipleTable.clearSelection();
        } else {
          this.$message.error(res.message);
        }
        this.search = "";
      } catch (error) {}
    },
  },
  beforeDestroy() {
    clearInterval(this.timer);
  },
};
</script>
<style lang="scss">
.footer-badge {
  position: relative;
  height: 100%;
  text-align: left;
  .badge {
    .item {
      height: 100%;
      line-height: 0.5rem;
      margin: 0 0.5rem;
      font-size: 0.18rem;
      cursor: pointer;
    }
  }
  .el-badge__content.is-fixed {
    position: absolute;
    top: 0.16rem !important;
    right: -0.05rem !important;
    transform: translateY(-50%) translateX(100%);
    cursor: default;
  }
  .el-badge__content {
    font-size: 0.14rem;
    border-radius: 0.1rem !important;
    height: 0.19rem !important;
    line-height: 0.19rem !important;
    padding: 0 0.05rem !important;
    border-color: transparent !important;
    cursor: pointer;
  }
  .fault .el-badge__content {
    background-color: #ff1010;
  }
  .alarm .el-badge__content {
    background-color: #ff7e00;
  }
  .tips .el-badge__content {
    background-color: #0791a6;
  }
  .header-part {
    display: flex;
    justify-content: space-between;
    margin-bottom: 0.16rem;
    ::v-deep input::-webkit-input-placeholder {
      font-size: 0.16rem;
    }
    ::v-deep input::-moz-placeholder {
      font-size: 0.16rem;
    }

    ::v-deep input:-moz-placeholder {
      font-size: 0.16rem;
    }

    ::v-deep input:-ms-input-placeholder {
      font-size: 0.16rem;
    }
  }
  .AlarmTable {
    height: 4.5rem;
    border: 1px solid #1b3d72;
    margin-bottom: 0.16rem;
  }
  .equipment {
    display: inline-block;
    text-decoration: underline;
    cursor: pointer;
  }
  .el-pager {
    height: 0.28rem;
    line-height: 0.28rem;
  }
  .search-input .el-input__inner {
    border-radius: 0.03rem !important;
    border: 1px solid rgba(43, 138, 255, 0.6) !important;
    box-sizing: border-box;
    color: #fff !important;
    height: 0.36rem !important;
    font-size: 0.16rem;
    line-height: 0.36rem !important;
  }
  .search-input .el-input__icon.icon-search {
    display: inline-block;
    margin-right: 0.075rem;
    line-height: 0.36rem;
    background-image: url("../../assets/images/search.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: 0.12rem;
  }
}
</style>