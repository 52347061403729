<template>
  <!-- 装机容量 -->
  <div class="tur-contain">
    <div class="left">
      <p>单元：MW</p>
      <contain-charts class="contain-charts"></contain-charts>
    </div>
    <div class="right">
      <tag-title title="能源类型占比图"></tag-title>
      <div class="type-charts">
        <contain-pie-charts
          :data="data"
          @getIndex="getIndex"
        ></contain-pie-charts>
      </div>
      <div class="type-info">
        <p>
          <span class="name">装机容量：</span>
          <span class="value">{{ Capacity }}</span>
          <span class="unit"> MW</span>
        </p>
        <p>
          <span class="name">年发电量：</span>
          <span class="value">{{ GeneratingCapacity }}</span>
          <span class="unit"> 万kWh</span>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import ContainCharts from "../com/containCharts.vue";
import ContainPieCharts from "../com/containPieCharts.vue";
import TagTitle from "../com/tagTitle.vue";
export default {
  components: {
    ContainCharts,
    ContainPieCharts,
    TagTitle,
  },
  data() {
    return {
      Capacity: 0,
      GeneratingCapacity: 0,
      lightCapacity: 0,
      lightGeneratingCapacity: 0,
      windCapacity: 0,
      windGeneratingCapacity: 0,
      data: [],
    };
  },
  created() {
    this.getEnergy();
  },
  methods: {
    getIndex(val) {
      if (val == 0) {
        this.Capacity = this.windCapacity;
        this.GeneratingCapacity = this.windGeneratingCapacity;
      } else {
        this.Capacity = this.lightCapacity;
        this.GeneratingCapacity = this.lightGeneratingCapacity;
      }
    },
    async getEnergy() {
      try {
        let res = await this.$api.monitor.getEnergy();
        if (res.code == 20000) {
          this.lightCapacity = res.data.lightCapacity;
          this.lightGeneratingCapacity = res.data.lightGeneratingCapacity;

          this.windCapacity = res.data.windCapacity;
          this.windGeneratingCapacity = res.data.windGeneratingCapacity;

          (this.Capacity = res.data.windCapacity),
            (this.GeneratingCapacity = res.data.windGeneratingCapacity),
            this.data.push(res.data.windCapacity);
          this.data.push(res.data.lightCapacity);
        }
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.tur-contain {
  display: flex;
  height: 100%;
  width: 100%;
  font-size: 15px;
  > div {
    display: flex;
    flex-direction: column;
    width: 50%;
    height: 100%;
  }
  .left {
    padding-top: 16px;
    > p {
      padding-left: 20px;
      font-size: 11px;
    }
    .contain-charts {
      flex: 1;
    }
  }
  .right {
    padding-left: 30px;
    .type-charts {
      flex: 1;
    }
    .type-info {
      padding-left: 48px;
      span {
        margin-right: 20px;
        font-size: 13px;
        font-weight: bold;
        color: #fefefe;
        line-height: 20px;
      }
    }
  }
}
</style>