import {
    post,
    get,
    post_old,
    Delete
} from "../request"

export default {
    // 添加模块
    addModule(data) {
        return post('/permission/add', data);
    },
    //查询模块列表
    searchModule(data) {
        return get('/permission/queryAsForest', data);
    },
    //编辑模块
    editModule(data) {
        return post('/permission/update', data);
    },
    //删除模块
    delModule(data) {
        return Delete('/permission/del', data);
    },
    //模块排序
    orderModule(data) {
        return post('/permission/editTreeOrderNum', data);
    },
}