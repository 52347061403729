<template>
    <!-- 运行状态 -->
    <ul class="run-status">
        <li class="headers">
            <p v-for="(item,index) in  statusList" :key="index">{{item.desc}}</p>
        </li>
        <!-- 风电 -->
        <li class="wind">
            <div class="name-wrap">
                <i class="com-icon icon-windpower"></i>
                <p class="name">风电</p>
            </div>
            <div class="value-wrap" v-for="(item,index) in  windDynamicStatusList" :key="index">
                <i v-if="index>0" class="flag" :class="'blink'+(index-1).toString()" :style="'background:' +color[item.status]"></i>
                <span>{{item.num}}</span>
            </div>
        </li>
        <!-- 光伏 -->
        <li class="light">
            <div class="name-wrap">
                <i class="com-icon icon-runlight"></i>
                <p class="name">光伏</p>
            </div>
            <div class="value-wrap" v-for="(item,index) in  lightDynamicStatusList" :key="index">
                <i v-if="index>0" class="flag" :class="'blink'+(index-2).toString()" :style="'background:' +color[item.status]"></i>
                <span>{{item.num}}</span>
            </div>
        </li>
    </ul>
</template>

<script>
export default {
  data() {
        return {
            statusList:[],
            time:null,
            color:{},
            windDynamicStatusList:[],
            lightDynamicStatusList:[],
        };
    },
    created() {
        this.getStatusColor();
        this.getRunningStatus();
        this.time = setInterval(()=>{
            this.getRunningStatus();
        },1000 * 6)
    },
    methods: {
        async getStatusColor(){
           try {
                let res = await this.$api.monitor.getStatusColor({type:14})
                if(res.code ==20000){
                    res.data.map(item =>{
                      this.color[item.id] = item.name
                    })
                }
            } catch (error) {
                console.log(error)
            }
        },
        async getRunningStatus(){
            try {
                let res = await this.$api.monitor.getRunningStatus()
                if(res.code ==20000){
                    this.statusList =  res.data.windDynamicStatusList
                    this.windDynamicStatusList = res.data.windDynamicStatusList
                    this.lightDynamicStatusList = res.data.lightDynamicStatusList
                }
            } catch (error) {
                console.log(error)
            }
        }
    },
    beforeDestroy(){
        clearInterval(this.time)
        this.time = null
    }
};
</script>

<style lang="scss" scoped>
// 风电
.icon-windpower {
    background-image: url(@/assets/images/screem/icon-windpower.png);
}
// 光伏
.icon-runlight {
    background-image: url(@/assets/images/screem/icon-runlight.png);
}
// .flag-run {
//     background: #19E085 !important;
// }
// .flag-stanby {
//     background: #26A6FF !important;
// }
// .flag-stop {
//     background: #FD7C13 !important;
// }
// .flag-fault {
//     background: #CA2121 !important;
// }
// .flag-maintain {
//     background: #F0E925 !important;
// }
// .flag-lost {
//     background: #ACACAC !important;
// }
.run-status {
    padding-top: 24px;
    font-size: 15px;
    li {
        padding-left: 48px;
    }
    .headers {
        display: flex;
        height: 20px;
        > p {
            width: 14.2%;
            font-size: 11px;
            text-align: center;
        }
    }
    .wind, .light {
        position: relative;
        display: flex;
        margin-bottom: 20px;
        height: 60px;
        .name-wrap {
            position: absolute;
            left: 0;
            bottom: 0;
            .com-icon {
                width: 28px;
                height: 28px;
            }
            i {
                margin-bottom: 8px;
            }
            .name {
                font-size: 11px;
            }
        }
        .value-wrap {
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            align-items: center;
            width: 14.2%;
            .flag {
                display: inline-block;
                width: 10px;
                height: 10px;
                margin: 16px 0 10px;
                border-radius: 50%;
                // background: #ACACAC;
                animation: blink 1.5s linear infinite;
            }
        }
    }
}

.blink1 {
    animation-delay: 0.5s !important;
}
.blink2 {
    animation-delay: 1s !important;
}
.blink3 {
    animation-delay: 1.5s !important;
}
.blink4 {
    animation-delay: 2s !important;
}
.blink5 {
    animation-delay: 2.5s !important;
}
//闪烁
@keyframes blink {
    0% {
        opacity: 1;
    }
    10% {
        opacity: 0.6;
    }
    20% {
        opacity: 1;
    }
    100% {
        opacity: 1;
    }
}
</style>