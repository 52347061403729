/*
 * @Author: liumeikun
 * @Date: 2023-08-11 17:46:15
 * @LastEditors: Do not edit
 * @LastEditTime: 2023-10-13 16:44:39
 * @filePath: Do not edit
 */
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
//全局引入
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import * as echarts from 'echarts';
import 'echarts-gl';
import tool from "../src/utils/store"
// 引入水球图
import 'echarts-liquidfill'
import api from './request/api';
import './utils/rem';
import '@/assets/style/iconfont.css'
import '@/assets/font/font.css'
import '@/assets/font/unidreamled.css'
import '@/assets/font/ConthraxSb.css'
import './utils/dateFormat';
import numFormat from './utils/numFormat' //数值千位化处理
import install from './utils/install' //数值千位化处理
import Print from 'vue-print-nb'

Object.keys(numFormat).forEach(key => { //数值千位化处理{{ num | nums}}
    Vue.filter(key, numFormat[key])
})

Vue.use(tool)
Vue.use(install); // 在VUE原型上配置
Vue.use(ElementUI); /* 引入网络请求,把api挂载到Vue实例中 */
Vue.use(Print); //打印

Vue.prototype.$api = api;
Vue.prototype.$bus = new Vue()
Vue.prototype.$echarts = echarts;
Vue.config.productionTip = false

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')