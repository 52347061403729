<template>
    <!-- 节能减排 -->
    <ul class="energy-save">
        <li class="item">
            <div class="water-box">
                <i class="com-icon es-i-tree"></i>
                <div class="water">
                    <water-ball-charts></water-ball-charts>
                </div>
            </div>
            <div class="info">
                <p class="name">总等效植树</p>
                <p class="v-wrap">
                    <span class="value">{{treePlanting |nums}}</span>
                    <span class="unit">棵</span>
                </p>
            </div>
        </li>
        <li class="item revers">
            <div class="water-box bounce1">
                <i class="com-icon es-i-co2"></i>
                <div class="water">
                    <water-ball-charts></water-ball-charts>
                </div>
            </div>
            <div class="info">
                <p class="name">总减排量CO2</p>
                <p class="v-wrap">
                    <span class="value">{{co2 |nums}}</span>
                    <span class="unit">吨</span>
                </p>
            </div>
        </li>
        <li class="item">
            <div class="water-box bounce2">
                <i class="com-icon es-i-coal"></i>
                <div class="water">
                    <water-ball-charts></water-ball-charts>
                </div>
            </div>
            <div class="info">
                <p class="name">总节煤量</p>
                <p class="v-wrap">
                    <span class="value">{{sectionCoal |nums}}</span>
                    <span class="unit">吨</span>
                </p>
            </div>
        </li>
    </ul>
</template>
<script>
import whhProgress from "../com/progress.vue";
import WaterBallCharts from "../com/waterBallCharts.vue";
export default {
    components: {
        whhProgress,
        WaterBallCharts,
    },
    data() {
        return {
            treePlanting:0,
            co2:0,
            sectionCoal:0,
        };
    },
    created() {
        this.getLessWaste()
    },
    methods:{
        async getLessWaste(){
            try {
                let res = await this.$api.monitor.getLessWaste()
                if(res.code ==20000){
                    this.co2 = res.data.co2
                    this.sectionCoal = res.data.sectionCoal
                    this.treePlanting = res.data.treePlanting
                }
            } catch (error) {
                console.log(error)
            }
        }
    },
};
</script>

<style lang="scss" scoped>
.es-i-tree {
    background-image: url(@/assets/images/screem/es-i-tree.png);
}
.es-i-co2 {
    background-image: url(@/assets/images/screem/es-i-co2.png);
}
.es-i-coal {
    background-image: url(@/assets/images/screem/es-i-coal.png);
}
.energy-save {
    height: 100%;
    width: 100%;
    padding: 20px 10px;
    font-size: 15px;
    .item {
        display: flex;
        align-items: center;
        height: 33%;
        .water-box {
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 0 8px;
            animation: bounce 5s linear infinite;
            .com-icon {
                position: absolute;
                width: 40px;
                height: 40px;
                left: 50%;
                top: 35%;
                margin: -20px 0 0 -20px;
                z-index: 1;
            }
            .water {
                width: 48px;
                height: 48px;
            }
        }
        .info {
            line-height: 30px;
            font-size: 11px;
            padding: 0 8px;
            .v-wrap {
                font-size: 15px;
                padding-left: 20px;
                > span {
                    font-weight: bold;
                    color: #4eecff;
                }
                .value {
                    margin-right: 8px;
                }
            }
        }
        &.revers {
            flex-direction: row-reverse;
        }
    }
}

.bounce1 {
    animation-delay: 0.75s !important;
}
.bounce2 {
    animation-delay: 1.5s !important;
}
//弹动
@keyframes bounce {
    /* scaleY(.98) 模拟的是球落下的时候，受重力影响而产生的变形 */
    from,
    to {
        transform: translateY(10px) scale(1.5);
    }
    50% {
        transform: translateY(30px) scale(0.8);
    }
}
</style>