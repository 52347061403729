import {
    post,
    post_old,
    postExport,
} from "../request"

export default {
    //场站详情-查询设备类型下拉框
    getstationSearchList(data) {
        return post_old('/monitor/matrix/queryStationSearchList', data);
    },
    //场站详情-查询设备类型下拉框
    getDeviceSearchList(data) {
        return post_old('/monitor/stationDetails/queryStationModuleSearchList', data);
    },

    //场站详情-查询状态
    getStatusList(data) {
        return post_old('/monitor/stationDetails/queryStatusList', data);
    },
    //场站详情-查询集电路关联值下拉框
    getElectricalCircuitList(data) {
        return post_old('/monitor/stationDetails/queryAssociatedValueList', data);
    },
    //场站详情-查询矩阵视图
    getMatrixList(data) {
        return post_old('/monitor/stationDetails/queryMatrixList', data);
    },
    //场站详情-查询风机列表视图
    getTurbineList(data) {
        return post('/monitor/stationDetails/queryTurbineList', data);
    },
    //场站详情-查询综合指标
    getAllIndex(data) {
        return post_old('/monitor/stationDetails/queryStationAggregativeIndicator', data);
    },
    //场站详情-查询当日风速功率曲线
    getDayPowerCurve(data) {
        return post_old('/monitor/stationDetails/queryDayPowerCurve', data);
    },

    /*----------------------------- 风机详情 ---------------- */
    //风机详情-查询风机基本信息
    getFanTurbineBase(data) {
        return post_old('/monitor/deviceDetails/queryTurbineBaseMessage', data);
    },
    //风机详情-查询风机运行信息
    getFanTurbineKey(data) {
        return post_old('/monitor/deviceDetails/queryTurbineKeyData', data);
    },
    //风机详情-查询风机温度参数列表
    getFanTurbineTempParaList(data) {
        return post_old('/monitor/deviceDetails/queryTurbineTempParaList', data);
    },
    //风机详情-查询设备实时趋势 **逆变器详情-实时趋势
    getFanPowerCurve(data) {
        return post_old('/monitor/deviceDetails/queryDevicePowerCurve', data);
    },
    //风机详情-查询设备故障列表
    getFanFaultList(data) {
        return post_old('/monitor/deviceDetails/queryDeviceFaultList', data);
    },
    //风机详情-查询设备状态列表
    getFanStatusList(data) {
        return post_old('/monitor/deviceDetails/queryDeviceStatusList', data);
    },
    //风机详情-查询设备基础信息
    getFanBase(data) {
        return post_old('/monitor/stationDetails/queryStationBaseAttribute', data);
    },
    //风机详情-查询设备测点
    getFanMapList(data) {
        return post('/monitor/deviceDetails/queryTurbineMapList', data);
    },
    //风机详情-查询设备测点chart
    getFanMapLineChart(data) {
        return post('/monitor/deviceDetails/queryTurbineMapLineChart', data);
    },


    /*----------------------------- 部件详情 ---------------- */
    getFanPointList(data) {
        return post_old('/station/enum', data);
    },

    getFanPointDetail(data) {
        return post_old('/monitor/deviceDetails/queryDeviceRealTimeDataList', data);
    },

    /*------------------------------逆变器详情------------------*/
    getInverterPowerCurve(data) { //电流曲线
        return post_old('/monitor/deviceDetails/queryInverterCurrentPowerCurve', data);
    },
    getInverterMessage(data) { //基本信息
        return post_old('/monitor/deviceDetails/queryInverterMessage', data);
    },
    getInverterDeviceWarnList(data) { //事件告警列表
        return post_old('/monitor/deviceDetails/queryDeviceWarnList', data);
    },
    getInverterDeviceSeries(data) { //事件告警列表
        return post_old('/monitor/deviceDetails/queryInverterPVCurrentCurve', data);
    },




    /*------------------------------汇流箱详情------------------*/
    getCombinerBox(data) { //汇流箱编号
        return post_old('/monitor/deviceDetails/getCombinerBox', data);
    },
    getCombinerBoxList(data) { //表格数据
        return post_old('/monitor/deviceDetails/queryCombinerBoxList', data);
    },
    getDirectCurrentList(data) { //电流echart
        return post_old('/monitor/deviceDetails/queryDirectCurrentList', data);
    },



    /*------------------------------光伏场站详情------------------*/
    getInverterList(data) { //列表视图
        return post('/monitor/stationDetails/queryInverterList', data);
    },


    /*------------------------------箱变详情------------------*/
    //箱变详情
    getBoxSearchList(data) {
        return post_old('/monitor/stationDetails/queryDeviceSearchList', data);
    },

    /*------------------------------升压站------------------*/

    //8.3.11 场站详情-查询svg文件base64编码
    getBoosterSvg(data) {
        return post_old('/monitor/stationDetails/querySvgBase64Code', data);
    },
    //8.3.12 场站详情-查询svg文件列表
    getBoosterSvgList(data) {
        return post_old('/monitor/stationDetails/querySvgList', data);
    },
    //8.3.13 场站详情-查询svg文件实时数据
    getBoosterSvgRealTimeData(data) {
        return post_old('/monitor/stationDetails/querySvgRealTimeData', data);
    },
    //8.3.14 升压站矩阵-查询svg图实时数据详情列表
    getSvgRealTimeDataDetailList(data) {
        return post_old('/monitor/stationDetails/querySvgRealTimeDataDetailList', data);
    },
    //8.3.15 升压站矩阵-查询svg图通讯状态列表
    getSvgCommunicationStatusList(data) {
        return post_old('/monitor/stationDetails/querySvgCommunicationStatusList', data);
    },


    //8.3.16 场站详情-查询升压站事件列表
    getBoosterEventList(data) {
        return post('/monitor/stationDetails/queryBoosterEventList', data);
    },
    //8.3.17 场站详情-导出升压站事件列表
    exportBoosterEventList(data) {
        return postExport('/monitor/stationDetails/exportBoosterEventList', data);
    },

}