export default [{
        path: 'faultTree',
        title: '故障树',
        name: "faultTree",
        component: () =>
            import ( /* webpackChunkName: "powerCurve" */ '../views/health/faultTree.vue'),
        meta: {
            title: "故障树",
            keepAlive: true
        },
    },
    {
        path: 'energyEfficiency',
        title: '能效评估',
        name: "energyHome",
        component: () =>
            import ( /* webpackChunkName: "powerCurve" */ '../views/health/energyHome.vue'),
        meta: {
            title: "能效评估",
            keepAlive: true
        },
    },
]