<template>
  <div>
    <audio
      ref="audio"
      controls
      id="aud"
      autoplay="autoplay"
      style="display: none"
    ></audio>
    <ul class="message-info">
      <li
        class="message-item"
        v-for="(item, index) in alarmList"
        :key="index"
        :class="{
          current: curIndex == index,
          next: nextIndex == index,
          pre: preIndex == index && nextIndex != index,
          nextTwo: nextTwoIndex == index,
        }"
      >
        <span style="color: #be0303"
          ><i class="el-icon-message-solid"></i
        ></span>
        <span class="name" v-if="item.farmName && item.farmName.length < 12">{{
          item.farmName ? "【" + item.farmName + "】" : ""
        }}</span>
        <el-tooltip
          v-else
          class="item"
          effect="dark"
          :content="item.farmName"
          placement="top-start">
          <span class="name">{{
            item.farmName ? "【" + item.farmName.slice(0, 11) + "...】" : ""
          }}</span>
        </el-tooltip>
        <span>{{ item.runCode }}</span>
        <span>{{ item.codeStr }}</span>
        <span>{{ item.happenTime }}</span>
        <span @click="getAlarmDetails(item)" class="alarm-detail"
          >详情 <i class="el-icon-caret-right"></i
        ></span>
      </li>
    </ul>

    <el-dialog
      title="告警详情"
      :append-to-body="true"
      :visible.sync="dialogVisible"
      width="35%"
      :close-on-click-modal="false"
      @close="swiper()">
      <div class="detail-dialog">
        <div
          class="flex"
          style="justify-content: space-between; align-items: center">
          <div>
            <span style="margin-right: 0.25rem">所属场站</span
            ><span>{{ row.farmName }}</span>
          </div>
          <div class="alarm-type">{{ row.statusStr }}</div>
        </div>
        <div>
          <span>告警设备</span><span>{{ row.runCode }}</span>
        </div>
        <div>
          <span>告警等级</span>
          <span
            class="alarm-level"
            v-show="row.warnTypeStr == '故障'"
            :class="row.warnTypeStr == '故障' ? 'red' : ''"
            >{{ row.warnTypeStr }}</span
          >
          <span
            class="alarm-level"
            v-show="row.warnTypeStr == '警告'"
            :class="row.warnTypeStr == '警告' ? 'orange' : ''"
            >{{ row.warnTypeStr }}</span
          >
          <span
            class="alarm-level"
            v-show="row.warnTypeStr == '提示'"
            :class="row.warnTypeStr == '提示' ? 'bule' : ''"
            >{{ row.warnTypeStr }}</span
          >
        </div>
        <div>
          <span>告警内容</span><span> {{ row.codeStr }}</span>
        </div>
        <div>
          <span>发生时间</span><span>{{ row.happenTime }}</span>
        </div>
        <div v-show="row.svgId">
          <span>告警画面</span
          ><span
            style="color: #217cff; border-bottom: 1px solid #217cff;cursor: pointer;"
            @click="getSvg(row.svgId)"
            >查看SVG图</span
          >
        </div>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button
          class="handle-btn"
          size="small"
          @click="handle(row, 2)"
          style="background: #d47506"
          >忽略</el-button
        >
        <el-button class="handle-btn" size="small" @click="handle(row, 1)"
          >确认</el-button
        >
        <el-button class="handle-btn" size="small" @click="handle(row, 0)"
          >加入白名单</el-button
        >
      </div>
    </el-dialog>

    <el-dialog
      title="加入白名单"
      :append-to-body="true"
      :visible.sync="whiteListDialogVisible"
      width="30%"
      :close-on-click-modal="false">
      <el-radio-group v-model="whiteList">
        <el-radio :label="0">当前设备</el-radio>
        <el-radio :label="1">当前场站</el-radio>
      </el-radio-group>
      <div slot="footer" class="dialog-footer">
        <el-button
          size="small"
          @click="(whiteListDialogVisible = false), (whiteList = 0)"
          >取 消</el-button
        >
        <el-button size="small" type="primary" @click="doOperation(0)"
          >确定</el-button
        >
      </div>
    </el-dialog>
    <el-dialog
      title="SVG告警图"
      :append-to-body="true"
      :visible.sync="svgDialogVisible"
      width="60%"
      :close-on-click-modal="false">
      <fualt-svg-dialog :svgId="svgId"></fualt-svg-dialog>
    </el-dialog>
  </div>
</template>
<script>
// import WaveSurfer from "wavesurfer.js";
import boosterStation from "@/utils/boosterStation"
import { Base64 } from 'js-base64'
import FualtSvgDialog from '../../../components/FualtSvgDialog';
export default {
  comments:{FualtSvgDialog},
  data() {
    return {
      alarmList: [],
      curIndex: 0,
      preIndex: -1,
      nextIndex: 1,
      nextTwoIndex: 2,
      timer: null,
      timerTimeout: null,
      dialogVisible: false,
      row: {},
      src: "",
      wavesurfer: null,
      duration: 0,
      whiteList: 0,
      whiteListDialogVisible: false,
      handleData: [],
      id: null,
      timeReal: null,
      svgDialogVisible:false,
      svgId:null,
    };
  },
  mounted() {
    this.getAlarmMessage();
    this.timeReal = setInterval(()=>{
      this.getAlarmMessage();
    },1000 * 10)
  },
  methods: {
    swiper(){
      this.preIndex = this.curIndex
      this.curIndex = this.nextIndex;
      this.nextIndex = this.nextTwoIndex;
      this.nextTwoIndex++;
      this.timerTimeout = setTimeout(() => {
      this.preIndex = -1;
      this.nextTwoIndex = this.nextTwoIndex == this.alarmList.length?0 : this.nextTwoIndex; //0
      },500);
      this.getReadVoice( this.curIndex)
    },
    clearTimer() {
      clearInterval(this.timer);
      clearTimeout(this.timerTimeout);
    },
    handle(row, val) {
      this.handleData = [];
      [row].forEach((item) => {
        this.handleData.push(item.id);
      });
      if (val == 1 || val == 2) { //1确认 2忽略
        this.doOperation(val);
      } else {//0加入白名单
        this.whiteListDialogVisible = true;
      }
    },
    // svg告警图
    getSvg(id) {
      this.svgDialogVisible = true
      this.svgId = id
    },
    async getAlarmMessage() {
      try {
        let res = await this.$api.faultAlarm.getAlarmMessage();
        if (res.code == 20000) {
          this.alarmList = res.data;
          if (this.curIndex == 0) {
            this.getReadVoice(this.curIndex);
          }else{
            
          }
        } else {
          this.alarmList = [];
        }
      } catch (error) {}
    },
    async getAlarmDetails(item) {
      this.clearTimer();
      try {
        let res = await this.$api.faultAlarm.getAlarmDetails({ id: item.id });
        // let res = await this.$api.faultAlarm.getAlarmDetails({ id: 249760 });
        if (res.code == 20000) {
          this.dialogVisible = true;
          this.row = res.data;
        }
      } catch (error) {}
    },

    async getReadVoice(index) {
      let id = this.alarmList[index].id;
      if (this.alarmList[index].audioType == 1) {
        // this.clearTimer();
        try {
          let res = await this.$api.faultAlarm.getReadVoice(id);
          if (!res) {
            return;
          }
 
          var audio = document.getElementById("aud");
          audio.src = URL.createObjectURL(res.data);
          audio.load();
          audio.onended = () => {
            if (this.dialogVisible == false) {
              this.swiper();
            }
          };
        } catch (error) {}
      } else if (this.alarmList[index].audioType == 2) {
        // this.clearTimer();
        var audio = document.getElementById("aud");
        audio.src = require(`../../../assets/video/${this.alarmList[index].audioName}`);
        audio.load();
        audio.onended = () => {
          if (this.dialogVisible == false) {
            setTimeout(() => {
              this.swiper();
            }, 3000);
          }
        };
      }else{
        if (this.dialogVisible == false) {
          setTimeout(() => {
            this.swiper();
          }, 5000);
        }
      }
    },
    async doOperation(val) {
      try {
        let res = await this.$api.faultAlarm.doOperate({
          ids: this.handleData,
          isAllDevice: this.whiteList,
          type: val,
        });
        if (res.code == 20000) {
          this.getAlarmMessage();
          this.$message.success(res.message);
          this.dialogVisible = false;
          this.whiteListDialogVisible = false;
        } else {
          this.$message.error(res.message);
        }
      } catch (error) {}
    },
  },
  beforeDestroy() {
    clearInterval(this.timeReal);
    this.timeReal = null;
  },
};
</script>
<style lang="scss" scoped>
.message-info {
  height: 0.8rem;
  overflow: hidden;
  position: relative;
}
.message-item {
  font-size: 0.14rem;
  padding: 0.03rem 0.16rem;
  position: absolute;
  width: 100%;
  top: 100%;
  opacity: 0;
  transition: all 0.5s;
  background: linear-gradient(
    to right,
    rgba(14, 27, 46, 0.8),
    #7b0d0c,
    rgb(14, 27, 46)
  );
  display: grid;
  grid-template-columns: 2% 23% 10% 40% 18% 6%;
  .alarm-detail {
    cursor: pointer;
  }
  &.current {
    top: 0px;
    opacity: 1;
    z-index: 3;
    padding: 0.08rem 0.16rem;
  }
  &.pre {
    top: -100%;
    transform: scale(0.5);
    opacity: 0;
  }
  &.next {
    top: 45%;
    transform: translateY(-10%);
    opacity: 0.4;
  }
  &.nextTwo {
    top: 75%;
    transform: translateY(-20%);
    opacity: 0.1;
  }
  @keyframes swiper {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}
</style>
<style lang="scss">
.preview-div{
  height: 5.6rem;
  overflow: hidden;
  #booster-station{
    height: 100%;
    width: 100%; 
    overflow: hidden;
    cursor: move;
  }
}
.svgTip{
  display: flex;
  justify-content: center;
  align-items:center;
  padding: 0 20px;
 .cricle{
  margin-right: 10px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #ffff00;
 }
}

.detail-dialog {
  width: 100%;
  padding: 10px 30px 50px;
  > div {
    text-align: left;
    margin-bottom: 0.26rem;
    width: 100%;
    > span {
      &:first-child {
        margin-right: 0.25rem;
      }
    }
  }
  .alarm-type {
    padding: 0.05rem 0.16rem;
  }
  .alarm-level {
    padding: 0.05rem 0.16rem;
    border-radius: 0.03rem;
  }
  .orange {
    background-color: #f18023;
  }
  .red {
    background-color: #b31010;
  }
  .bule {
    background-color: #2690aa;
  }
}
</style>