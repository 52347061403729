import {
    post,
    get,
    post_old,
} from "../request"

export default {
    // 查询所有权限
    allPermission(data) {
        return get('/permission/queryAsForest', data);
    },
    //用户的权限列表
    permissionAsTreeById(data) {
        return get('/permission/queryPermissionAsTreeByTenantId', data);
    },
    //枚举接口
    getOption(data) {
        return post_old('/station/enum', data);
    },
    //枚举接口
    getMap(data) {
        return get('/station/enum', data);
    },

}