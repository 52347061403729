/* 
    接口引用示例，每个模块创建不同的文件对应不同的api
*/
import login from "./login"
import common from './common';
import tenant from './tenant';
import account from './account';
import role from './role';
import station from './station';
import module from './module';
import monitor from './monitor';
import details from './details';
import faultAlarm from './faultAlarm';
import analyse from './analyse';
import report from './report';
import manageHome from './manageHome';
import log from './log';
import dataImport from './import'
import health from "./health"

export default {
    login,
    common,
    tenant,
    account,
    role,
    station,
    module,
    monitor,
    details,
    faultAlarm,
    analyse,
    report,
    manageHome,
    log,
    dataImport,
    health
}