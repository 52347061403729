/*
 * @Author: liumeikun
 * @Date: 2023-04-06 16:46:51
 * @LastEditors: Do not edit
 * @LastEditTime: 2023-10-27 18:11:22
 * @filePath: Do not edit
 */
export default [{
        path: 'stationMatrix',
        title: '场站矩阵',
        name: "stationMatrix",
        component: () =>
            import ( /* webpackChunkName: "monitor" */ '@/views/monitor/stationMatrix/stationMatrix.vue'),
        meta: {
            title: "场站矩阵",
            keepAlive: true
        },

    },
    {
        path: 'stationDetails',
        title: '场站详情',
        name: "stationDetails",
        component: () =>
            import ( /* webpackChunkName: "monitor" */ '../views/monitor/stationDetails.vue'),
        meta: {
            title: "场站详情",
            keepAlive: true,
        },

    },
    {
        path: 'fanDetails',
        title: '风机详情',
        name: "fanDetails",
        component: () =>
            import ( /* webpackChunkName: "monitor" */ '../views/monitor/fanDetails.vue'),
        meta: {
            title: "风机详情",
            keepAlive: true
        },

    },
    {
        path: 'inverterDetails',
        title: '逆变器详情',
        name: "inverterDetails",
        component: () =>
            import ( /* webpackChunkName: "monitor" */ '../views/monitor/inverterDetails.vue'),
        meta: {
            title: "逆变器详情",
            keepAlive: true
        },
    },
    {
        path: 'boxDetails',
        title: '箱变详情',
        name: 'boxDetails',
        component: () =>
            import ( /* webpackChunkName: "monitor" */ '../views/monitor/stationDetails/boxChangeDetail.vue'),
        meta: {
            title: "箱变详情",
            keepAlive: true,
        },

    },
]