<template>
  <!-- 中间地图 -->
  <div class="map-wrap">
    <Echarts
      ref="mapCharts"
      :options="options"
      @inited="inited"
      @setOption="changeLoca"
    ></Echarts>
    <div
      class="farm-wrap"
      v-for="(item, index) in mapData"
      :key="item.name"
      :class="curentId == index ? 'active' : 'hide'"
      :style="{ left: item.pos[0] + 'px', top: item.pos[1] + 'px' }"
    >
      <i class="com-icon icon-map-bottom"></i>
      <i
        class="com-icon"
        :class="[item.type == 1 ? 'icon-tur-farm' : 'icon-light-farm']"
      ></i>
    </div>
  </div>
</template>
<script>
import Echarts from "@/components/charts/chartsModle.vue";
import axios from "axios";
export default {
  props: {
    Data: {
      type: Array,
      default: () => [],
    },
    mapCode: {
      type: String,
      default: "",
    },
    curentId: {
      type: Number,
      default: 0,
    },
  },
  components: {
    Echarts,
  },
  data() {
    return {
      options: null,
      mapData: [],
      timer: 0,
    };
  },
  watch: {
    Data(newVal, oldVal) {
      this.mapData = newVal;
    },
    mapCode(newVal, oldVal) {
      if (newVal) {
        this.getMap(newVal);
      }
    },
  },

  methods: {
    getGeoJson(adcode) {
      return axios.get("/geojson/" + adcode + "_full.json");
    },
    initChart() {
      let mapOption = {
          map: "Map",
          aspectScale: 1, //长宽比
          zoom: 0.85,
          roam: true,
          top: "center",
          left: "center",
        },
        areaColor = {
          type: "linear",
          x: 0,
          y: 0,
          x2: 1,
          y2: 1,
          colorStops: [
            {
              offset: 0,
              color: "rgba(4, 15, 67, 0.7)",
            },
            {
              offset: 0.2,
              color: "rgba(0, 43, 255, 0.7)",
            },
            {
              offset: 0.4,
              color: "rgba(2, 24, 140, 0.7)",
            },
            {
              offset: 0.6,
              color: "rgba(10, 49, 108, 0.7)",
            },
            {
              offset: 0.8,
              color: "rgba(31, 173, 255, 0.7)",
            },
          ],
          global: false, // 缺省为 false
        },
        areaColor1 = {
          type: "linear",
          x: 0,
          y: 0,
          x2: 950,
          y2: 400,
          colorStops: [
            {
              offset: 0,
              color: "rgba(4, 15, 67, 0.7)",
            },
            {
              offset: 0.2,
              color: "rgba(0, 43, 255, 0.7)",
            },
            {
              offset: 0.4,
              color: "rgba(2, 24, 140, 0.7)",
            },
            {
              offset: 0.6,
              color: "rgba(10, 49, 108, 0.7)",
            },
            {
              offset: 0.8,
              color: "rgba(31, 173, 255, 0.7)",
            },
          ],
          global: true, // 缺省为 false
        },
        borderColor = {
          type: "linear",
          x: 0,
          y: 0,
          x2: 1,
          y2: 1,
          colorStops: [
            {
              offset: 0,
              color: "rgba(49, 131, 255, 0.7)",
            },
            {
              offset: 0.4,
              color: "rgba(0, 247, 250, 0.7)",
            },
            {
              offset: 0.7,
              color: "rgba(69, 183, 255, 0.7)",
            },
          ],
          global: false, // 缺省为 false
        };
      let option = {
        backgroundColor: "rgba(0,0,0,0)",
        geo: {
          ...mapOption,
          map: "shengAll",
          zoom: 0.85,
          aspectScale: 0.75,
          itemStyle: {
            // areaColor: areaColor,
            areaColor: this.mapCode !== "100000" ? areaColor : areaColor1,
            shadowColor: "#182f68",
            shadowOffsetX: 0,
            shadowOffsetY: 15,
            borderColor: borderColor,
            borderWidth: 4,
            label: {
              show: false,
            },
          },
          emphasis: {
            areaColor: areaColor,
            borderWidth: 0,
            label: {
              show: false,
            },
          },
        },
        series: [
          {
            type: "map",
            ...mapOption,
            aspectScale: 0.75,
            map: "shengChild",
            label: {
              show: false,
              textStyle: {
                color: "#fff",
              },
            },
            itemStyle: {
              borderColor: "#2ab8ff",
              borderWidth: 1,
              // areaColor: "rgba(0,0,0,0)",
              areaColor:
                this.mapCode == "100000" ? areaColor1 : "rgba(0,0,0,0)",
            },
            emphasis: {
              areaColor: "rgba(0,0,0,0)",
              borderWidth: 0,
              label: {
                show: true,
                color: "#fff",
              },
            },
          },
        ],
      };
      this.options = option;
    },
    //echarts初始化后
    inited(myChart) {
      myChart.on("georoam", (params) => {
        var option = myChart.getOption(); //获得option对象
        //console.log(params, option);
        if (params.componentType == "series") {
          option.geo[0].zoom = option.series[0].zoom; //下层geo的缩放等级跟着上层的geo一起改变
          option.geo[0].center = option.series[0].center; //下层的geo的中心位置随着上层geo一起改变
        } else if (params.componentType == "geo") {
          option.series[0].zoom = option.geo[0].zoom; //下层geo的缩放等级跟着上层的geo一起改变
          option.series[0].center = option.geo[0].center; //下层的geo的中心位置随着上层geo一起改变
        }
        myChart.setOption(option); //设置option
        this.changeLoca(myChart);
      });
    },
    //调整坐标点位置
    changeLoca(myChart) {
      this.mapData = this.mapData.map((item) => {
        let pos = myChart.convertToPixel("geo", item.value);
        return {
          ...item,
          //减去dom的宽度和高度的一半
          pos: [pos[0] - 18, pos[1] - 12],
        };
      });
    },
    async getMap(val) {
      this.getGeoJson("100000").then((res) => {
        if (val !== "100000") {
          res.data.features.forEach((item) => {
            if (item.properties.adcode == val) {
              //注册地图--省份（无子行政区）
              this.$echarts.registerMap("shengAll", {
                type: "FeatureCollection",
                name: "shengAll",
                features: [item],
              });
            }
          });
        } else {
          this.$echarts.registerMap("shengAll", res.data);
        }
        this.getGeoJson(val).then((res) => {
          //注册地图--省份（子行政区）
          this.$echarts.registerMap("shengChild", res.data);
          this.initChart();
        });
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.icon-map-bottom {
  background-image: url(@/assets/images/screem/map-bottom.png);
}
.icon-tur-farm {
  background-image: url(@/assets/images/screem/tur-farm.png);
}
.icon-light-farm {
  background-image: url(@/assets/images/screem/lightfarm.png);
}
.active {
  animation: bounce 2s linear infinite;
}
.map-wrap {
  position: relative;
  width: 100%;
  height: 100%;
  .farm-wrap {
    position: absolute;
    .icon-map-bottom {
      width: 36px;
      height: 24px;
    }
    .icon-tur-farm,
    .icon-light-farm {
      position: absolute;
      width: 20px;
      height: 14px;
      left: 50%;
      margin-left: -10px;
      top: -8px;
    }
  }
}
//弹动
@keyframes bounce {
  /* scaleY(.98) 模拟的是球落下的时候，受重力影响而产生的变形 */
  from,
  to {
    transform: translateY(0px);
    transform: scale(1);
    transform: rotate(0);
    opacity: 1;
  }
  25% {
    transform: translateY(-10px);
    transform: scale(1.2);
    transform: rotate(90);
    opacity: 1;
  }
  50% {
    transform: translateY(0px);
    transform: scale(1);
    transform: rotate(180);
    opacity: 0.8;
  }
  75% {
    transform: translateY(10px);
    transform: scale(1.2);
    transform: rotate(360);
    opacity: 1;
  }
}
</style>