<template>
  <div class="chart-container">
    <!-- 底座背景 -->
    <div class="title">
      <p>{{ percent }}%</p>
      <p>{{ title }}</p>
    </div>
    <div class="bg"></div>
    <div class="chart" ref="chart"></div>
  </div>
</template>

<script>
import { getPie3D, getParametricEquation } from "@/utils/chart"; //工具类js，页面路径自己修改
const color = ["#1A897E", "#249DD1"];
export default {
  name: "chart",
  props: {
    data: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      statusChart: null,
      option: {},
      optionData: [],
      percent: 0,
      title: "风电",
      mTime: null,
    };
  },
  watch: {
    data(newVal, oldVal) {
      let pieData = [
        { name: "风电", value: 0, percent: 0 },
        { name: "光伏", value: 0, percent: 0 },
      ];

      pieData[0].value = newVal[0];
      pieData[1].value = newVal[1];
      let sumValue = 0;
      for (let i = 0; i < pieData.length; i++) {
        sumValue += pieData[i].value;
      }
      pieData[0].percent = ((pieData[0].value / sumValue) * 100).toFixed(2);
      pieData[1].percent = ((pieData[1].value / sumValue) * 100).toFixed(2);
      this.optionData = pieData;
      this.setLabel();
    },
  },
  mounted() {
    // //根据窗口变化自动调节图表大小
    const that = this;
    window.onresize = function () {
      that.changeSize();
    };
  },
  methods: {
    // 初始化label样式
    setLabel() {
      this.optionData.forEach((item, index) => {
        item.itemStyle = {
          color: color[index],
        };
        item.label = {
          show: true,
          color: "#fff",
          height: 75,
          fontSize: 14,
          formatter: [
            // "{b|{b}}",
            // "{c|{c}}{b|台}",
            "{d}%\n{b|{b}}",
          ].join(""), // 用来换行
          rich: {
            b: {
              color: "#fff",
              lineHeight: 45,
            },
          },
        };
        item.labelLine = {
          lineStyle: {
            width: 1,
            color: "rgba(255,255,255,0.7)",
          },
        };
      });
      this.initChart();
    },
    // 图表初始化
    initChart() {
      this.statusChart = this.$echarts.init(this.$refs.chart);
      // 传入数据生成 option, 构建3d饼状图, 参数工具文件已经备注的很详细
      this.option = getPie3D(this.optionData, 0.8, 240, 30, 26, 1);
      this.statusChart.setOption(this.option);
      this.bindListen(this.statusChart);
    },
    // 监听鼠标事件，实现饼图选中效果（单选），近似实现高亮（放大）效果。
    // optionName是防止有多个图表进行定向option传递，单个图表可以不传，默认是opiton
    bindListen(myChart) {
      let that = this;
      let selectedIndex = "";
      let hoveredIndex = "";
      let index = 0;
      let isSelected;
      let isHovered;
      let startRatio;
      let endRatio;
      let k;
      let h;

      this.title = this.optionData[index].name;
      this.percent = this.optionData[index].percent;

      //默认第一个高亮
      isSelected = !this.option.series[index].pieStatus.selected;
      isHovered = true;
      startRatio = this.option.series[index].pieData.startRatio;
      endRatio = this.option.series[index].pieData.endRatio;
      k = this.option.series[index].pieStatus.k;
      // 对当前点击的扇形，执行高亮操作（对 option 更新）
      this.option.series[index].parametricEquation = getParametricEquation(
        startRatio,
        endRatio,
        isSelected,
        isHovered,
        k,
        25
      );
      this.option.series[index].pieStatus.hovered = isHovered;
      // 记录上次高亮的扇形对应的系列号 seriesIndex
      hoveredIndex = index;
      // 使用更新后的 option，渲染图表
      myChart.setOption(this.option);

      //循环高亮
      clearInterval(this.mTime);
      this.mTime = null;
      this.mTime = setInterval(() => {
        index = (index + 1) % this.optionData.length;
        this.title = this.optionData[index].name;
        this.percent = this.optionData[index].percent;
        if (hoveredIndex === index) {
          // 否则进行高亮及必要的取消高亮操作
        } else {
          // 如果当前有高亮的扇形，取消其高亮状态（对 option 更新）
          if (hoveredIndex !== "") {
            // 从 option.series 中读取重新渲染扇形所需的参数，将是否高亮设置为 false。
            isSelected = this.option.series[hoveredIndex].pieStatus.selected;
            isHovered = false;
            startRatio = this.option.series[hoveredIndex].pieData.startRatio;
            endRatio = this.option.series[hoveredIndex].pieData.endRatio;
            k = this.option.series[hoveredIndex].pieStatus.k;
            // 对当前点击的扇形，执行取消高亮操作（对 option 更新）
            this.option.series[hoveredIndex].parametricEquation =
              getParametricEquation(
                startRatio,
                endRatio,
                false,
                isHovered,
                k,
                10
                // this.option.series[hoveredIndex].pieData.value
              );
            this.option.series[hoveredIndex].pieStatus.hovered = isHovered;
            // 将此前记录的上次选中的扇形对应的系列号 seriesIndex 清空
            hoveredIndex = "";
          }
          // 从 option.series 中读取重新渲染扇形所需的参数，将是否高亮设置为 true。
          isSelected = this.option.series[index].pieStatus.selected;
          isHovered = true;
          startRatio = this.option.series[index].pieData.startRatio;
          endRatio = this.option.series[index].pieData.endRatio;
          k = this.option.series[index].pieStatus.k;
          // 对当前点击的扇形，执行高亮操作（对 option 更新）
          this.option.series[index].parametricEquation = getParametricEquation(
            startRatio,
            endRatio,
            isSelected,
            isHovered,
            k,
            25
          );
          this.option.series[index].pieStatus.hovered = isHovered;
          // 记录上次高亮的扇形对应的系列号 seriesIndex
          hoveredIndex = index;
          // 使用更新后的 option，渲染图表
          myChart.setOption(this.option);
        }
        // index = (index+1) %this.optionData.length
        // if(index>this.optionData.length){
        //     index = 0
        // }
        this.$emit("getIndex", index);
        // console.log(index)
      }, 5000);

      // 监听 mouseover，近似实现高亮（放大）效果
      myChart.on("mouseover", (params) => {
        clearInterval(this.mTime);
        this.mTime = null;
        let isSelected;
        let isHovered;
        let startRatio;
        let endRatio;
        let k;
        let h;
        this.title = this.optionData[params.seriesIndex].name;
        this.percent = this.optionData[params.seriesIndex].percent;

        // // 如果触发 mouseover 的扇形当前已高亮，则不做操作
        if (hoveredIndex === params.seriesIndex) {
          // 否则进行高亮及必要的取消高亮操作
        } else {
          // 如果当前有高亮的扇形，取消其高亮状态（对 option 更新）
          if (hoveredIndex !== "") {
            // 从 option.series 中读取重新渲染扇形所需的参数，将是否高亮设置为 false。
            isSelected = this.option.series[hoveredIndex].pieStatus.selected;
            isHovered = false;
            startRatio = this.option.series[hoveredIndex].pieData.startRatio;
            endRatio = this.option.series[hoveredIndex].pieData.endRatio;
            k = this.option.series[hoveredIndex].pieStatus.k;
            h = !this.option.series[hoveredIndex].pieStatus.selected ? 25 : 10;
            // 对当前点击的扇形，执行取消高亮操作（对 option 更新）
            this.option.series[hoveredIndex].parametricEquation =
              getParametricEquation(
                startRatio,
                endRatio,
                false,
                isHovered,
                k,
                10
                // this.option.series[hoveredIndex].pieData.value
              );
            this.option.series[hoveredIndex].pieStatus.hovered = isHovered;
            // 将此前记录的上次选中的扇形对应的系列号 seriesIndex 清空
            hoveredIndex = "";
          }
          // 从 option.series 中读取重新渲染扇形所需的参数，将是否高亮设置为 true。
          isSelected =
            this.option.series[params.seriesIndex].pieStatus.selected;
          isHovered = true;
          startRatio =
            this.option.series[params.seriesIndex].pieData.startRatio;
          endRatio = this.option.series[params.seriesIndex].pieData.endRatio;
          k = this.option.series[params.seriesIndex].pieStatus.k;
          h = !this.option.series[params.seriesIndex].pieStatus.selected
            ? 25
            : 10;
          // 对当前点击的扇形，执行高亮操作（对 option 更新）
          this.option.series[params.seriesIndex].parametricEquation =
            getParametricEquation(
              startRatio,
              endRatio,
              isSelected,
              isHovered,
              k,
              h
              // this.option.series[params.seriesIndex].pieData.value + 60
            );
          this.option.series[params.seriesIndex].pieStatus.hovered = isHovered;
          // 记录上次高亮的扇形对应的系列号 seriesIndex
          hoveredIndex = params.seriesIndex;

          // 使用更新后的 option，渲染图表
          myChart.setOption(this.option);
        }
      });

      // 修正取消高亮失败的 bug
      // 指的是鼠标从图表的上的某个点移出,并不是移出整个坐标系
      myChart.on("globalout", () => {
        clearInterval(this.mTime);
        this.mTime = null;
        this.mTime = setInterval(() => {
          index = (index + 1) % this.optionData.length;
          this.title = this.optionData[index].name;
          this.percent = this.optionData[index].percent;
          if (hoveredIndex === index) {
            // 否则进行高亮及必要的取消高亮操作
          } else {
            // 如果当前有高亮的扇形，取消其高亮状态（对 option 更新）
            if (hoveredIndex !== "") {
              // 从 option.series 中读取重新渲染扇形所需的参数，将是否高亮设置为 false。
              isSelected = this.option.series[hoveredIndex].pieStatus.selected;
              isHovered = false;
              startRatio = this.option.series[hoveredIndex].pieData.startRatio;
              endRatio = this.option.series[hoveredIndex].pieData.endRatio;
              k = this.option.series[hoveredIndex].pieStatus.k;
              h = !that.option.series[hoveredIndex].pieStatus.selected
                ? 25
                : 10;
              // 对当前点击的扇形，执行取消高亮操作（对 option 更新）
              this.option.series[hoveredIndex].parametricEquation =
                getParametricEquation(
                  startRatio,
                  endRatio,
                  false,
                  isHovered,
                  k,
                  10
                  // this.option.series[hoveredIndex].pieData.value
                );
              this.option.series[hoveredIndex].pieStatus.hovered = isHovered;
              // 将此前记录的上次选中的扇形对应的系列号 seriesIndex 清空
              hoveredIndex = "";
            }
            // 从 option.series 中读取重新渲染扇形所需的参数，将是否高亮设置为 true。
            isSelected = this.option.series[index].pieStatus.selected;
            isHovered = true;
            startRatio = this.option.series[index].pieData.startRatio;
            endRatio = this.option.series[index].pieData.endRatio;
            k = this.option.series[index].pieStatus.k;
            h = !that.option.series[index].pieStatus.selected ? 25 : 10;
            // 对当前点击的扇形，执行高亮操作（对 option 更新）
            this.option.series[index].parametricEquation =
              getParametricEquation(
                startRatio,
                endRatio,
                isSelected,
                isHovered,
                k,
                25
              );
            this.option.series[index].pieStatus.hovered = isHovered;
            // 记录上次高亮的扇形对应的系列号 seriesIndex
            hoveredIndex = index;
            // 使用更新后的 option，渲染图表
            myChart.setOption(this.option);
          }

          // if(index>this.optionData.length){
          //     index =0
          // }
          this.$emit("getIndex", index);
        }, 5000);
      });
    },
    // 自适应宽高
    changeSize() {
      this.statusChart && this.statusChart.resize();
    },
  },
  beforeDestroy() {
    clearInterval(this.mTime);
    this.mTime = null;
  },
};
</script>

<style lang='scss' scoped>
.chart-container {
  position: relative;
  width: 100%;
  height: 100%;
  .chart,
  .bg {
    width: 100%;
    height: 100%;
  }
  .title {
    pointer-events: none;
    position: absolute;
    text-align: center;
    top: 45px;
    left: 0;
    right: 0;
    z-index: 2;
    p:first-child {
      font-size: 14px;
      margin-bottom: 12px;
    }
    p:last-child {
      font-size: 12px;
      margin-bottom: 5px;
    }
  }
  .bg {
    position: absolute;
    bottom: 30px;
    left: 50%;
    z-index: 0;
    width: 193px;
    height: 80px;
    background: no-repeat center;
    background-image: url(@/assets/images/screem/pie-bg.png);
    background-size: 100% 150%;
    transform: translateX(-50%);
  }
}
</style>
