import {
    post,
    get,
    post_old,
    postExportFile
} from "../request"

export default {
    //查询场站下拉框
    getStationList(data) {
        return post_old('/monitor/matrix/queryStationSearchList', data);
    },
    //------------------风资源对比分析--------------------------
    //风资源对比分析-查询平均风速图表-列表
    queryWindSpeedAvgList(data) {
        return post('/analyze/windResource/queryWindSpeedAvgList', data);
    },
    //风资源对比分析-导出平均风速列表
    exportWindSpeedAvgList(data) {
        return postExportFile('/analyze/windResource/exportWindSpeedAvgList', data);
    },

    // 风资源对比分析-查询风向玫瑰图
    getRoseChart(data) {
        return post('/analyze/windResource/queryWindSpeedDirectionRoseChart', data);
    },
    //风资源对比分析-查询风频柱状图
    getWindFrequency(data) {
        return post('/analyze/windResource/queryWindFrequencyLineChart', data);
    },
    //风资源对比分析-查询风速风向频率列表
    getWindSpeedDirectionList(data) {
        return post('/analyze/windResource/queryWindSpeedDirectionFreqList', data);
    },
    //风资源对比分析-导出风速风向频率列表
    exportWindSpeedDirection(data) {
        return postExportFile('/analyze/windResource/exportWindSpeedDirectionFreqList', data);
    },

    //-------------------电量对比分析----------------------
    //电量对比分析-查询电量风速图表-列表
    queryEnergyWindSpeed(data) {
        return post('/analyze/energy/queryEnergyWindSpeedList', data);
    },
    //电量对比分析-导出电量风速列表
    exportEnergyWind(data) {
        return postExportFile('/analyze/energy/exportEnergyWindSpeedList', data);
    },

    //电量对比分析-查询损失电量图表-列表
    queryLossEnergyList(data) {
        return post('/analyze/energy/queryGenEnergyList', data);
    },
    //电量对比分析-查询损失电量饼图
    querLossEnergyPieChart(data) {
        return post('/analyze/energy/queryGenEnergyPieChart', data);
    },
    //电量对比分析-导出损失电量列表
    exportLossEnergy(data) {
        return postExportFile('/analyze/energy/exportGenEnergyList', data);
    },



    //故障对比分析-查询故障次数时间图表-列表
    queryFaultTimeList(data) {
        return post('/analyze/fault/queryFaultTimeCountList', data);
    },
    //故障对比分析-导出故障次数时间列表
    exportFaultTime(data) {
        return postExportFile('/analyze/fault/exportFaultTimeCountList', data);
    },

    //故障对比分析-查询故障机型图表-列表
    queryFaultModelList(data) {
        return post('/analyze/fault/queryFaultModelList', data);
    },
    //故障对比分析-导出故障机型列表
    exportFaultModel(data) {
        return postExportFile('/analyze/fault/exportFaultModelList', data);
    },


    // --------------------------api----------------------------------
    //光伏智能分析-效率对比分析
    getEfficacy(data) {
        return get('/pv/efficacy', data);
    },

    //光伏智能分析-对比分析(同期功率对比)
    getContrastData(data) {
        return post('/pv/contrast', data);
    },
    //光伏智能分析-对比分析(功率-发电量分析)
    getContrastPower(data) {
        return post('/pv/power', data);
    },

    //光伏智能分析-对比分析(逆变器对比--曲线)
    getInverterPowerCurve(data) {
      return post('pv/inverterPowerCurve', data);
    },
    //光伏智能分析-对比分析(逆变器对比--表格)
    getContrastInverter(data) {
      return post('pv/inverterModelAnalyze', data);
  },
    //光伏智能分析-对比分析(逆变器对比--组串list)
    getContrastInverterList(data) {
        return get('/pv/model', data);
    },
    //光伏智能分析-对比分析(逆变器对比--详情)
    getContrastInverterChart(data) {
        return post('/pv/selfCurve', data);
    },

    //光伏智能分析-对比分析(可靠性分析)
    getContrastReliable(data) {
        return post('/pv/reliable', data);
    },

    //光伏智能分析-组串自检分析
    getSelfChecking(data) {
        return get('/pv/self', data);
    },

    //光伏智能分析-离散率分析柱子图
    getDispersionBar(data) {
        return post('/pv/dispersion', data);
    },
    //光伏智能分析-离散率分析表格
    getDispersion(data) {
        return post('/pv/dispersionList', data);
    },
}