<template>
    <el-menu
        :default-active="$route.path"
        class="el-menu-vertical-demo"
        :collapse-transition="true"
        @select="handleSelect"
        :mode="type"
        :collapse="isCollapse"
        text-color="#fff">
        <template v-for="(item,index) in menuList">
            <el-menu-item
                :key="item.name"
                :index="item.path"
                v-if="!item.children">
                <i v-if="type !=='horizontal'" :class="'el-icon-' + item.icon"></i>
                <span>{{ item.title }}</span>
            </el-menu-item>
            <el-submenu
                :key="index+'_'+item.name"
                :index="item.path"
                v-else-if="item.children">
                <template slot="title">
                    <i v-if="!type !=='horizontal'" :class="'el-icon-' + item.icon" ></i>
                    <span>{{ item.title }}</span>
                </template>
                <template v-for="subNav in item.children">
                    <el-menu-item
                        :key="subNav.name"
                        :index="item.path + '/' + subNav.path"
                        v-if="!subNav.isHide">{{ subNav.title }}</el-menu-item>
                </template>
            </el-submenu>
        </template>
    </el-menu>
</template>
<script>
export default {
    props:{
        type:{
           type:String,
           default:"vertical"
       },
        menuList:{
          type:Array,
        }
    },
    data(){
        return{
            activeNav: "",
            isCollapse: false,
        }
    },
    methods:{
        handleSelect(key, keyPath) {
            this.activeNav = key;
            if (this.$route.path != key) {
                this.$router.push(key);
            }
        },
    }
}
</script>
<style>
/**水平导航 */
.head-nav{
    display: inline-block;
    justify-content: center;
    margin-left: 2rem;
    width:50%;
    height: 100%;
    background-color:transparent;
}
.head-nav .el-menu {
    background: #0C235C;
    border-right: 0px;
}
.head-nav .el-menu .el-menu-item{
    text-align: center;
    border-top:0.06rem solid #0C235C;
    border-bottom: 0.06rem;
    width:1.24rem;
    height: 0.6rem;
    line-height: 0.45rem;
    font-size: 0.14rem;
    padding: 0 0.2rem;
}
.head-nav .el-menu.el-menu--horizontal {
    border-bottom:0.06rem;
}
.head-nav .el-menu--horizontal>.el-menu-item.is-active {
    border-bottom: 0.06rem;
    border-top: 0.06rem solid #3CAAFF;
    color: #fff;
    background-color: rgba(43, 138, 255, 0.2);
}
.head-nav .el-menu-item:focus,.head-nav  .el-menu-item:hover {
    outline: 0;
    background:transparent;
    border-top:0.06rem solid #3CAAFF;
    background-color: rgba(43, 138, 255, 0.2);
}
.head-nav .el-menu--horizontal .el-menu-item:not(.is-disabled):focus,.head-nav .el-menu--horizontal .el-menu-item:not(.is-disabled):hover {
    outline: 0;
    color: #fff;
}
.head-nav .el-menu--horizontal>.el-menu-item:not(.is-disabled):focus, .head-nav .el-menu--horizontal>.el-menu-item:not(.is-disabled):hover, .el-menu--horizontal>.el-submenu .el-submenu__title:hover {
   background-color: rgba(43, 138, 255, 0.2);
}


/** 垂直导航*/
.left-nav {
    background-color:#081737;
    width:2.15rem !important;
    padding-top: 0.2rem;
}
.left-nav  .el-menu-item [class^=el-icon-] {
    margin-right: 0.05rem;
    width: 0.24rem;
    font-size: 0.18rem;
}
.left-nav .el-menu {
    background-color:#081737;
    border-right: 0px;
}
.left-nav .el-menu .el-menu-item{
    text-align: left;
    border-left: 6px solid #081737;
    height: 0.4rem;
    line-height: 0.4rem;
    font-size: 0.14rem;
    padding: 0 0.2rem;
}
.left-nav .el-menu-item.is-active {
    border-left: 6px solid #3CAAFF;
    color: #fff;
    background: linear-gradient(90deg,rgba(60, 170, 255, 0.5),rgba(60, 170, 255, 0.1));
}
.left-nav .el-menu-item:focus,.left-nav  .el-menu-item:hover {
    outline: 0;
    background:transparent;
    border-left: 6px solid #3CAAFF;
    background: linear-gradient(90deg,rgba(60, 170, 255, 0.5),rgba(60, 170, 255, 0.1));
}
.left-nav .el-icon-home{
    display: inline-block;
    width: 0.18rem;
    height: 0.18rem;
    background-image: url("../assets/images/home.png");
    background-repeat: no-repeat;
    background-size: 0.18rem;
}
.left-nav .el-icon-log{
    display: inline-block;
    width: 0.17rem;
    height: 0.17rem;
    background-image: url("../assets/images/log.png");
    background-repeat: no-repeat;
    background-size: 0.17rem;
}
.left-nav .el-icon-tenant{
    display: inline-block;
    width: 0.17rem;
    height: 0.17rem;
    background-image: url("../assets/images/tenant.png");
    background-repeat: no-repeat;
 background-size: 0.17rem;
}
.left-nav .el-icon-module{
    display: inline-block;
    width: 0.17rem;
    height: 0.17rem;
    background-image: url("../assets/images/module.png");
    background-repeat: no-repeat;
    background-size: 0.17rem;
}
.left-nav .el-icon-labelPoint{
    display: inline-block;
    width: 0.15rem;
    height: 0.18rem;
    background-image: url("../assets/images/labelPoint.png");
    background-repeat: no-repeat;
    background-size: 0.15rem;
}
.left-nav .el-icon-data{
    display: inline-block;
    width: 0.1rem;
    height: 0.17rem;
    background-image: url("../assets/images/data.png");
    background-repeat: no-repeat;
    background-size: 0.17rem;
}
.left-nav .el-icon-indicatorFormula{
    display: inline-block;
    width: 0.17rem;
    height: 0.17rem;
    background-image: url("../assets/images/indicatorFormula.png");
    background-repeat: no-repeat;
    background-size: 0.17rem;
}
.left-nav .el-icon-account{
    display: inline-block;
    width: 0.17rem;
    height: 0.17rem;
    background-image: url("../assets/images/account.png");
    background-repeat: no-repeat;
    background-size: 0.17rem;
}
.left-nav .el-icon-role{
    display: inline-block;
    width: 0.15rem;
    height: 0.18rem;
    background-image: url("../assets/images/role.png");
    background-repeat: no-repeat;
    background-size: 0.15rem;
}
.left-nav .el-icon-station{
    display: inline-block;
    width: 0.29rem;
    height: 0.21rem;
    background-image: url("../assets/images/station.png");
    background-repeat: no-repeat;
    background-size: 0.24rem;
}
.left-nav .el-icon-productConfig{
    display: inline-block;
    width: 0.17rem;
    height: 0.17rem;
    background-image: url("../assets/images/productConfig.png");
    background-repeat: no-repeat;
    background-size: 0.17rem;
}
.left-nav .el-icon-alarm{
    display: inline-block;
    width: 0.17rem;
    height: 0.17rem;
    background-image: url("../assets/images/alarm/alarm.png");
    background-repeat: no-repeat;
    background-size: 0.17rem;
}

</style>
    