<template>
  <!-- 功率曲线(3小时) -->
  <Echarts :options="options" @setOption="setOption"></Echarts>
</template>
<script>
import Echarts from "@/components/charts/chartsModle.vue";
export default {
  components: {
    Echarts,
  },
  data() {
    return {
      time: null,
      options: null,
      xAxisData: [],
      yAxisData1: [],
      yAxisData2: [],
      yAxisData3: [],
      Timer: null,
      index: -1,
    };
  },
  mounted() {
    this.getPowerCurve()
      .then(() => {})
      .then(() => {
        this.initChart();
      });

    this.time = setInterval(() => {
      this.getPowerCurve();
    }, 1000 * 5);
  },
  methods: {
    setOption(chart) {
      this.dispatchAction(chart);
      this.mouseEvents(chart);
    },
    mouseEvents(myCharts) {
      //鼠标移入
      myCharts.on("mouseover", () => {
        clearInterval(this.Timer);
        this.Timer = null;
        myCharts.dispatchAction({
          type: "showTip", // 根据 tooltip 的配置项显示提示框。
          seriesIndex: 0,
          dataIndex: this.index,
        });
      });
      //鼠标移出
      myCharts.on("mouseout", () => {
        this.dispatchAction(myCharts);
      });
    },
    //高亮轮播
    dispatchAction(myCharts) {
      this.Timer = setInterval(() => {
        //清除之前的高亮
        myCharts.dispatchAction({
          type: "downplay", // 根据 tooltip 的配置项显示提示框。
          seriesIndex: 0,
          dataIndex: this.index,
        });
        this.index = (this.index + 1) % this.xAxisData.length;

        //当前下标的高亮
        myCharts.dispatchAction({
          type: "highlight", // 根据 tooltip 的配置项显示提示框。
          seriesIndex: 0,
          dataIndex: this.index,
        });
        myCharts.dispatchAction({
          type: "showTip", // 根据 tooltip 的配置项显示提示框。
          seriesIndex: 0,
          dataIndex: this.index,
        });
      }, 5000);
    },
    initChart() {
      let echarts = this.$echarts;
      let textColor = "#FFF";
      let option = {
        textStyle: { color: textColor },
        color: ["#A45AFF", "#13D2F3", "#FBF811"],
        legend: {
          top: "8",
          textStyle: {
            color: textColor,
            fontSize: 10,
          },
          itemWidth: 16,
          itemHeight: 10,
          icon: "roundRect",
        },
        tooltip: {
          trigger: "axis",
          backgroundColor: "rgba(2,25,61,0.8)",
          borderColor: "rgba(26,90,170,0.5)",
          extraCssText: "box-shadow: 0 0 3px rgba(0, 0, 0, 0.3);",
          textStyle: {
            fontSize: 11,
            color: "#FFFFFF",
          },
        },
        grid: {
          show: true,
          top: "20%",
          left: "4%",
          bottom: "5%",
          containLabel: true,
          borderWidth: "0",
        },
        xAxis: [
          {
            type: "category",
            name: "",
            data: this.xAxisData,
            textStyle: {
              color: textColor,
            },
            axisTick: { show: false },
            axisLine: {
              show: false,
              lineStyle: { color: "rgba(92, 167, 219, 0.5)" },
            },
            axisLabel: {
              show: true,
              interval: 2,
              textStyle: { fontSize: 11 },
            },
            splitLine: {
              show: false,
              lineStyle: {
                color: "rgba(92, 167, 219, 0.5)",
              },
            },
            boundaryGap: false,
          },
        ],
        yAxis: [
          {
            type: "value",
            name: "MW",
            //nameGap: fontSize(1),
            nameTextStyle: { fontSize: 11 },
            min: 0,
            axisLine: {
              lineStyle: { color: "rgba(54, 153, 255, 0.5)" },
            },
            axisLabel: {
              show: true,
              textStyle: { fontSize: 11 },
            },
            splitLine: {
              show: true,
              lineStyle: {
                color: "rgba(54, 153, 255, 0.5)",
              },
            },
            axisTick: {
              show: false,
            },
          },
        ],
        series: [
          {
            name: "全场 ",
            id: "001",
            type: "line",
            data: this.yAxisData1,
            lineStyle: {
              width: 1,
              opacity: 0.8,
            },
            tooltip: {
              valueFormatter: (value) => value + " " + "MW",
            },
            smooth: true,
            symbol: "none",
            areaStyle: {
              //区域填充样式
              //线性渐变，前4个参数分别是x0,y0,x2,y2(范围0~1);相当于图形包围盒中的百分比。如果最后一个参数是‘true’，则该四个值是绝对像素位置。
              color: new echarts.graphic.LinearGradient(
                0,
                0,
                0,
                1,
                [
                  {
                    offset: 0,
                    color: "rgba(164, 90, 255, 0.6)",
                  },
                  {
                    offset: 0.8,
                    color: "rgba(164, 90, 255, 0.1)",
                  },
                ],
                false
              ),
            },
          },
          {
            name: "风电",
            id: "002",
            type: "line",
            data: this.yAxisData2,
            lineStyle: {
              width: 1,
              opacity: 0.8,
            },
            tooltip: {
              valueFormatter: (value) => value + " " + "MW",
            },
            symbol: "none",
            smooth: true,
            areaStyle: {
              //区域填充样式
              //线性渐变，前4个参数分别是x0,y0,x2,y2(范围0~1);相当于图形包围盒中的百分比。如果最后一个参数是‘true’，则该四个值是绝对像素位置。
              color: new echarts.graphic.LinearGradient(
                0,
                0,
                0,
                1,
                [
                  {
                    offset: 0,
                    color: "rgba(19, 210, 243, 0.6)",
                  },
                  {
                    offset: 0.8,
                    color: "rgba(19, 210, 243, 0.1)",
                  },
                ],
                false
              ),
            },
          },
          {
            name: "光伏",
            id: "003",
            type: "line",
            data: this.yAxisData3,
            lineStyle: {
              width: 1,
              opacity: 0.8,
            },
            tooltip: {
              valueFormatter: (value) => value + " " + "MW",
            },
            symbol: "none",
            smooth: true,
            areaStyle: {
              //区域填充样式
              //线性渐变，前4个参数分别是x0,y0,x2,y2(范围0~1);相当于图形包围盒中的百分比。如果最后一个参数是‘true’，则该四个值是绝对像素位置。
              color: new echarts.graphic.LinearGradient(
                0,
                0,
                0,
                1,
                [
                  {
                    offset: 0,
                    color: "rgba(251, 248, 17, 0.6)",
                  },
                  {
                    offset: 0.8,
                    color: "rgba(251, 248, 17, 0.1)",
                  },
                ],
                false
              ),
            },
          },
          // {
          //     type: "lines",
          //     zlevel: 2,
          //     smooth:false,
          //     showSymbol:false,
          //     polyline: true,
          //     coordinateSystem: "cartesian2d",
          //     lineStyle: {
          //         color: "#FFF96B",
          //         width: 2, // 线条宽度
          //         opacity: 0, // 尾迹线条透明度
          //         curveness: 5, // 尾迹线条曲直度
          //     },
          //     effect: {
          //         show: true,
          //         period: 10, // 箭头指向速度，值越小速度越快
          //         trailLength: 0.2, // 特效尾迹长度[0,1]值越大，尾迹越长重
          //         symbol: "pin", // 箭头图标
          //         symbolSize: [4, 15], // 图标大小
          //         cap: "round",
          //     },
          //     data: linesData,
          // },
        ],
      };
      this.options = option;
    },
    async getPowerCurve() {
      try {
        let res = await this.$api.monitor.getThreeHourPowerCurve();
        if (res.code == 20000) {
          this.xAxisData = res.data.xaxis;
          this.yAxisData1 = res.data.yAxisLeft.map((item) => {
            if (item == "-") {
              return "-";
            } else {
              return Number(item);
            }
          });
          this.yAxisData2 = res.data.yAxisLeftTwo.map((item) => {
            if (item == "-") {
              return "-";
            } else {
              return Number(item);
            }
          });
          this.yAxisData3 = res.data.yAxisLeftThree.map((item) => {
            if (item == "-") {
              return "-";
            } else {
              return Number(item);
            }
          });
        }
      } catch (error) {
        console.log(error);
      }
    },
  },
  beforeDestroy() {
    clearInterval(this.time);
    clearInterval(this.Timer);
    this.Timer = null;
    this.time = null;
  },
};
</script>

<style lang="scss" scoped>
</style>