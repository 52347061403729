<template>
  <div class="meun">
    <ul class="first-meun" >
      <li
        v-for="(item, index) in menus"
        :key="index"
        class="dropdown"
        :class="{ active: item.title === activeMenu.title }">
        <span>{{ item.title }}</span>
        <ul
          v-if="item.children && item.children.length > 0"
          class="second-meun">
          <li
            v-for="(i, j) in item.children"
            :key="index + '-' + j"
            :class="$route.path == i.path || $route.path.includes(i.path)
                ? 'active'
                : ''"
            @click="changeTab(i)">
            <span>{{ i.title }}</span>
          </li>
        </ul>
      </li>
    </ul>
  </div>
</template>
<script>
import { mapState } from "vuex";
export default {
  data() {
    return {
      menus: [],
    };
  },
  created() {
    this.menus = JSON.parse(sessionStorage.getItem("menuList"));
  },
  computed: {
    ...mapState("navtab", ["tabs", "activeItem"]),
    activeMenu() {
      let active = { parentId: 0, title: "" };
      this.traverseMap(this.menus, (item, parent) => {
        if (this.$route.path === item.path) {
          active = parent;
        }
      });
      return active;
    },
  },
  methods: {
    changeTab(it) {
      if (this.tabs.length == 12) {
        let arr = [];
        arr = this.tabs.map((item) => {
          return item.path;
        });
        if (arr.includes(it.path)) {
          this.$router.push(it.path);
        } else {
          this.$router.push(it.path);
          this.$message({
            showClose: true,
            type: "warning",
            duration: 1000,
            message: "已超过最大标签数，请先关闭已打开的标签页",
          });
        }
      } else {
        this.$router.push(it.path);
      }
    },
    traverseMap(data, callback, parent = null) {
      for (let i = 0; i < data.length; i++) {
        const item = data[i];
        callback && typeof callback === "function" && callback(item, parent);
        if (item && item.children && item.children.length) {
          this.traverseMap(item.children, callback, item);
        }
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.meun {
  height: 100%;
  .first-meun {
    display: flex;
    justify-content: center;
    height: 100%;
    font-size: 0.2rem;
    > li {
      min-width: 1.5rem;
      text-align: center;
      height: 100%;
      position: relative;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .dropdown:hover .second-meun {
      display: block;
    }
    .dropdown.active {
      position: relative;
      background-color: rgba(43, 138, 255, 0.2);
    }
    .dropdown.active::before {
      content: "";
      display: block;
      height: 0.07rem;
      width: 100%;
      background-color: #2b8aff;
      position: absolute;
      top: 0;
    }
  }
  .second-meun {
    display: none;
    position: absolute;
    z-index: 99999;
    border: 0.01rem solid #1c3b75;
    border-bottom-left-radius: 0.05rem;
    border-bottom-right-radius: 0.05rem;
    padding: 0.1rem 0;
    font-size: 0.18rem;
    background-color: rgba(12, 35, 92);
    box-shadow: 0 0.02rem 0.1rem 0.05rem rgba(3, 9, 51, 0.5);
    bottom: 0;
    transform: translateY(100%);
    transition: 0.3s ease-in-out;
    > li {
      min-width: 1.5rem;
      text-align: center;
      height: 0.4rem;
      line-height: 0.4rem;
      white-space: nowrap;
      padding: 0 0.2rem;
      cursor: pointer;
      &:hover {
        background-color: rgba(0, 0, 0, 0.16);
      }
    }
    .active {
      color: #4e99fa;
    }
  }
}
</style>