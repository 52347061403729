export default [{
        path: '/account',
        title: '账户管理',
        component: () =>
            import ( /* webpackChunkName: "adminSys" */ '../views/adminSys/account.vue'),
    },
    {
        path: '/role',
        title: '角色管理',
        component: () =>
            import ( /* webpackChunkName: "adminSys" */ '../views/adminSys/role.vue'),
    },
    {
        path: '/station',
        title: '场站管理',
        component: () =>
            import ( /* webpackChunkName: "adminSys" */ '../views/adminSys/station.vue'),
    },
    {
        path: '/dataImport',
        title: '数据导入',
        component: () =>
            import ( /* webpackChunkName: "adminSys" */ '../views/adminSys/dataImport.vue'),
    },
    {
        path: '/config',
        title: '配置管理',
        component: () =>
            import ( /* webpackChunkName: "adminSys" */ '../views/adminSys/config.vue'),
    },

]