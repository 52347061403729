import {
    post,
    get,
    post_old
} from "../request"

export default {
    // 添加租户
    addTenant(data) {
        return post('/tenant/add', data);
    },
    //查询租户列表
    searchTenant(curPage, pageSize, id, keyword) {
        let params = {
            curPage: curPage,
            pageSize: pageSize,
            id: id,
            keyword: keyword
        }
        return post('/tenant/list', params);
    },
    //编辑租户
    editTenant(data) {
        return post('/tenant/edit', data);
    },
    //租户查看-基本信息
    detailsTenant(data) {
        return post_old('/tenant/queryTenantDetails', data);
    },
    //租户查看-成员信息
    membersTenant(data) {
        return post('/tenant/members', data);
    },
    //租户查看-场站信息
    stationTenant(data) {
        return post('/tenant/stations', data);
    },
    //租户-分配权限
    permsTenant(data) {
        return post('/tenant/distributePerms', data);
    },
}