import {
    post,
    get,
    Delete,
} from "../request"

export default {
    getRole(data) {
        return get('/role/role/all', data);
    },
    // 添加角色
    addRole(data) {
        return post('/role/add', data);
    },
    //查询角色列表
    searchRole(curPage, pageSize, id, keyword) {
        let params = {
            curPage: curPage,
            pageSize: pageSize,
            id: id,
            keyword: keyword
        }
        return post('/role/list', params);
    },
    //编辑角色
    editRole(data) {
        return post('/role/edit', data);
    },
    //删除角色
    delRole(data) {
        return Delete('/role/del', data);
    },
    //查询角色-分配权限-场站
    searchStationsByRoleId(data) {
        return get('/role/queryStationsByRoleId', data);
    },
    //查询角色-分配权限-模块
    permissionAsTreeById(data) {
        return get('/permission/queryPermissionAsTreeByRoleId', data);
    },

    //分配权限-场站
    doStationPower(data) {
        return post('/role/distribute/station/perms', data);
    },
    //分配权限-模块
    doModulePower(data) {
        return post('/role/distribute/perms', data);
    }


}