import {
    post,
    get,
    post_old,
    postExport,
    postExportFile
} from "../request"

export default {
    //下拉框查询场站
    getStationList(data) {
        return post('/importData/import/queryStationSelectList', data);
    },
    //下拉框查询用户
    getUsertList(data) {
        return post('/importData/import/queryUsertList', data);
    },


    //导入-导入文件
    importTemplate(data) {
        return postExport('/importData/import/importTemplate', data);
    },
    //导入--导入查询
    getImportRecord(data) {
        return post_old('/importData/import/queryImportRecord', data);
    },

    //模板下载--下载模板
    exportTemplates(data) {
        return postExportFile('/importData/downTemplate/exportTemplates', data);
    },
    //模板下载--查询模板列表
    getTemplateList(data) {
        return post_old('/importData/downTemplate/queryTemplateList', data);
    },

    //导入历史--文件list
    getFileList(data) {
        return post_old('/importData/importHistory/importVagueQuery', data);
    },

    //导入历史--查询
    getImportHistoryRecord(data) {
        return post('/importData/importHistory/queryImportHistoryRecord', data);
    },


}