<template>
  <Echarts
    ref="containEcharts"
    :options="options"
    @setOption="setOption"
  ></Echarts>
</template>
<script>
// 装机容量柱状图
import Echarts from "@/components/charts/chartsModle.vue";
export default {
  components: {
    Echarts,
  },
  data() {
    return {
      time: null,
      options: null,
      yAxisData1: [],
      yAxisData2: [],
      xAxisData: [],
      Timer: null,
      index: -1,
    };
  },
  mounted() {
    this.getCapacity()
      .then(() => {})
      .then(() => {
        this.initChart();
      });
  },
  methods: {
    setOption(chart) {
      this.dispatchAction(chart);
      this.mouseEvents(chart);
    },
    mouseEvents(myCharts) {
      //鼠标移入
      myCharts.on("mouseover", () => {
        clearInterval(this.Timer);
        this.Timer = null;
        myCharts.dispatchAction({
          type: "showTip", // 根据 tooltip 的配置项显示提示框。
          seriesIndex: 0,
          dataIndex: this.index,
        });
      });
      //鼠标移出
      myCharts.on("mouseout", () => {
        this.dispatchAction(myCharts);
      });
    },
    //高亮轮播
    dispatchAction(myCharts) {
      this.Timer = setInterval(() => {
        //清除之前的高亮
        myCharts.dispatchAction({
          type: "downplay", // 根据 tooltip 的配置项显示提示框。
          seriesIndex: 0,
          dataIndex: this.index,
        });
        this.index = (this.index + 1) % this.xAxisData.length;

        //当前下标的高亮
        myCharts.dispatchAction({
          type: "highlight", // 根据 tooltip 的配置项显示提示框。
          seriesIndex: 0,
          dataIndex: this.index,
        });
        myCharts.dispatchAction({
          type: "showTip", // 根据 tooltip 的配置项显示提示框。
          seriesIndex: 0,
          dataIndex: this.index,
        });
      }, 5000);
    },
    initChart() {
      let echarts = this.$echarts;
      let option = null;
      const yAxisData1 = this.yAxisData1;
      const yAxisData2 = this.yAxisData2;
      const xAxisData = this.xAxisData;
      let barBottomColor = ["rgba(255, 150, 0, 0)", "rgba(0, 222, 255, 0)"];
      let barTopColor = ["rgba(255, 150, 0, 1)", "rgba(0, 222, 255, 1)"];
      option = {
        title: {
          text: "",
          textStyle: {
            color: "#fff",
          },
        },
        tooltip: {
          trigger: "axis",
          // backgroundColor: "#333333",
          // borderColor: "#333333",
          backgroundColor: "rgba(2,25,61,0.8)",
          borderColor: "rgba(26,90,170,0.5)",
          extraCssText: "box-shadow: 0 0 3px rgba(0, 0, 0, 0.3);",
          showDelay: 0,
          hideDelay: 0,
          enterable: true,
          transitionDuration: 0,
          // extraCssText: "z-index:100",
          axisPointer: {
            type: "shadow",
          },
          textStyle: {
            fontSize: 11,
            color: "#FFFFFF",
          },
        },
        legend: {
          data: ["同比增长容量", "累计容量"],
          left: "right",
          right: 30,
          align: "left",
          top: "0%",
          textStyle: {
            color: "#fff",
            fontSize: 11,
          },
          itemWidth: 20,
          itemHeight: 10,
          itemGap: 5,
          selectedMode: false, //图例点击失效
        },
        grid: {
          top: "20%",
          left: "4%",
          bottom: "0%",
          containLabel: true,
        },
        xAxis: [
          {
            type: "category",
            data: xAxisData,
            axisLine: {
              show: false,
              lineStyle: {
                color: "#5CA7DB",
                width: 1,
                type: "solid",
              },
            },
            axisTick: {
              show: false,
            },
            axisLabel: {
              show: true,
              margin: 10,
              textStyle: {
                color: "#FFF",
                fontSize: 11,
              },
            },
          },
        ],
        yAxis: [
          {
            type: "value",
            axisLabel: {
              formatter: "{value}",
              textStyle: {
                color: "#FFF",
                fontSize: 11,
              },
            },
            axisTick: {
              show: false,
            },
            axisLine: {
              show: false,
            },
            splitLine: {
              show: true,
              lineStyle: {
                color: "rgba(92, 167, 219, 0.5)",
                width: 1,
                type: "solid",
              },
            },
          },
          {
            type: "value",
            axisLabel: {
              formatter: "{value}",
              textStyle: {
                color: "#FFF",
                fontSize: 11,
              },
            },
            axisTick: {
              show: false,
            },
            axisLine: {
              show: false,
            },
            splitLine: {
              show: false,
              lineStyle: {
                color: "rgba(92, 167, 219, 0.5)",
                width: 1,
                type: "solid",
              },
            },
          },
        ],
        series: [
          {
            name: "累计容量",
            id: "002",
            type: "bar",
            // stack: "total",
            barGap: "0",
            data: yAxisData2,
            barWidth: 20,
            itemStyle: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color: barTopColor[1],
                },
                {
                  offset: 1,
                  color: barBottomColor[1],
                },
              ]),
              opacity: 0.8,
            },
            // markPoint: {
            //   symbol: "circle",
            //   symbolSize: [20, 8],
            //   itemStyle: {
            //     color: barTopColor[1],
            //   },
            //   data: yAxisData2.map((item, index) => {
            //     return {
            //       coord: [
            //         xAxisData[index],
            //         item,
            //         // item +
            //         //     yAxisData1[
            //         //         yAxisData2.indexOf(item)
            //         //     ],
            //       ],
            //     };
            //   }),
            // },
          },
          {
            name: "同比增长容量",
            id: "001",
            type: "line",
            yAxisIndex: 1,
            barGap: "0",
            data: yAxisData1,
            symbol: 'circle',
            itemStyle:{
              color:'#fa9128'
            }
          },
        ],
      };
      this.options = option;
    },
    async getCapacity() {
      try {
        let res = await this.$api.monitor.getCapacity();
        if (res.code == 20000) {
          this.xAxisData = res.data.xAis;
          this.yAxisData1 = res.data.yAxisLeft.map((item) => {
            if (item == "-") {
              return "-";
            } else {
              return Number(item);
            }
          });
          this.yAxisData2 = res.data.yAxisLeftTwo.map((item) => {
            if (item == "-") {
              return "-";
            } else {
              return Number(item);
            }
          });
        }
      } catch (error) {
        console.log(error);
      }
    },
  },
  beforeDestroy() {
    clearInterval(this.Timer);
    this.Timer = null;
  },
};
</script>
<style lang="scss" scoped>
</style>