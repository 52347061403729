<template>
  <el-container class="page" id="bigscreem">
    <el-header class="header">
      <div class="head-left">
        <div class="save-run">
          <span class="font10">安全生产&nbsp;</span><span>{{ day }}</span
          ><span class="font10">&nbsp;天</span>
        </div>
        <div class="nav">
          <el-dropdown
            v-for="(item, index) in leftMenus"
            :key="index"
            @command="goTo">
            <span class="el-dropdown-link">
              <a>{{ item.title }}</a>
            </span>
            <el-dropdown-menu
              v-if="item.children && item.children.length > 0"
              slot="dropdown">
              <el-dropdown-item
                v-for="(i, j) in item.children"
                :key="index + '-' + j"
                :class="
                  $route.path == i.path || $route.path.includes(i.path)
                    ? 'active'
                    : ''"
                :command="i"
                >{{ i.title }}
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </div>
      <div class="head-center">
        <h2>{{ title !== "null" ? title : "新能源云集控" }}</h2>
      </div>
      <div class="head-right">
        <div class="nav">
          <el-dropdown
            v-for="(item, index) in rightMenus"
            :key="index"
            @command="goTo">
            <span class="el-dropdown-link">
              <a>{{ item.title }}</a>
            </span>
            <el-dropdown-menu
              v-if="item.children && item.children.length > 0"
              slot="dropdown">
              <el-dropdown-item
                v-for="(i, j) in item.children"
                :key="index + '-' + j"
                :command="i"
                :class="
                  $route.path == i.path || $route.path.includes(i.path)
                    ? 'active'
                    : ''"
                  >{{ i.title }}
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
        <div class="h-right-o">
          <i class="com-icon icon-user"></i>
          <span class="sys-text">{{ sysUserName }}</span>
          <i class="com-icon icon-logout" @click="logout"></i>
        </div>
      </div>
    </el-header>
    <el-main class="main">
      <div class="bottom-bg"></div>
      <div class="m-left">
        <!-- 发电量完成情况 -->
        <Block title="发电量完成情况">
          <Power></Power>
        </Block>
        <!-- 装机容量 -->
        <Block title="装机容量">
          <tur-contain></tur-contain>
        </Block>
      </div>
      <!-- 中间 -->
      <div class="m-center">
        <!-- 地图 -->
        <!-- <Map></Map> -->
        <MapCharts
          :Data="mapData"
          :mapCode="mapCode"
          :curentId="curentId"
        ></MapCharts>
        <!-- 顶部信息 -->
        <center-top-info class="mc-top"></center-top-info>
        <message-info class="alarm-message"></message-info>
        <farm-info
          class="farm-info"
          :farmList="mapData"
          @getShow="getShow"
        ></farm-info>
      </div>
      <div class="m-right">
        <!-- 功率曲线 -->
        <Block title="功率曲线" style="width: 50%; height: 45%">
          <PowerCurveCharts></PowerCurveCharts>
        </Block>
        <!-- 运行状态 -->
        <Block title="运行状态" style="width: 45%; height: 45%">
          <run-status></run-status>
        </Block>
        <!-- 业务指标 -->
        <Block title="业务指标" style="width: 50%; height: 55%">
          <target></target>
        </Block>
        <!-- 节能减排 -->
        <Block title="节能减排" style="width: 45%; height: 55%">
          <energy-save></energy-save>
        </Block>
      </div>
    </el-main>
  </el-container>
</template>

<script>
import Block from "./com/block.vue";
import PowerCurveCharts from "./com/powerCurveCharts.vue";
import Power from "./screem/power.vue";
import CenterTopInfo from "./screem/centerTopInfo.vue";
import RunStatus from "./screem/runStatus.vue";
import Target from "./screem/target.vue";
import EnergySave from "./screem/energySave.vue";
import TurContain from "./screem/turContain.vue";
import MapCharts from "../home/com/mapCharts.vue";
import FarmInfo from "./screem/farmInfo.vue";
import MessageInfo from "./screem/messageInfo.vue";
export default {
  components: {
    Block,
    Power,
    CenterTopInfo,
    RunStatus,
    Target,
    EnergySave,
    TurContain,
    PowerCurveCharts,
    MapCharts,
    FarmInfo,
    MessageInfo,
  },
  data() {
    return {
      time: null,
      menus: [],
      day: 0,
      mapData: [],
      mapCode: "",
      curentId: 0,
      title: null,
    };
  },
  created() {
    this.title = localStorage.getItem("title");
    this.sysUserName = localStorage.getItem("username");
    this.getSafeTimeDays();
    this.getStationMap();
    this.menus = JSON.parse(sessionStorage.getItem("menuList"));
    // this.time = setInterval(()=>{
    //     this.getStationMap()
    // },1000 * 6)
    const nowTemp = new Date().getTime(); //获取当前时间戳
    const tomorrowTemp = new Date(
      new Date(new Date().toLocaleDateString()).getTime() +
        24 * 60 * 60 * 1000 -
        1
    ); //获取今天23:59的时间戳
    const residueTemp = tomorrowTemp - nowTemp; //距离当天24：00的时间戳
    setTimeout(() => {
      //次天0点 执行每天24;00 刷新
      setInterval(() => {
        //需要调用的方法
        this.getSafeTimeDays();
      }, 1000 * 60 * 60 * 24);
    }, residueTemp);
  },
  computed: {
    leftMenus() {
      return this.menus.slice(0, this.menus.length / 2);
    },
    rightMenus() {
      return this.menus.slice(this.menus.length / 2); 
    },
  },
  methods: {
    goTo(item) {
      this.$router.push(item.children &&item.children.length>0? item.children[0].path : item.path);
    },
    getSafeTimeDays() {
      this.$api.monitor
        .getSafeTimeDays()
        .then((res) => {
          if (res.code == 20000) {
            this.day = Number(res.data);
          }
        })
        .catch((error) => {});
    },
    getStationMap() {
      //场站地图接口
      this.$api.monitor
        .getStationMap({ type: 0 })
        .then((res) => {
          if (res.code == 20000) {
            this.mapCode = res.data.mapCode;
            this.mapData = res.data.stationList.map((item) => {
              if (res.data.mapType == 1) {
                return {
                  ...item,
                  name1: item.provinces,
                  value: [Number(item.longitude), Number(item.latitude), 1],
                  station: item.name,
                  pos: [0, 0],
                };
              } else {
                return {
                  ...item,
                  name: item.name,
                  name1: item.city,
                  value: [Number(item.longitude), Number(item.latitude), 1],
                  station: item.name,
                  pos: [0, 0],
                };
              }
            });
          }
        })
        .catch((error) => {});
    },
    getShow(val) {
      this.curentId = val;
    },
    async logout(data) {
      try {
        let res = await this.$api.login.logout(data);
        if (res.code == 200) {
          sessionStorage.removeItem("menuList");
          localStorage.removeItem("currentTab");
          localStorage.removeItem("admin");
          localStorage.removeItem("token");
          localStorage.removeItem("tabs");
        } else {
          this.$message.error(res.msg);
        }
      } catch (error) {}
      this.$router.push("/");
    },
  },
  beforeDestroy() {
    clearInterval(this.time);
    this.time = null;
  },
};
</script>

<style lang="scss" scoped>
.page {
  width: 2480px;
  height: 640px;
  .header {
    display: flex;
    align-items: center;
    height: 51px !important;
    color: #fefeff;
    background-image: url("@/assets/images/screem/top-bg.gif");
    background-size: 100% 100%;
    .head-left,
    .head-right {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 28%;
      .nav {
        display: flex;
        padding: 0;
        a {
          padding: 0 18px;
          font-size: 15px;
          font-weight: bold;
          background: linear-gradient(
            90deg,
            rgba(9, 84, 186, 1) 0%,
            rgba(20, 175, 255, 1) 100%
          );
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          cursor: pointer;
          animation: scratchy 0.25s linear forwards infinite;
          &:hover {
            background: linear-gradient(
              90deg,
              rgba(9, 84, 186, 0.6) 0%,
              rgba(20, 175, 255, 0.6) 100%
            );
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
          }
        }
      }
      .h-right-o {
        display: flex;
        align-items: center;
        .com-icon {
          width: 18px;
          height: 18px;
          margin: 0 6px 0 10px;
          cursor: pointer;
        }
        .sys-text {
          font-size: 13px;
        }
      }
    }
    .head-left {
      .save-run {
        display: flex;
        font-size: 16px;
      }
    }
    .head-center {
      width: 44%;
      h2 {
        text-align: center;
        font-size: 20px;
        font-weight: bold;
      }
    }
  }
  .main {
    position: relative;
    display: flex;
    background-image: url(@/assets/images/screem/bg.png);
    background-repeat: no-repeat;
    background-size: 100% 100%;
    .bottom-bg {
      position: absolute;
      width: 100%;
      height: 51px;
      bottom: 0;
      background-image: url(@/assets/images/screem/bottom-bg.gif);
      background-repeat: no-repeat;
      background-size: 100% 100%;
    }
    .m-left,
    .m-right {
      width: 700px;
      height: 100%;
      padding: 22px 32px;
    }
    .map-chart {
      width: 1080px;
      height: 640px;
    }
    .m-left {
      ::v-deep .block {
        height: 50%;
      }
    }
    .m-center {
      position: relative;
      width: 1080px;
      height: 100%;
      overflow: hidden;
      .mc-top {
        position: absolute;
        width: 100%;
        left: 0;
        top: 36px;
      }
      .farm-info {
        position: absolute;
        width: 236px;
        height: 188px;
        right: 48px;
        bottom: 120px;
      }
      .alarm-message {
        z-index: 10;
        position: absolute;
        width: 75%;
        left: 50%;
        transform: translateX(-50%);
        top: 85%;
      }
    }
    .m-right {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
    }
  }
}

@keyframes scratchy {
  0% {
    background-position: 0 0;
  }
  25% {
    background-position: 4px -4px;
  }
  50% {
    background-position: 8px -8px;
  }
  75% {
    background-position: 16px -16px;
  }
  100% {
    background-position: 0 0;
  }
}
</style>