<template>
  <div class="fualt-svg">
    <div class="svg-tip">
      <i class="cricle"></i>
      <span> 告警点</span>
    </div>
    <div
      class="preview-div"
      ref="previewDiv"
      @mousewheel="handleMousewheel"
      @mousedown="handleMouseDown"
    >
      <div
        id="booster-station"
        :style="{
          transform: `scale(${scale}) translate(${translateX}px, ${translateY}px)`,
        }"
      ></div>
    </div>
  </div>
</template>
<script>
import boosterStation from "@/utils/boosterStation";
import { Base64 } from "js-base64";
export default {
  props: {
    svgId: {
      type: [Number, String],
      default: () => null,
    },
  },
  data() {
    return {
      scale: 1.2,
      translateX: 0,
      translateY: 0,
    };
  },
  mounted() {
    this.getBoosterSvg(this.svgId);
  },
  methods: {
    //鼠标操作svg图
    handleMousewheel(event) {
      this.scale = this.scale + event.wheelDelta / 1200;
      return false;
    },
    //鼠标操作svg图
    handleMouseDown(event) {
      event.preventDefault();
      if (event.target.tagName !== "svg") {
        return false;
      }
      let div = this.$refs.previewDiv;
      let originX = event.screenX;
      let originY = event.screenY;
      let translateX = this.translateX;
      let translateY = this.translateY;
      const move = (e) => {
        let afterX = e.screenX;
        let afterY = e.screenY;
        this.translateX = translateX + (afterX - originX) / this.scale;
        this.translateY = translateY + (afterY - originY) / this.scale;
      };
      div.addEventListener("mousemove", move);
      div.addEventListener("mouseup", () => {
        div.removeEventListener("mousemove", move);
      });
    },
    async getBoosterSvg(id) {
      try {
        let res = await this.$api.details.getBoosterSvg({ id: id });
          if (res.code == 20000) {
          if (res.data && res.data.length > 0) {
            this.svg = Base64.decode(res.data);
            this.getBoosterSvgRealTimeData(id);
            //加载svg
            $("#booster-station").empty().html(this.svg);
            $("#booster-station svg").css({ width: "100%", height: "100%" });
          } else {
            $("#booster-station").empty().html();
            this.svg = [];
          }
        }
      } catch (error) {}
    },
    async getBoosterSvgRealTimeData(id) {
      try {
        let res = await this.$api.details.getBoosterSvgRealTimeData({
          id: id,
          isWarn: 0,
        });
        if (res.code == 20000 && res.data.length > 0) {
          boosterStation.init(this, 1, res.data);
        }
      } catch (error) {}
    },
  },
};
</script>
<style lang="scss" scoped>
.fualt-svg{
  height: 600px;
  width: 100%;
  .preview-div{
    height: 550px;
  }
  #booster-station{
    height: 100%;
    width: 100%;
  }
  .svg-tip{
    display: flex;
    justify-content: center;
    align-items:center;
    padding: 0 20px;
    .cricle{
      margin-right: 10px;
      width: 10px;
      height: 10px;
      border-radius: 5px;
      background-color: #ffff00;
    }
  }
}

</style>