(function(doc, win) {
    var docEl = doc.documentElement,
        resizeEvt = 'orientationchange' in window ? 'orientationchange' : 'resize',
        recalc = function() {
            var clientWidth = docEl.clientWidth;
            var clientHeight = docEl.clientHeight;
            if (!clientWidth) return;
            // 固定比例，横向滚动
            let defalutScale = 1920 / 1080
            let innerWidth = Math.max(clientHeight * defalutScale, clientWidth)
            let appEl = document.getElementById('app')
            appEl && (appEl.style.height = '100%')
            appEl && (appEl.style.width = innerWidth + 'px')
            docEl.style.fontSize = (innerWidth / 19.8) + 'px';
        };
    if (!doc.addEventListener) return;
    win.addEventListener(resizeEvt, recalc, false);
    doc.addEventListener('DOMContentLoaded', recalc, false);
})(document, window);