/*
 * @Author: liumeikun
 * @Date: 2023-03-15 11:38:45
 * @LastEditors: Do not edit
 * @LastEditTime: 2023-09-07 18:53:44
 * @filePath: Do not edit
 */
import {
    post,
    get,
    post_old,
    Delete
} from "../request"

export default {
    //获取类型，风区下拉框
    getAllType(data) {
        return post_old('/station/enum', data)
    },

    // 添加场站
    addStation(data) {
        return post('/station/editWindStation', data);
    },
    //查询场站列表
    searchStation(curPage, pageSize, id, keyword) {
        let params = {
            curPage: curPage,
            pageSize: pageSize,
            id: id,
            keyword: keyword
        }
        return post('/station/list', params);
    },
    //编辑场站
    editStation(data) {
        return post('/station/editWindStation', data);
    },
    //删除用户
    delUser(data) {
        return Delete('/statior/del', data);
    },
    //场站查看
    detailsStation(data) {
        return post_old('/station/queryStationDetails', data);
    },

    //编辑光伏场站
    editPVStation(data) {
        return post('/station/editPhotovoltaicStation', data);
    },


    //-------------------生产日期配置----------------------
    querySafeTime(data) {
        return post('/safeTime/querySafeTime', data);
    },
    //编辑用户
    editSafeTime(data) {
        return post_old('/safeTime/edit', data);
    },

    //-------------------报表推送配置----------------------
    getUserList(data) { //查询
        return get('/user/queryUserList', data);
    },
    queryConfigList(data) { //查询
        return get('/reportConfig/reportConfig/queryConfigList', data);
    },
    editConfig(data) { //编辑
        return post('/reportConfig/reportConfig/update', data);
    },
}