<template>
  <div class="login-container">
    <div class="logo-wrap">
      <div class="login-title">
        <div class="logo" v-if="logo">
          <img :src="logo" />
        </div>
        <div class="mylogo" v-else></div>
        <h2>{{ title ? title : "新能源云集控" }}</h2>
      </div>
      <h3 class="sub-title">{{ subTitile }}</h3>
    </div>
    <el-form ref="loginForm" :model="form" :rules="rules" class="login-form">
      <el-form-item prop="username" style="margin-bottom: 0.3rem">
        <el-input
          class="login-input"
          type="text"
          v-model.trim="form.username"
          placeholder="输入用户名"
          clearable
        >
          <i slot="prefix" class="el-input__icon user"></i>
        </el-input>
      </el-form-item>
      <el-form-item prop="password" style="margin-bottom: 0.16rem">
        <el-input
          class="login-input"
          type="password"
          v-model.trim="form.password"
          show-password
          placeholder="输入密码"
          @keyup.enter.native="onSubmit('loginForm')">
          <span slot="prefix" class="el-input__icon password"></span>
        </el-input>
      </el-form-item>
      <el-form-item style="text-align: left; margin-bottom: 0.3rem">
        <el-checkbox v-model="checked">记住密码</el-checkbox>
      </el-form-item>
      <el-form-item>
        <el-button
          type="primary"
          class="login-btn"
          @click="onSubmit('loginForm')"
          :loading="logining"
          >登录</el-button>
      </el-form-item>
      <el-form-item style="text-align: center">
        <span class="errtip">{{ errtip }}</span>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
import { mapActions } from "vuex";
import { encryptByDES } from "../utils/crypto";
import { Base64 } from "js-base64";
import meun from '../json/meun.json';
export default {
  data() {
    return {
      title: null,
      subTitile: "打造轻量级的新能源资产管家",
      form: {
        username: "",
        password: "",
      },
      admin: 1,
      checked: false,
      logining: false,
      rules: {
        username: [
          { required: true, message: "请输入用户名", trigger: "blur" },
        ],
        password: [{ required: true, message: "请输入密码", trigger: "blur" }],
      },
      errtip: "",
      logo: null,
    };
  },
  mounted() {
    let title = localStorage.getItem("title") || null;
    this.title = title == "null" || title == null ? null : "新能源云集控";
    let src = localStorage.getItem("logo") || null;
    this.logo =
      src == "null" || src == null ? null : "data:image/png;base64," + src;
    this.getCookie();
  },
  methods: {
    ...mapActions("navtab", ["getMenu"]),
    onSubmit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let password = encryptByDES(this.form.password);
          if (this.checked) {
            localStorage.setItem("userId", this.form.username);
            this.setCookie(
              this.form.username,
              Base64.encode(this.form.password),
              7
            );
          } else {
            this.setCookie("", "", -1);
          }
          this.login({
            password: password,
            username: this.form.username,
            rememberMe: this.checked,
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    setCookie(user, password, days) {
      let date = new Date(); // 获取时间
      date.setTime(date.getTime() + 24 * 60 * 60 * 1000 * days); // 保存的天数
      // 字符串拼接cookie
      window.document.cookie =
        "user" + "=" + user + ";path=/;expires=" + date.toGMTString();
      window.document.cookie =
        "password" + "=" + password + ";path=/;expires=" + date.toGMTString();
    },
    getCookie() {
      if (document.cookie.length > 0) {
        let arr = document.cookie.split("; "); //分割成一个个独立的“key=value”的形式
        for (let i = 0; i < arr.length; i++) {
          let arr2 = arr[i].split("="); // 再次切割，arr2[0]为key值，arr2[1]为对应的value
          if (arr2[0] === "user") {
            this.form.username = arr2[1];
          } else if (arr2[0] === "password") {
            this.form.password = Base64.decode(arr2[1]); // base64解密
            this.checked = true;
          }
        }
      }
    },
    handleArrMenu(arrs, newArr) {
      arrs.map((item, index) => {
        if (item.checked && item.data.status) {
          newArr.push({ 
            path:item.url,
            title:item.label,
            parentId:item.data.parentId, 
            children:[]
          })
          let len = newArr.length
          if (item.children && item.children.length>0 ) { //有子集的话 继续调用
            this.handleArrMenu(item.children, newArr[len-1].children);
          }
        }
      });
      return newArr; //新生成的数组
    },
    //路由做埋点
    async analyticsRequest() {
      try {
        let res = await this.$api.log.addLog({
          address: "",
          source: 0,
        });
      } catch (error) {}
    },
    async login(data) {
      try {
        let res = await this.$api.login.login(data);
        if (res.code == 20000) {
          let list = [];
          sessionStorage.setItem("token", res.data.Token);
          sessionStorage.setItem("admin", res.data.type);
          sessionStorage.setItem("username", res.data.info.username);
          if (res.data.type == 3 && res.data.hasOwnProperty("permission")) {
            list =  this.handleArrMenu( res.data.permission,[]);
          if (list.length > 0) {
              sessionStorage.setItem("menuList", JSON.stringify(list));
              this.getMenu(list);
              if (list[0].children && list[0].children.length > 0) {
                this.$router.push({ path: list[0].children[0].path });
              } else {
                this.$router.push({ path: list[0].path });
              }
            } else {
              this.errtip = "没有权限或权限不可见,请联系系统管理员";
            }
          } else{
           list =  meun[res.data.type]
            sessionStorage.setItem("menuList", JSON.stringify(list));
            let path = list[0].path;
            this.$router.push(path);
          }
          this.getSystemMsg();
          this.analyticsRequest();
        } else {
          this.errtip = res.msg;
        }
      } catch (error) {
        this.errtip = error;
      }
    },
    async getSystemMsg() {
      try {
        let { data } = await this.$api.login.getSystemMsg();
        localStorage.setItem("logo", data.logoBase64);
        localStorage.setItem("title", data.title);
        let src = localStorage.getItem("logo");
        let logo = src == "null" ? null : "data:image/png;base64," + src;
        var link = document.querySelector("link[rel*='icon']");
        link.href = logo;
      } catch (error) {}
    },
  },
};
</script>
<style lang="scss" scoped>
.login-container {
  position: absolute;
  width: 100%;
  height: 100%;
  background: url("../assets/images/login_bg.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .logo-wrap {
    .login-title {
      display: flex;
      align-items: center;
      margin-bottom: 0.3rem;
      .mylogo {
        display: inline-block;
        height: 0.48rem;
        width: 0.48rem;
        line-height: 0.48rem;
        margin-right: 0.2rem;
        background-image: url("../assets/images/logo.png");
        background-repeat: no-repeat;
        background-position: center;
        background-size: 0.48rem;
      }
      .logo {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 0.48rem;
        width: 0.48rem;
        line-height: 0.48rem;
        margin-right: 0.2rem;
        > img {
          max-width: 100%;
          max-height: 100%;
        }
      }
      h2 {
        display: inline-block;
        font-size: 0.5rem;
        line-height: 0.5rem;
        font-weight: bold;
        color: #fff;
        letter-spacing: 0.05rem;
      }
    }
    .sub-title {
      text-align: center;
      color: #fff;
      font-size: 0.18rem;
      letter-spacing: 0.02rem;
    }
  }
  .login-form {
    width: 4rem;
    margin: 0.5rem auto;
    .el-form-item {
      .login-btn {
        border: 1px solid #3aa5f9;
        background: linear-gradient(
          180deg,
          rgba(58, 165, 249, 0.1) 0%,
          rgba(58, 165, 249, 0.7) 100%
        );
        border-radius: 0.05rem;
        height: 0.6rem;
        font-size: 0.18rem;
        width: 100%;
      }
      .errtip {
        display: inline-block;
        height: 0.4rem;
        line-height: 0.4rem;
        width: 100%;
        color: #ec4747;
        font-size: 0.14rem;
      }
    }
  }
  .login-footer {
    position: absolute;
    bottom: 0.2rem;
    color: #fff;
    letter-spacing: 0.02rem;
    font-size: 0.16rem;
    line-height: 0.6rem;
    height: 0.6rem;
  }
}
</style>
<style>
.login-input .el-input__inner {
  border-radius: 0.05rem !important;
  border: 0px solid rgba(30, 121, 212, 0) !important;
  height: 0.6rem !important;
  line-height: 0.6rem !important;
  background-color: rgba(151, 166, 199, 0.35) !important;
  font-size: 0.18rem !important;
}
.login-input.el-input--prefix .el-input__inner {
  padding-left: 0.8rem !important;
}
.login-input .el-input__prefix {
  display: inline-block;
  width: 0.6rem;
  height: 0.6rem;
  left: 0px;
  background-color: rgba(58, 165, 249, 0.5);
}
.login-input .el-input__icon.password {
  display: inline-block;
  width: 0.6rem !important;
  height: 0.6rem;
  background-image: url("../assets/images/password.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 0.4rem;
}
.login-input .el-input__icon.user {
  display: inline-block;
  width: 0.6rem !important;
  height: 0.6rem;
  background-image: url("../assets/images/user.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 0.4rem;
}
.login-input input::-webkit-input-placeholder {
  color: #3ca9ff;
  font-size: 0.18rem;
}

.login-input input::-moz-placeholder {
  color: #3ca9ff;
  font-size: 0.18rem;
}

.login-input input:-moz-placeholder {
  color: #3ca9ff;
  font-size: 0.18rem;
}

.login-input input:-ms-input-placeholder {
  color: #3ca9ff;
  font-size: 0.18rem;
}
</style>