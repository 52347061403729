<template>
  <p class="title">
    <i class="tag-wrap"><i class="tag"></i></i>
    {{ title }}
    <slot></slot>
  </p>
</template>

<script>
export default {
  props: ["title"],
  data() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
.title {
  display: flex;
  align-items: center;
  color: #fefefe;
  .tag-wrap {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 16px;
    height: 16px;
    margin: 0 10px 0 20px;
    background: rgb(43, 138, 255);
    background: radial-gradient(rgba(43, 138, 255, 1), rgba(43, 138, 255, 0));
    border-radius: 50%;
    .tag {
      display: inline-block;
      width: 8px;
      height: 8px;
      background: radial-gradient(
        rgba(255, 255, 255, 1),
        rgba(43, 138, 255, 0)
      );
      border-radius: 50%;
      animation: blink 2s linear infinite;
    }
  }
}

//闪烁
@keyframes blink {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0.2;
  }
}
</style>