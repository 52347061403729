/*
 * @Author: liumeikun
 * @Date: 2023-09-20 18:29:18
 * @LastEditors: Do not edit
 * @LastEditTime: 2023-12-14 10:02:17
 * @filePath: Do not edit
 */
export default [{
        path: 'customReport',
        title: '自定义报表',
        component: () =>
            import ( /* webpackChunkName: "report" */ '../views/report/reportSearch'),
        meta: {
            title: "自定义报表",
            keepAlive: false
        },
    },
    // {
    //     path: 'templateManage',
    //     title: '报表模板管理',
    //     component: () =>
    //         import ( /* webpackChunkName: "report" */ '../views/report/template-manage.vue'),
    //     meta: {
    //         title: "报表模板管理",
    //         keepAlive: false
    //     },

    // },
    {
        path: 'dataFill',
        title: '数据填报',
        component: () =>
            import ( /* webpackChunkName: "report" */ '../views/report/data-fill.vue'),
        meta: {
            title: "数据填报",
            keepAlive: true
        },

    },
    {
        path: 'freeTendChart',
        title: '自由趋势图',
        component: () =>
            import ( /* webpackChunkName: "report" */ '../views/report/freeTendChart.vue'),
        meta: {
            title: "自由趋势图",
            keepAlive: true
        },
    },
    {
        path: 'paste',
        title: '手动抄表',
        component: () =>
            import ( /* webpackChunkName: "report" */ '../views/report/paste.vue'),
        meta: {
            title: "手动抄表",
            keepAlive: true
        },

    },
]