function nums(num) {
    let index = 0
    if (num && num !== '-') {
        if (String(num).includes('-')) { //负数处理
            index = 1
            num = num.toString().slice(1, );
        }
        num = num.toString().split(".");
        var arr = num[0].split("").reverse();
        var res = [];
        for (var i = 0, len = arr.length; i < len; i++) {
            if (i % 3 === 0 && i !== 0) {
                res.push(",");
            }
            res.push(arr[i]);
        }

        res.reverse();
        if (num[1]) {
            res = res.join("").concat('.' + num[1]);
        } else {
            res = res.join("");
        }
        if (index == 1) {
            return -res;
        } else {
            return res;
        }
    } else {
        return num
    }
}
export default {
    nums
}