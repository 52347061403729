<template>
    <!-- 发电量完成情况 -->
    <div class="power">
        <ul class="date-wrap">
            <li>
                <div class="d-wrap">
                    <div class="com-icon d-round">
                        <i class="com-icon d-lightning blink3"></i>
                    </div>
                    <p class="d-text">月</p>
                </div>
                <span class="value">{{monthPower}}</span>
                <span class="unit">（万kWh）</span>
            </li>
            <li>
                <div class="d-wrap">
                    <div class="com-icon d-round">
                        <i class="com-icon d-lightning blink2"></i>
                    </div>
                    <p class="d-text">年</p>
                </div>
                <span class="value">{{yearPower}}</span>
                <span class="unit">（万kWh）</span>
            </li>
            <li>
                <div class="d-wrap">
                    <div class="com-icon d-round">
                        <i class="com-icon d-lightning blink1"></i>
                    </div>
                    <p class="d-text">总</p>
                </div>
                <span class="value">{{allPower}}</span>
                <span class="unit">（万kWh）</span>
            </li>
        </ul>
        <div class="farm-wrap">
            <!-- <p class="title"><i class="tag"></i>发电量完成率排名</p> -->
            <tag-title class="tag-title" title="月发电量完成率排名"></tag-title>
            <ul class="p-farm">
                <li v-if="powerRank.length>0">
                    <i class="com-icon icon-first"></i>
                    <div class="content">
                        <p v-if="powerRank[0].name.length<16"  class="farm-name">{{ powerRank[0].name}}</p>
                        <el-tooltip v-else class="item"  effect="dark" :content="powerRank[0].name" pplacement="bottom-start">
                            <p class="farm-name" >{{powerRank[0]?powerRank[0].name.slice(0,14)+'...':""}}</p>
                        </el-tooltip>
                        <whhProgress :width='powerRank[0].value'></whhProgress>
                    </div>
                </li>
                <li v-if="powerRank.length>1"> 
                    <i class="com-icon icon-two rolling1" ></i>
                    <div class="content">
                        <p v-if="powerRank[1].name.length<16"  class="farm-name">{{ powerRank[1].name}}</p>
                        <el-tooltip v-else class="item"  effect="dark" :content="powerRank[1].name" pplacement="bottom-start">
                            <p class="farm-name" >{{powerRank[1].name.slice(0,14)+'...'}}</p>
                        </el-tooltip>
                        <whhProgress :width='powerRank[1].value'></whhProgress>
                    </div>
                </li>
                <li v-if="powerRank.length>2">
                    <i class="com-icon icon-three rolling2"></i>
                    <div class="content">
                        <p v-if="powerRank[2].name.length<16"  class="farm-name">{{ powerRank[2].name}}</p>
                        <el-tooltip v-else class="item"  effect="dark" :content="powerRank[2].name" pplacement="bottom-start">
                            <p class="farm-name" >{{powerRank[2].name.slice(0,14)+'...'}}</p>
                        </el-tooltip>
                       
                        <whhProgress :width='powerRank[2].value'></whhProgress>
                    </div>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
import whhProgress from "../com/progress.vue";
import TagTitle from "../com/tagTitle.vue";
export default {
    components: {
        whhProgress,
        TagTitle,
    },
    data() {
        return {
            time:null,
            yearPower:0, 
            monthPower:0,
            allPower:0,
            powerRank:[
                {name:"",value:0,},
                {name:"",value:0,},
                {name:"",value:0,},
            ]
        };
    },
    created() {
        this.getGenerating()
        this.getPowerRank()
        this.time = setInterval(()=>{
            this.getGenerating()
        },1000 * 6)
    },
    methods: {
        getGenerating(){//发电量
            this.$api.monitor.getGenerating().then(res =>{
                if(res.code ==20000){
                    this.yearPower = res.data.yearGeneratingCapacity
                    this.monthPower = res.data.monthGeneratingCapacity
                    this.allPower = res.data.allGeneratingCapacity
                }
            }).catch(error=>{})
        },
        getPowerRank(){//发电量
            this.$api.monitor.getPowerRank({timeType:2}).then(res =>{
                if(res.code ==20000){
                    this.powerRank = res.data.map(item=>{
                        return {name:item.name,value:item.actuallyDivPlan,}
                    })
                }else{
                    this.powerRank=[]
                }
            }).catch(error=>{})
        },
    },
    beforeDestroy(){
        clearInterval(this.time)
        this.time = null
    }

};
</script>

<style lang="scss" scoped>
.d-round {
    background-image: url(@/assets/images/screem/power-d-round.png);
}
.d-lightning {
    background-image: url(@/assets/images/screem/power-d-lightning.png);
}
.icon-first {
    background-image: url(@/assets/images/screem/power-first.png);
}
.icon-two {
    background-image: url(@/assets/images/screem/power-two.png);
}
.icon-three {
    background-image: url(@/assets/images/screem/power-three.png);
}
.power {
    display: flex;
    height: 100%;
    width: 100%;
    font-size: 15px;
    .date-wrap {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 50%;
        height: 100%;
        padding: 46px 50px 32px 0;
        > li {
            display: flex;
            justify-content: space-between;
            align-items: center;
            .d-wrap {
                position: relative;
                width: 42px;
                height: 42px;
                .com-icon {
                    position: absolute;
                }
                .d-round {
                    height: 100%;
                    width: 100%;
                    left: 0;
                    top: 0;
                    .d-lightning {
                        width: 14px;
                        height: 16px;
                        right: 0;
                        bottom: 0;
                    }
                    .blink1 {
                        animation: blink 1s linear infinite;
                        animation-delay: 0s;
                    }
                    .blink2 {
                        animation: blink 1s linear infinite;
                        animation-delay: 0.2s;
                    }
                    .blink3 {
                        animation: blink 1s linear infinite;
                        animation-delay: 0.4s;
                    }
                }
                .d-text {
                    font-size: 15px;
                    line-height: 42px;
                    text-align: center;
                    color: #fff;
                }
            }
            .value {
                font-size: 18px;
                font-weight: bold;
                color: #fefefe;
            }
            .unit {
                font-size: 11px;
                font-weight: 400;
                color: #ffffff;
            }
        }
    }
    .farm-wrap {
        flex: 1;
        display: flex;
        flex-direction: column;
        padding-left: 30px;
        .tag-title {
            height: 46px;
        }
        .p-farm {
            flex: 1;
            display: flex;
            flex-direction: column;
            padding-left: 10px;
            li {
                display: flex;
                margin: 0.04rem 0;
                >.com-icon {
                    width: 30px;
                    height: 30px;
                    margin-right: 8px;
                    animation: rolling 4s ease-in infinite;
                }
                .rolling1 {
                    animation-delay: 0.5s;
                }
                .rolling2 {
                    animation-delay: 1s;
                }
                .content {
                    flex: 1;
                    .farm-name {
                        line-height: 30px;
                        color: #ffffff;
                    }
                }
            }
        }
    }
}
//闪烁
@keyframes blink {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}
//翻转
@keyframes rolling {
    0% {
        transform: rotateY(0deg);
    }
    70% {
        transform: rotateY(360deg);
    }
    100% {
        transform: rotateY(360deg);
    }
}
</style>