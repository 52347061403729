let scheduler = true;
let timer = null
    /**
     * 开关颜色控制函数
     * 刀闸:    合位--值1，红色；分位--值0，绿色
     */
var switchCtl = function(rowData) {
    if (rowData["uniqueId"].indexOf('Switch') != -1) { //开关
        // var idName = rowData["name"] + rowData["id"]; //拼接成ID
        var idName = rowData["uniqueId"]

        //双显
        $("#" + idName + " path[id*='off']").css({ visibility: 'visible' });
        $("#" + idName + " path[id*='on']").css({ visibility: 'visible' });

        //按条件单显
        if (Number(rowData["value"]) == 1) {
            $("#" + idName + " path[id*='off']").css({ visibility: 'hidden' });
            $("#" + idName + " path[id*='on']").css({ visibility: 'visible' });
            // $("#" + idName + " path[id*='on']").css({ stroke: '#F00' }); //红色
        } else {
            $("#" + idName + " path[id*='off']").css({ visibility: 'visible' });
            // $("#" + idName + " path[id*='off']").css({ stroke: '#0F0' }); //绿色
            $("#" + idName + " path[id*='on']").css({ visibility: 'hidden' });
        }
    }
}

/**
 * 断路器颜色控制函数
 * 断路器:    合位--值1，红色；分位--值0，绿色
 */
var circuitBreaker = function(rowData) {
        if (rowData["uniqueId"].indexOf('Color') != -1) { //断路器
            // if (rowData["name"].indexOf('CircuitBreaker') != -1) { //断路器
            // var idName = rowData["name"] + rowData["id"]; //拼接成ID
            var idName = rowData["uniqueId"]

            if (Number(rowData["value"]) == 1) {
                $("#" + idName).css({ stroke: '#F00', fill: '#F00' });
                // $("#" + idName).css({ stroke: '#FFF', fill: '#FFF' });
            } else {
                $("#" + idName).css({ stroke: '#0f0', fill: '#0f0' });
            }
        }
    }
    /**
     * 函数名：RealPageText
     * 功能：实时图svg元件上的文字显示
     * 说明：rowData={"id":"Textxx","value":"yyyy.yy"}
     */
var RealPageText = function(rowData) {
    if (rowData["uniqueId"].indexOf('Text') != -1) {
        var idName = rowData["uniqueId"]
        $("#" + idName).text(null);
        $("#" + idName).empty().text(rowData.value);
    }
}

/**
 * 告警模块给父元素添加class，并圈出来高亮
 */
var isWarnHover = function(rowData) {
        var idName = rowData["uniqueId"]
        if (rowData["uniqueId"].indexOf('Switch') != -1) { //开关
            $("#" + idName + " path[id*='off']").css({ visibility: 'visible' });
            $("#" + idName + " path[id*='on']").css({ visibility: 'visible' });

            //按条件单显

            if (rowData["value"] == '1') {
                $("#" + idName + " path[id*='off']").css({ visibility: 'hidden' });
                $("#" + idName + " path[id*='on']").css({ visibility: 'visible' });
                $("#" + idName + " path[id*='on']").css({ stroke: '#F5FA1F' }); //黄色
            } else {
                $("#" + idName + "path").css({ stroke: '#F5FA1F', fill: '#F00' }); //绿色
                $("#" + idName + " path[id*='off']").css({ visibility: 'visible' });
                $("#" + idName + " path[id*='off']").css({ stroke: '#F5FA1F' }); //黄色
                $("#" + idName + " path[id*='on']").css({ visibility: 'hidden' });
            }
            var parent = $("#" + idName);
            parent.find("path").css({ stroke: '#F5FA1F' })
        } else if (rowData["uniqueId"].indexOf('Color') != -1) {
            $("#" + idName).css({ stroke: '#F5FA1F', fill: '#F5FA1F' });
        }
    }
    /**
     * 函数名：RealPageTextCtl
     * 功能：实时图svg元件上的文字显示
     * 说明：rowData={"type":"TextMainStation","id":"xx","value":"yyyy.yy"}
     */
var RealPageTextCtl = function(rowData) {
        //集线上的文字：P、Q、Ia、COS
        var strText = '';
        var Index = { 'textIa': 1, 'textP': 2, 'textQ': 3, 'textCOS': 4 }; //位置映射
        var idName = '#text' + rowData["id"]; //拼接成ID						
        idName += ' tspan:nth-child(' + Index[rowData.name] + ')'; //子元素的id
        if (rowData.name in Index) {
            strText += rowData["value"];
            $(idName).empty().text(strText);
        }

        //敖别线文字
        RealPageTextCtl_AoBieLine(rowData);

        //母线文字，35kV(母线1#、母线2#) 220kV(母线5#)
        RealPageTextCtl_MainLine(rowData);

        //主变上的文字显示
        RealPageTextCtl_Mainstation(rowData);
    } //RealPageTextCtl

/**
 * 函数名：RealPageTextCtl_AoBieLine
 * 功能：实时图svg元件上的文字显示,敖别线部分
 * 说明：rowData={"type":"TextMainStation","id":"xx","value":"yyyy.yy"}
 */
var RealPageTextCtl_AoBieLine = function(rowData) {
    var strText = '';
    var Index = {
        'CycleLineTextUa': 1,
        'CycleLineTextIa': 2,
        'CycleLineTextP': 3,
        'CycleLineTextQ': 4,
        'CycleLineTextCOS': 5,
        'CycleLineTextF': 6
    }; //位置映射
    var idName = '#CycleLineText'; //拼接成ID						
    idName += '>tspan:nth-child(' + Index[rowData.name] + ')'; //子元素的id
    if (rowData.name in Index) {
        strText += rowData["value"];
        $(idName).empty().text(strText);
    }
}

/**
 * 函数名：RealPageTextCtl_MainLine
 * 功能：实时图svg元件上的文字显示,35kV(母线1#、母线2#) 220kV(母线5#)部分
 * 说明：rowData={"type":"TextMainStation","id":"xx","value":"yyyy.yy"}
 */
var RealPageTextCtl_MainLine = function(rowData) {
    var strText = '';
    var Index = {
        'TextMainLineF': 1,
        'TextMainLineUa': 2,
        'TextMainLineUb': 3,
        'TextMainLineUc': 4,
        'TextMainLineUab': 5
    }; //位置映射
    var idName = '#MainLineText'; //拼接成ID						
    if (rowData.name in Index) {
        idName += rowData.id; //id表示是哪条线
        idName += '>tspan:nth-child(' + Index[rowData.name] + ')'; //子元素的id
        strText += rowData["value"];
        $(idName).empty().text(strText);
    }
}

/* 函数名：RealPageTextCtl_Mainstation
 * 功能：实时图svg元件上的文字显示,主变部分
 * 说明：rowData={"type":"TextMainStation","id":"xx","value":"yyyy.yy"}
 */
// var RealPageTextCtl_Mainstation = function(rowData) {
//     if (rowData["name"].indexOf("TextMainStation") == -1) { //这一行，不包含主变内容，直接返回
//         return;
//     }

//     var strText = rowData["value"]; //值
//     if (rowData["name"].indexOf("TextMainStationTem") != -1) {
//         strText += '℃';
//     }

//     var idName = "#" + rowData["name"] + rowData["id"]; //变换svgID
//     idName += '>tspan'; //子元素的id
//     $(idName).empty().text(strText);
// }

/**
 * 函数名：MainStationTextCtl
 * 功能：主变svg元件上的文字显示
 * 说明：rowData={"name":"TextMainStation","id":"xx","value":"yyyy.yy"}
 */
var MainStationTextCtl = function(rowData) {

        if (rowData["name"].indexOf("TextMainStation") == -1) { //这一行，不包含主变内容，直接返回
            return;
        }

        var strText = '0';

        var idName = "#" + rowData["name"] + rowData["id"]; //svg上的textID
        strText = rowData["value"]; //值
        $(idName + ' tspan').empty().text(strText);
        return;

    } //MainStationTextCtl

/**
 * 函数名：CycleLineTextCtl
 * 功能：回路svg元件上的文字显示 敖别线
 * 说明：rowData={"type":"CycleLineTextUa","id":"1","value":"yyyy.yy"},rowData={"type":"CycleLineTextUc","id":"2","value":"yyyy.yy"}
 */
var CycleLineTextCtl = function(rowData) {

        if (rowData["name"].indexOf("CycleLineText") == -1) {
            return;
        }

        var strText = '0';

        var idName = "#" + rowData["name"] + rowData["id"]; //svg上的textID
        strText = rowData["value"]; //值
        $(idName + ' tspan').empty().text(strText);

    } //CycleLineTextCtl


var GeneratrixTextCtl = function(rowData) {
        if (rowData["name"].indexOf("GeneratrixText") == -1) { //220KV母联，不包含内容，直接返回
            return;
        }

        var strText = '0';

        var idName = "#" + rowData["name"] + rowData["id"]; //svg上的textID
        if (rowData["name"].indexOf("GeneratrixTextF") != -1) { //频率f=原码值*10/4095+45;
            var fData = rowData["value"] * 10 / 4095 + 45;
            fData = fData.toFixed(2); //保留2位小数
            strText = fData + " "; //值
        } else {
            strText = rowData["value"] + " "; //值
        }
        //strText=rowData["value"]+" ";//值
        $(idName).empty().text(strText);

    } //GeneratrixTextCtl	

/**
 * 函数名：RealPageTextCtl_Generatril
 * 功能：实时图母线svg元件上的文字显示
 * 说明：rowData={"name":"TextMainStation","id":"xx","value":"yyyy.yy"}
 */
var RealPageTextCtl_Generatril = function(rowData) {

        if (rowData["name"].indexOf("GeneratrixText") == -1) { //220KV母联，不包含内容，直接返回
            return;
        }

        var strText = '';


        //位置映射
        var Index = {
            'GeneratrixTextUa': 1,
            'GeneratrixTextUb': 2,
            'GeneratrixTextUc': 3,
            'GeneratrixTextUab': 4,
            'GeneratrixTextUbc': 5,
            'GeneratrixTextUca': 6,
            'GeneratrixTextIa': 7,
            'GeneratrixTextIb': 8,
            'GeneratrixTextIc': 9,
            'GeneratrixTextP': 10,
            'GeneratrixTextQ': 11,
            'GeneratrixTextCos': 12,
            'GeneratrixTextF': 13,
        };
        //单位
        var units = [
            ' kV',
            ' kV',
            ' kV',
            ' kV',
            ' kV',
            ' kV',
            ' A',
            ' A',
            ' A',
            ' MW',
            ' MVar',
            ' ',
            ' '
        ];
        var DescCNWords = [ //中字符
            '母联Ua:',
            '母联Ub:',
            '母联Uc:',
            '母联Uab:',
            '母联Ubc:',
            '母联Uca:',
            '母联Ia:',
            '母联Ib:',
            '母联Ic:',
            '母联P:',
            '母联Q:',
            '母联Cos:',
            '母联F:'
        ]; //中文描述


        var strText = '';

        var MainTextObj = "#GeneratrixText1"; //svg上的textID
        var idName = MainTextObj; //ID						
        idName += ' tspan:nth-child(' + Index[rowData.name] + ')'; //子元素的id

        strText += DescCNWords[Index[rowData.name] - 1];
        if (rowData["name"].indexOf("GeneratrixTextF") != -1) { //频率f=原码值*10/4095+45;
            var fData = rowData["value"] * 10 / 4095 + 45;
            fData = fData.toFixed(2); //保留2位小数
            strText += " " + fData; //值
        } else {
            strText += " " + rowData["value"]; //值
        }
        strText += units[Index[rowData.name] - 1];

        $(idName).empty().text(strText);

    } //RealPageTextCtl_Generatril

var RealPageTextCtl_PT = function(rowData) {
    if (rowData["name"].search("PT_") == -1) { //这一行，不包含PT内容，直接返回
        return;
    }
    var strText = '';
    var DisTextObj = {
        '351': '#PT_35KV_IText', //I母PT
        '352': '#PT_35KV_IIText', //II母PT
        '353': '#PT_35KV_IIIText', //III母PT
        '2201': '#PT_220KV_IText', //高压测
        '2202': '#PT_220KV_IIText' //中压测
    }; //svg上的textID
    if (rowData["name"].indexOf("PT_35KV") != -1) { //35KV PT
        var DescCNWords = [ //字符描述
            'Uab:',
            'Ubc:',
            'Uca:'
        ];
        var MainTextObj = rowData.id; //svg上的textID
        if (rowData["name"].indexOf("Uab") != -1) {
            var idName = DisTextObj[MainTextObj]; //ID		
            idName += ' tspan:nth-child(' + 1 + ')'; //子元素的id
            strText += DescCNWords[0];
            strText += " " + rowData["value"]; //值
            strText += ' KV';
            $(idName).empty().text(strText);
        }
        if (rowData["name"].indexOf("Ubc") != -1) {
            var idName = DisTextObj[MainTextObj]; //ID						
            idName += ' tspan:nth-child(' + 2 + ')'; //子元素的id
            strText += DescCNWords[1];
            strText += " " + rowData["value"]; //值
            strText += ' KV';
            $(idName).empty().text(strText);
        }
        if (rowData["name"].indexOf("Uca") != -1) {
            var idName = DisTextObj[MainTextObj]; //ID						
            idName += ' tspan:nth-child(' + 3 + ')'; //子元素的id
            strText += DescCNWords[2];
            strText += " " + rowData["value"]; //值
            strText += ' KV';
            $(idName).empty().text(strText);
        }
    }
    if (rowData["name"].indexOf("PT_220KV") != -1) {
        var DescCNWords = [ //字符描述
            'Uab:',
            'Ubc:',
            'Uca:',
            'Uabc:'
        ];
        var MainTextObj = rowData.id; //svg上的textID
        if (rowData["name"].indexOf("Uab") != -1) {
            var idName = DisTextObj[MainTextObj]; //ID						
            idName += ' tspan:nth-child(' + 1 + ')'; //子元素的id

            strText += DescCNWords[0];
            strText += " " + rowData["value"]; //值
            strText += ' KV';
            $(idName).empty().text(strText);
        }
        if (rowData["name"].indexOf("Ubc") != -1) {
            var idName = DisTextObj[MainTextObj]; //ID						
            idName += ' tspan:nth-child(' + 2 + ')'; //子元素的id

            strText += DescCNWords[1];
            strText += " " + rowData["value"]; //值
            strText += ' KV';
            $(idName).empty().text(strText);
        }
        if (rowData["name"].indexOf("Uca") != -1) {
            var idName = DisTextObj[MainTextObj]; //ID						
            idName += ' tspan:nth-child(' + 3 + ')'; //子元素的id
            strText = '';
            strText += DescCNWords[2];
            strText += " " + rowData["value"]; //值
            strText += ' KV';
            $(idName).empty().text(strText);
        }
        if (rowData["name"].indexOf("Uf") != -1) {
            var idName = DisTextObj[MainTextObj]; //ID						
            idName += ' tspan:nth-child(' + 4 + ')'; //子元素的id
            strText = '';
            strText += DescCNWords[3];
            strText += " " + rowData["value"]; //值
            strText += ' KV';
            $(idName).empty().text(strText);
        }
    }
}


var RealPageTextCtl_CycleLine = function(rowData) {
    if (rowData["name"].indexOf("CycleLineText") == -1) { //夏扎I、II，不包含回路内容，直接返回
        return;
    }

    var strText = '';

    var DisTextObj = {
        '1': '#CycleLineText1', //回线I 2254
        '2': '#CycleLineText2', //回线II 2255
    }; //svg上的textID

    //位置映射
    var Index = {
        'CycleLineTextUa': 0,
        'CycleLineTextUb': 1,
        'CycleLineTextUc': 2,
        'CycleLineTextUab': 3,
        'CycleLineTextUbc': 4,
        'CycleLineTextUca': 5,
        'CycleLineTextIa': 6,
        'CycleLineTextIb': 7,
        'CycleLineTextIc': 8,
        'CycleLineTextP': 9,
        'CycleLineTextQ': 10,
        'CycleLineTextCos': 11,
    };
    //单位
    var units = [
        ' kV',
        ' kV',
        ' kV',
        ' kV',
        ' kV',
        ' kV',
        ' A',
        ' A',
        ' A',
        ' MW',
        ' MVar',
        ' '
    ];
    var DescCNWords = [ //中字符
        '回线Ua:',
        '回线Ub:',
        '回线Uc:',
        '回线Uab:',
        '回线Ubc:',
        '回线Uca:',
        '回线Ia:',
        '回线Ib:',
        '回线Ic:',
        '回线P:',
        '回线Q:',
        '回线Cos:'
    ]; //中文描述


    var strText = '';
    var MainTextObj = DisTextObj[rowData["id"]]; //svg上的textID //#CycleLineText2
    var idName = DisTextObj[rowData["id"]]; //ID  	#CycleLineText2
    var oldIndex = Index[rowData.name]; //10
    if (!(oldIndex == 6 ||
            oldIndex == 9 ||
            oldIndex == 10)) { //P Q IA
        return;
    }
    var NewIndex = {
        6: 1,
        9: 2,
        10: 3
    };
    idName += ' tspan:nth-child(' + NewIndex[oldIndex] + ')'; //子元素的id
    if (MainTextObj.indexOf("1") != -1) { //I回线
        strText += "I";
    }
    if (MainTextObj.indexOf("2") != -1) { //II回线
        strText += "II";
    }
    strText += DescCNWords[Index[rowData.name]];
    strText += " " + rowData["value"]; //值
    strText += units[Index[rowData.name]];

    $(idName).empty().text(strText);

}

/* 函数名：RealPageTextCtl_Mainstation
 * 功能：实时图svg元件上的文字显示,主变部分
 * 说明：rowData={"type":"TextMainStation","id":"xx","value":"yyyy.yy"}
 */
var RealPageTextCtl_Mainstation = function(rowData) {
    if (rowData["name"].indexOf("TextMainStation") == -1) { //这一行，不包含主变内容，直接返回
        return;
    }
    //需要展现的变量，将对应展示文本块上的位置偏移
    var mapIndex = {
        'TextMainStationP12': 0,
        'TextMainStationQ12': 1,
        'TextMainStationIa12': 2,
        'TextMainStationGear10': 3,
        'TextMainStationP11': 4,
        'TextMainStationQ11': 5,
        'TextMainStationIa11': 6,


        'TextMainStationP22': 0,
        'TextMainStationQ22': 1,
        'TextMainStationIa22': 2,
        'TextMainStationGear20': 3,
        'TextMainStationP21': 4,
        'TextMainStationQ21': 5,
        'TextMainStationIa21': 6,

        'TextMainStationP32': 0,
        'TextMainStationQ32': 1,
        'TextMainStationIa32': 2,
        'TextMainStationGear30': 3,
        'TextMainStationP31': 4,
        'TextMainStationQ31': 5,
        'TextMainStationIa31': 6
    };

    //实时图上需要展现的变量映射的ID
    var mapSvgTextID = {
        'TextMainStationIa12': 'TextMainStation1',
        'TextMainStationP12': 'TextMainStation1',
        'TextMainStationQ12': 'TextMainStation1',
        'TextMainStationGear10': 'TextMainStation1',
        'TextMainStationIa11': 'TextMainStation1',
        'TextMainStationP11': 'TextMainStation1',
        'TextMainStationQ11': 'TextMainStation1',

        'TextMainStationIa22': 'TextMainStation2',
        'TextMainStationP22': 'TextMainStation2',
        'TextMainStationQ22': 'TextMainStation2',
        'TextMainStationGear20': 'TextMainStation2',
        'TextMainStationIa21': 'TextMainStation2',
        'TextMainStationP21': 'TextMainStation2',
        'TextMainStationQ21': 'TextMainStation2',

        'TextMainStationIa32': 'TextMainStation3',
        'TextMainStationP32': 'TextMainStation3',
        'TextMainStationQ32': 'TextMainStation3',
        'TextMainStationGear30': 'TextMainStation3',
        'TextMainStationIa31': 'TextMainStation3',
        'TextMainStationP31': 'TextMainStation3',
        'TextMainStationQ31': 'TextMainStation3'
    };

    var idName = rowData["name"] + rowData["id"];

    var myArray = Object.keys(mapSvgTextID);
    if (myArray.indexOf(idName) == -1) { //不在范围内
        return;
    }
    var strText = '';
    var DescCNWords = [ //字符
        '高压侧P:',
        '高压侧Q:',
        '高压侧Ia:',
        '主变挡位:',
        '中压侧P:',
        '中压侧Q:',
        '中压侧Ia:'
    ]; //中文描述
    var units = [ //字符
        ' MW',
        ' MVar',
        ' A',
        ' ',
        ' MW',
        ' MVar',
        ' A'
    ]; //中文描述
    strText += DescCNWords[mapIndex[idName]];
    strText += " " + rowData["value"]; //值
    strText += units[mapIndex[idName]];

    var posIndex = mapIndex[idName] + 1;
    idName = "#" + mapSvgTextID[idName]; //变换svgID
    idName += ' tspan:nth-child(' + posIndex + ')'; //子元素的id
    $(idName).empty().text(strText);
}



var svgHandler = function(vue, index, data) {
    let pageIdx = index;
    //---------控制部分----			
    var json = []; //alldata[0]["data"];
    if (data && data.length > 0) {
        json = data
        for (var i = 0, len = json.length; i < len; i++) { //遍历元件
            var metaObj = json[i];
            //-----------断路器和开关是公共部分--------------
            //开关
            switchCtl(metaObj);

            //断路器
            circuitBreaker(metaObj);

            //告警高亮
            if (metaObj.isWarn !== null) {
                isWarnHover(metaObj)
            }
            //文字
            RealPageText(metaObj)


            //---------------区分显示-----------------------
            if (pageIdx == 2) { //实时图，文字（有功、无功、电流）
                RealPageTextCtl(metaObj);
                RealPageTextCtl_PT(metaObj)
                RealPageTextCtl_Generatril(metaObj)
                RealPageTextCtl_CycleLine(metaObj)

            }
            if (pageIdx == 3) { //主变分图。1#主变、2#主变、					
                MainStationTextCtl(metaObj);
            }

            if (pageIdx == 4) { //敖别线	//回线分图 2255回线、2254回线												
                CycleLineTextCtl(metaObj)
            }

            if (pageIdx == 5) { //母联分图										
                GeneratrixTextCtl(metaObj);
            }
        }
    }
    // if (scheduler) {
    //     timer = setTimeout(svgHandler, vue, index, data);
    // } else {
    //     clearTimeout(timer);
    //     timer = 0;
    // }
}
export default {
    init(vue, index, data) {
        svgHandler(vue, index, data);
    },
    clearTimer() {
        clearTimeout(timer);
    }
}