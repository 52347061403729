import {
    post,
    get,
    post_old
} from "../request"

export default {
    // 总览
    addLog(data) {
        return post_old('/log/addLog', data);
    },
    //租户
    getTenantList(data) {
        return post('log/queryTenant', data);
    },
    //用户
    getUserList(data) {
        return post_old('log/queryUserList', data);
    },
    //页面
    getPageList(data) {
        return post('log/queryModularPage', data);
    },


    //访问日志信息
    queryVisitLog(data) {
        return post('log/queryVisitLog', data);
    },
    //操作日志信息
    queryOperationLog(data) {
        return post('log/queryOperationLog', data);
    },
}