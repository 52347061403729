import Vue from 'vue'
import VueRouter from 'vue-router'

import login from '@/views/login.vue'
import layout from '@/components/layout';
import home from '@/components/home.vue'

import BackSys from '@/router/backSys'
import Admin from '@/router/adminSys'

import monitorPage from '@/router/monitor'
import faultAlarm from '../router/faultAlarm'
import analyse from '../router/analyse'
import health from '../router/health'
import report from '../router/report'
import api from '../request/api'

//首页大屏
import bigscreem from '@/views/home/bigScreem.vue'
Vue.use(VueRouter)

const routes = [{
        path: '/',
        name: 'login',
        component: login,
    },
    {
        path: '/bigscreem',
        name: 'bigscreem',
        component: bigscreem,
    },
    {
        path: '/system',
        name: 'system',
        component: layout,
        children: [
            ...BackSys,
            ...Admin,
        ]
    },
    {
        path: '/monitor',
        name: 'monitor',
        component: home,
        title: '监控中心',
        meta: { hasSubNav: true },
        children: [
            ...monitorPage
        ]
    },
    {
        path: '/alarm',
        name: 'alarm',
        component: home,
        title: '告警中心',
        meta: { hasSubNav: true },
        children: [
            ...faultAlarm
        ]
    },
    {
        path: '/analyse',
        name: 'analyse',
        component: home,
        title: '分析中心',
        children: [
            ...analyse
        ]
    },
    {
        path: '/health',
        name: 'health',
        component: home,
        title: '健康中心',
        children: [
            ...health
        ]
    },
    {
        path: '/report',
        name: 'report',
        component: home,
        title: '报表中心',
        children: [
            ...report
        ]
    },
]

const router = new VueRouter({
    mode: 'hash',
    base: process.env.BASE_URL,
    routes
})
const VueRouterPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(to) {
    return VueRouterPush.call(this, to).catch(err => err)
}

function Flat(arr) {
    return [].concat(...arr.map(item => [].concat(item, ...Flat(item.children))));
}

function analyticsRequest(to, from) { //路由做埋点
    // 只统计页面跳转数据，不统计当前页 query 不同的数据
    // 所以这里只使用了 path, 如果需要统计 query 的，可以使用 to.fullPath
    api.log.addLog({
        address: to.path,
        source: 0
    }).then((result) => {

    }).catch((err) => {

    });
};


router.beforeEach((to, from, next) => {
    window.addEventListener('setItemEvent', function(e) { //utils--store function（）
        if (e.key == "title") {
            document.title = e.newValue ? e.newValue : '新能源云集控'
        }
    })
    if (sessionStorage.getItem('admin')) {
        if (sessionStorage.getItem('menuList')) {
            let menu = JSON.parse(sessionStorage.getItem('menuList'))
            let list = []
            menu.map(item => {
                list.push(item)
                if (item.children) {
                    list.push(...item.children)
                }
            })
            if (to.path == '/' || to.path == '/myHome') {
                next()
            } else if (list.some(item => { return to.path == item.path || (to.path.includes('health') && item.path.includes('energy')) })) {
                analyticsRequest(to, from)
                next();
            } else {
                next({ path: '/' })
            }
        } else {
            if (to.path == '/') {
                next()
            } else {
                next({ path: '/' })
            }
        }
    } else {
        if (to.path == '/') {
            next()
        } else {
            next({ path: '/' })
        }
    }
});

export default router