import {
    post,
    get,
    post_old,
    Delete_old,
    get_old,
    postExport,
    postExportFile,
} from "../request"

export default {
    //风机型号集合
    getModel(data) {
        return get('/fault/tree/model/list', data);
    },
    //故障树节点下拉框
    getNodeList(data) {
        return get('/fault/tree/node/list', data);
    },
    //故障树
    getTree(data) {
        return get('/fault/tree/info', data);
    },
    //删除故障树
    delTree(data) {
        return Delete_old('/fault/tree/del', data);
    },
    //新增修改故障树
    addTree(data) {
        return post('/fault/tree/saveOrUpdate', data);
    },
    //导出故障树模板
    exportTree(data) {
        return get_old('/fault/tree/export/template', data);
    },
    //上传故障树文件
    uploadTree(data) {
        return post('/fault/tree/import', data);
    },


    //能效评估状态饼图
    getReliabilityStatusPie(data) {
        return post('/reliability/queryReliabilityStatusPieChart', data);
    },
    //能效评估状态列表
    getReliabilityStatusList(data) {
        return post('/reliability/queryReliabilityStatusList', data);
    },
    //能效诊断工单分页列表
    getReliabilityCheckOrderList(data) {
        return post('/reliability/queryReliabilityCheckOrderList', data);
    },

    //能效诊断说明
    getCheckOrderPrinciple(data) {
        return post_old('/reliability/queryReliabilityCheckOrderPrinciple', data);
    },
    //能效诊断工单柱状图
    getCheckOrderBarChart(data) {
        return post('/reliability/queryReliabilityCheckOrderBarChart', data);
    },
    //能效--场站详情矩阵
    getReliabilityStationMatrix(data) {
        return post_old('/reliability/queryReliabilityStationMatrix', data);
    },
    //能效--单机详情列表
    getReliabilityTurbineDetailsList(data) {
        return post_old('/reliability/queryReliabilityTurbineDetailsList', data);
    },
    //能效--单机详情
    getReliabilityTurbineDetails(data) {
        return post_old('/reliability/queryReliabilityTurbineDetails', data);
    },

    //能效--编辑
    editReliabilityCheckOrder(data) {
        return post_old('/reliability/updateReliabilityCheckOrderStatus', data);
    },
    // 查询单机详情折线图
    turbineLineChart(data) {
        return post_old('/reliability/queryReliabilityTurbineDetailsLineChart', data);
    },








}