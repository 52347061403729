<!--
 * @Author: liumeikun
 * @Date: 2023-09-20 18:29:18
 * @LastEditors: Do not edit
 * @LastEditTime: 2023-10-31 14:07:45
 * @filePath: Do not edit
-->
<template>
    <div class="my-charts" ref="myCharts"></div>
</template>
<script type="text/javascript">
import resize from "../mixins/resize";
export default {
    name: "myCharts",
    mixins: [resize],
    props: {
        options: {
            type: Object,
            require: true,
            default: function () {
                return {};
            },
        },
    },
    data() {
        return {
            myCharts: null,
            Timer:null,
            index:0,
        };
    },
    methods: {
        initEcharts() {
            if (!this.options) {
                return;
            }
            if(!this.myCharts) {
                this.myCharts = this.$echarts.init(this.$refs.myCharts, null, { renderer: 'svg' });
                this.$emit('inited', this.myCharts);
            }
            this.myCharts.setOption(this.options,true);
            this.$nextTick(() => {
               this.$emit('setOption', this.myCharts);
            });
           
        },
        reload(option) {
            this.myCharts.setOption(option)
            // this.$emit('setOption', this.myCharts);
        },
    },
    watch: {
        options: {
            handler() {
                this.initEcharts();
            }
        }
    },
    mounted() {
        this.initEcharts();
    },
    beforeDestroy () {
        this.myCharts.clear()
        this.myCharts.dispose()
    }
};
</script>
<style lang="scss" scoped>
.my-charts {
    height: 100%;
}
</style>
  