import CryptoJS from 'crypto-js';
export function encryptByDES(message) {
    var DES_KEY = "6gajHBVRT2girwQj";
    var keyHex = CryptoJS.enc.Utf8.parse(DES_KEY);
    var encrypted = CryptoJS.DES.encrypt(message, keyHex, {
        mode: CryptoJS.mode.ECB,
        padding: CryptoJS.pad.Pkcs7
    });
    return encrypted.toString();
}
//DES 解密
export function decryptByDES(message) {
    var DES_KEY = "6gajHBVRT2girwQj";
    var keyHex = CryptoJS.enc.Utf8.parse(DES_KEY);
    var decrypted = CryptoJS.DES.decrypt(message, keyHex, {
        mode: CryptoJS.mode.ECB,
        padding: CryptoJS.pad.Pkcs7
    });
    return decrypted.toString(CryptoJS.enc.Utf8);
}