<template>
    <!-- 中间的风场弹窗 -->
    <ul class="farm-info">
        <li
            class="farm-item"
            v-for="(item, index) in farmList"
            :key="index"
            :class="{ 'current': curIndex == index, 'next': nextIndex == index, 'pre': preIndex == index && nextIndex != index }">
            <!-- <p class="farm-name">{{ item.station }}</p> -->
            <p v-if="item.station.length<14"  class="farm-name">{{ item.station}}</p>
            <el-tooltip v-else class="item"  effect="dark" :content="item.station" placement="top-start">
                <p class="farm-name" >{{item.station.slice(0,12)+'...'}}</p>
            </el-tooltip>
            <p class="info-line">
                <span class="name">装机容量：</span>
                <span class="value">{{ item.capacity }}</span>
                <span class="unit">MW</span>
            </p>
            <p class="info-line">
                <span class="name">装机台数：</span>
                <span class="value">{{ item.count }}</span>
                <span class="unit">台</span>
            </p>
            <p class="info-line">
                <span class="name">日发电量：</span>
                <span class="value">{{ item.dayGeneratingCapacity }}</span>
                <span class="unit">万kWh</span>
            </p>
            <p class="info-line">
                <span class="name">有功功率：</span>
                <span class="value">{{ item.realTimePower }}</span>
                <span class="unit">MW</span>
            </p>
        </li>
    </ul>
</template>

<script>
export default {
    props:{
        farmList:{
            type:Array,
            default:()=>[]
        }
    },
    data() {
        return {
            // farmList: [],
            curIndex: 0,
            preIndex: -1,
            nextIndex: 1,
            timer: null,
            timerTimeout: null,
        };
    },
    methods: {
        swiper() {
            this.timer = setInterval(() => {
                this.preIndex = this.curIndex;
                this.curIndex = this.nextIndex;
                this.nextIndex++;
                this.timerTimeout = setTimeout(() => {
                    this.preIndex = -1;
                    this.nextIndex = this.nextIndex == this.farmList.length?0:this.nextIndex;
                }, 500);
                this.farmList.forEach((item,index) => {
                    if(this.curIndex ==index){
                        this.$emit('getShow',index)
                    }
                });
            }, 5000);
        },
        clearTimer() {
            clearInterval(this.timer);
            clearTimeout(this.timerTimeout);
        }
    },
    watch: {
        farmList() {
            if(this.farmList.length > 1) {
                //开启轮播
                this.swiper();
            }else {
                this.clearTimer();
            }
        },
    },
    beforeDestroy() {
        if(this.timer) {
            this.clearTimer();
        }
    }
};
</script>

<style lang="scss" scoped>
.farm-info {
    position: relative;
    display: flex;
    justify-content: center;
    padding: 32px;
    font-size: 15px;
    background-image: url(@/assets/images/screem/box-frame.png);
    background-size: 100% 100%;
    overflow: visible;
    .farm-item {
        position: absolute;
        width: 100%;
        top: 100%;
        padding: 0 32px;
        opacity: 0;
        transition: all .5s;
        .farm-name {
            margin-bottom: 10px;
            text-align: center;
            font-size: 13px;
        }
        .info-line {
            display: flex;
            padding-left: 10px;
            span {
                display: inline-block;
                font-size: 10px;
                line-height: 24px;
                transform: scale(0.833);
            }
            .value {
                width: 30%;
            }
        }
        &.current {
            top: 32px;
            opacity: 1;
        }
        &.pre {
            top: -100%;
            transform: scale(0.5);
            opacity: 0;
        }
        &.next {
            top: 100%;
            opacity: 0;
        }
    }
}
@keyframes swiper {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
</style>