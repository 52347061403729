<template>
  <div class="block">
    <div class="b-title">
      <span>{{ title }}</span>
    </div>
    <div class="b-content">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  props: ["title"],
  data() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
.block {
  font-size: 16px;
  .b-title {
    position: relative;
    width: 200px;
    height: 35px;
    padding-left: 18px;
    line-height: 35px;
    background: linear-gradient(
      90deg,
      rgba(43, 138, 255, 0.2),
      rgba(43, 138, 255, 0)
    );
    span {
      font-weight: bold;
      font-size: 15px;
      color: #fefefe;
    }
    &::before {
      position: absolute;
      content: "";
      width: 4px;
      height: 100%;
      left: 0;
      top: 0;
      background: linear-gradient(90deg, #2b8aff, rgba(43, 138, 255, 0));
    }
  }
  .b-content {
    height: calc(100% - 35px);
    width: 100%;
  }
}
</style>