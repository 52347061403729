<template>
  <div class="meun-tab">
    <ul class="tab-box">
      <li 
        class="tab" 
        v-for="(item, index) in tabs" 
        :key="index" 
        :id="item.path"
        :class="{ active: $route.path == item.path}" 
        @contextmenu.prevent.stop="openMenu($event, item, index)" 
        @click="tabClick(item)">
         {{ item.title }}
        <i v-show="tabs.length !== 1" class="icon el-icon-close" @click.stop="tabRemove(item, index)"></i>
      </li>
    </ul>
     
    <!-- <el-carousel class="tab-box" :autoplay="false" :loop="false" indicator-position="none" type="card" height="50px">
      <el-carousel-item class="tab"  v-for="(item, index) in tabList"   :key="index" 
        :id="item.path"
        :class="{ active: $route.path == item.path}" 
        @contextmenu.prevent.stop="openMenu($event, item, index)" 
        @click="tabClick(item)">
        {{ item.title }}
        <i v-show="tabs.length !== 1" class="icon el-icon-close" @click.stop="tabRemove(item, index)"></i>
      </el-carousel-item>
    </el-carousel> -->
    <ul v-show="visible" class="contextmenu" :style="{ left: left + 'px', top: top + 'px' }">
      <li @click="refresh()">刷新</li>
      <!-- <li @click="fullScreen()">全屏</li> -->
      <li v-show="tabs.length !== 1" @click="closeOther()">关闭其他</li>
      <li v-show="tabs.length !== 1" @click="closeCurrent()">关闭当前</li>
    </ul>
    <!-- <div class="left-right-tab-button" v-show="tabs.length>13">
      <span @click="clickLeft"><i class="el-icon-arrow-left"></i></span>
      <span @click="clickRight"><i class="el-icon-arrow-right"></i></span>
    </div> -->
  </div>
</template>
<script>
import { mapState, mapActions, mapMutations } from "vuex";
export default {
  data() {
    return {
      visible: false,
      left: 0,
      top: 0,
      current: "",
      index: 0,
      // tabList:[],
    };
  },
  watch: {
    visible(newVal, oldVal) {
      if (newVal) {
        document.body.addEventListener("click", this.closeMenu);
      } else {
        document.body.removeEventListener("click", this.closeMenu);
      }
    },
  },
  computed: {
    ...mapState("navtab", ["tabs", "activeItem"]),
    tabList(){
      let tabList = []
      if(this.tabs.length<14){//13
        tabList = this.tabs
      }else{
        tabList = this.tabs.slice(0,13)
        let index = 0
        this.tabs.map((item,i) =>{
          if(this.$route.path === item.path){
            index= i
          }
        })
        if(index<5){
          
        }else if(index>12){
          console.log(index)
          // tabList.splice(0,index-12);
          // let newTab =  this.tabs.slice(13)
          // console.log(newTab)
          // tabList.concat(newTab)
          tabList= this.tabs.slice(index-12)
          console.log(tabList)
        }
      }
      return tabList
    }
  },
  methods: {
    ...mapMutations("navtab", ["switchTab"]),
    ...mapActions("navtab", ["closeTab", "delTabOther"]),
      tabRemove (item, index) {
        this.$route.meta.keepAlive = false;
        this.closeTab(item.path);
        let lastIndex = this.tabs.length - 1
        // 异步阻塞一下，否则activeItem还是当前关闭tab的值
        setTimeout(() => {
            // 如果关闭的是当前tab, 优先跳转到下一个tab
            if (this.$route.path === item.path) {
                if (index <= lastIndex) {
                    this.tabClick(this.tabs[index])
                } else {
                    this.tabClick(this.tabs[index - 1])
                }
            }
        }, 0);
    },
    clickLeft(){
      this.$nextTick(() => {
        document.getElementById(this.$route.path).scrollIntoView()
      })
      // if(this.tabs[0].path == this.tabList[0].path){
      //   return false;
      // }else{
      //   let path = this.tabList[0].path
      //   this.tabs.map((item,index) =>{
      //     if(path ==item.path){
      //       this.tabList.splice(11,1);
      //       this.tabList.unshift(this.tabs[index-1]);
      //     }
      //   })
      // }
    },
    clickRight(){
      let path = this.tabs[12].path;
      this.tabs.map((item,index) =>{
        if(path == item.path){
          this.tabList.splice(0,1);
          this.tabList.push(this.tabs[index+1]);
        }
      })
      // }
    },
    tabClick(item) {
        if (!this.$route.meta.keepAlive) {
          this.$route.meta.keepAlive = true;
        }
        this.switchTab(item.path);
        this.$router.push({ path: item.path });
    },
    openMenu (event, item, index) {
      const e = event || window.event;
      this.left = e.clientX + 10;
      this.top = e.clientY + 10;
      this.visible = true;
      this.current = item;
      this.index = index;
    },
    closeMenu() {
      this.visible = false;
    },
    fullScreen() {
      this.$emit("getFullScreen", this.activeItem);
    },
    refresh() {
      this.$route.meta.keepAlive = false;
      if (this.$route.path === this.tabs[this.index].path) {
        this.$router.push("/");
        this.$nextTick(() => {
          this.$router.push(this.tabs[this.index].path);
          this.$route.meta.keepAlive = true;
        });
      }
    },
    closeOther() {
      // let t = this
      //   // 异步阻塞一下，否则activeItem还是当前关闭tab的值
      // setTimeout(function () {
      //     t.$router.push({ path: t.activeItem })
      // }, 0)
      this.delTabOther(this.current.path);
    },
    closeCurrent() {
      let t = this;
      // 异步阻塞一下，否则activeItem还是当前关闭tab的值
      setTimeout(function () {
        t.$router.push({ path: t.activeItem });
      }, 0);
      // console.log(this.$route)
      this.closeTab(this.current.path);
    },
  },
};
</script>
<style lang="scss" scoped>
.meun-tab {
  margin: 0.1rem 0.2rem 0 0.16rem;
  font-size: 0.16rem;
 
  .tab-box {
    // width: 90%;
    margin-right: 0.5rem;
    position: relative;
    display: flex;
    overflow-x: scroll;
    .tab {
      position: relative;
      // display: inline-block;
      white-space:nowrap;
      font-size: 0.16rem;
      height: 0.32rem;
      line-height: 0.32rem;
      padding: 0 0.4rem 0 0.2rem;
      background-image: url('../assets/images/trapezoid.png');
      background-size: 100%;
      background-repeat: no-repeat;
      clip-path: polygon(6% 0%, 94% 0%, 100% 100%, 0% 100%);
      text-align: center;
      transition: all 0.3s ease 0s;
      cursor: pointer;
      &:hover {
        color:#4e99fa;
        transform: translateY(-2px);
      }
      span {
        padding-right: 0.1rem;
      }
      .icon {
        position: absolute;
        top: 0.09rem;
        right: 0.15rem;
        font-size: 0.16rem;
      }
    }
    .active {
      position: relative;
      padding: 0 0.5rem 0 0.3rem;
      color:#4e99fa;
      background-color: rgba(5, 20, 53, 0.9);
      transform: translateY(-1px);
    }
  }
  .left-right-tab-button{
    position: absolute;
    right: 0.2rem;
    top:0.16rem;
  }
  .contextmenu {
    margin: 0;
    background: rgba(16, 44, 98, 0.95);
    z-index: 9999;
    position: fixed;
    padding: 5px 0;
    border-radius: 0.04rem;
    font-size: 0.14rem;
    font-weight: 400;
    color: #fff;
    box-shadow: 2px 2px 3px 0 rgba(0, 0, 0, 0.3);
    li {
      width: 1rem;
      height: 0.32rem;
      line-height: 0.32rem;
      padding: 0 0.12rem 0 0.15rem;
      cursor: pointer;
      &:hover {
        background-color: rgb(11, 33, 77);
      }
    }
  }
}
</style>
