<template>
    <!-- 业务指标 -->
    <ul class="target-wrap">
        <li class="target">
            <div class="icon-wrap">
                <i class="com-icon t-i-round"></i>
                <i class="com-icon t-i-target1"></i>
                <i class="com-icon t-i-bottom"></i>
            </div>
            <p class="v-wrap">
                <span class="value">{{eUHour}}</span>
                <span class="unit">小时</span>
            </p>
            <p class="text">等效利用小时数</p>
        </li>
        <li class="target">
            <div class="icon-wrap bounce1">
                <i class="com-icon t-i-round"></i>
                <i class="com-icon t-i-target2"></i>
                <i class="com-icon t-i-bottom"></i>
            </div>
            <p class="v-wrap">
                <span class="value">{{mtbf}}</span>
                <span class="unit">小时</span>
            </p>
            <p class="text">平均无故障运行时间</p>
        </li>
        <li class="target">
            <div class="icon-wrap">
                <i class="com-icon t-i-round"></i>
                <i class="com-icon t-i-target3"></i>
                <i class="com-icon t-i-bottom"></i>
            </div>
            <p class="v-wrap">
                <span class="value">{{mttr}}</span>
                <span class="unit">小时</span>
            </p>
            <p class="text">平均故障修复时间</p>
        </li>
    </ul>
</template>

<script>
import whhProgress from "../com/progress.vue";
export default {
    components: {
        whhProgress,
    },
    data() {
        return {
            tba:0,
            mttr:0,
            eUHour:0,
            mtbf:0,
        };
    },
    created() {
        this.getKqi()
    },
    methods:{
        async getKqi(){
            try {
                let res = await this.$api.monitor.getKqi()
                if(res.code ==20000){
                    this.tba = res.data.tba
                    this.mttr = res.data.mttr
                    this.eUHour = res.data.eUHour
                    this.mtbf = res.data.mtbf
                }
            } catch (error) {
                console.log(error)
            }
        }
    },
};
</script>

<style lang="scss" scoped>
.t-i-round {
    background-image: url(@/assets/images/screem/t-i-round.png);
}
.t-i-bottom {
    background-image: url(@/assets/images/screem/t-i-bottom.png);
}
.t-i-target1 {
    background-image: url(@/assets/images/screem/t-i-target1.png);
}
.t-i-target2 {
    background-image: url(@/assets/images/screem/t-i-target2.png);
}
.t-i-target3 {
    background-image: url(@/assets/images/screem/t-i-target3.png);
}
.target-wrap {
    display: flex;
    height: 100%;
    width: 100%;
    padding: 20px 0;
    font-size: 15px;
    .target {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-end;
        height: 100%;
        .icon-wrap {
            position: absolute;
            margin: 0 auto;
            top: 30px;
            animation: bounce 5s linear infinite;
            .com-icon {
                position: absolute;
            }
            .t-i-round {
                width: 50px;
                height: 46px;
                left: 50%;
                top: 0;
                margin-left: -25px;
                animation: moving 2s linear infinite;
            }
            .t-i-bottom {
                width: 54px;
                height: 50px;
                left: 50%;
                top: 36px;
                margin-left: -27px;
            }
            .t-i-target1,
            .t-i-target2,
            .t-i-target3 {
                width: 38px;
                height: 36px;
                top: 6px;
                left: 50%;
                margin-left: -19px;
            }
            .t-i-target3 {
                height: 26px;
                top: 10px;
            }
        }
        > p {
            font-size: 11px;
            margin-bottom: 20px;
        }
        .v-wrap {
            color: #fefefe;
            .value {
                color: #fff;
                font-size: 15px;
                font-weight: bold;
                margin-right: 8px;
            }
            .unit {
                color: #fefefe;
                font-size: 11px;
            }
        }
        .text {
            padding: 0 20px;
            text-align: center;
            color: #FEFEFE;
        }
    }
}

@keyframes moving {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.bounce1 {
    animation-delay: 0.75s !important;
}
.bounce2 {
    animation-delay: 1.5s !important;
}
//弹动
@keyframes bounce {
    /* scaleY(.98) 模拟的是球落下的时候，受重力影响而产生的变形 */
    from,
    to {
        transform: translateY(0px);
    }
    25% {
        transform: translateY(-20px);
    }
    50% {
        transform: translateY(0px);
    }
    75% {
        transform: translateY(20px);
    }
}
</style>