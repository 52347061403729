export default {
    namespaced: true,
    state: {
        menus: JSON.parse(sessionStorage.getItem('menuList')) || [],
        tabs: [], //标签
        activeItem: "", // 默认选中首页
        includeCom: [], //缓存的
        cancelArr: [], //已取消缓存的
    },
    getters: {
        activeItem(state) {
            return state.activeItem
        }
    },
    mutations: {
        initMenu(state, menu) {
            state.menus = menu
        },
        initTabs(state, tabs) {
            state.tabs = tabs
        },
        initIncludeCom(state, newComs) {
            state.includeCom = newComs
        },
        addTab(state, tab) {
            state.tabs.push(tab)
        },
        addincludeCom(state, com) {
            state.includeCom.push(com)
        },
        switchTab(state, nowIndex) {
            state.activeItem = nowIndex
        },
        initCancel(state, data) {
            state.cancelArr = data
        },
        addCancel(state, data) {
            state.cancelArr.push(data)
        },
    },
    actions: {
        getMenu(context, data) {
            // 查询所有菜单（如果做权限管理，即根据不同用户（角色），显示不同的菜单，可以在后台就判断权限，然后对应有权限的菜单列表）
            if (sessionStorage.getItem('menuList')) {
                context.commit('initMenu', JSON.parse(sessionStorage.getItem('menuList')))
                context.state.tabs = []
            } else {
                context.state.tabs = []
                context.commit('initMenu', data)
            }
        },
        clickMenuItem(context, index) {
            // ‘index’一直固定在tabs里面，所有不需要‘addTab’
            if (index !== 'index') {
                // find()函数用来查找目标元素，找到就返回该元素，找不到返回undefined。
                var tab = context.state.tabs.find(f => f.path === index)
                    // tabs里面没有，就去总菜单去查询
                if (!tab) {
                    let menu = {}
                        // 首先查询一级菜单
                    menu = context.state.menus.find(f => f.path === index)
                        // 如果当前menu不在一级菜单，则查询所有二级菜单
                    if (!menu) {
                        // flat()用于将嵌套的数组“拉平”，变成一维数组。该方法返回一个新数组，对原数据没有影响
                        menu = context.state.menus.map(a => a.children).flat().find(f => f.path === index)
                    }
                    let newTab = {
                        title: menu.title,
                        path: menu.path,
                        closable: true,
                        includeCom: menu.title
                    }

                    context.commit('addTab', newTab)
                        // 缓存组件
                    let a = menu.path.lastIndexOf("/");
                    let includeCom = menu.path.slice(a + 1)
                    let tab = []
                    tab = context.state.cancelArr.filter(i => i !== includeCom)
                    context.commit('initCancel', tab)
                    context.commit('addincludeCom', includeCom)
                }
            }
            context.commit('switchTab', index)
        },
        closeTab(context, index) {
            // findIndex()函数也是查找目标元素，找到就返回元素的位置，找不到就返回-1。

            //当前需要关闭的tab
            let indexNum = context.state.tabs.findIndex(f => f.path === index)
            let a = index.lastIndexOf("/");
            let cancel = index.slice(a + 1)
            if (!context.state.cancelArr.includes(cancel)) {
                context.commit('addCancel', cancel)
            }
            // 当前选中菜单
            let activeItem = context.state.activeItem

            // 除移除的tab之外的所有tabs
            let newTabs = context.state.tabs.filter(f => f.path !== index)
                // 重新初始化tabs
            context.commit('initTabs', newTabs)
                // 筛选被缓存的组件，也就是删除缓存组件
            let newComs = []
            newTabs.map(val => {
                let a = val.path.lastIndexOf("/");
                let cancel = val.path.slice(a + 1)
                newComs.push(cancel)
            })

            // 重新初始化includeCom
            context.commit('initIncludeCom', newComs)
                // 如果删除的tab正好是当前选中的tab，则执行“switchTab”，改变activeItem
            if (activeItem === index) {
                // 如果indexNum===0，则表示switchTab到index（但是‘首页’不可能被删除，所以如果indexNum不可能为0），否则跳转到删除tab的上一个tab
                context.commit('switchTab', indexNum === 0 ? 'index' : newTabs[indexNum - 1].path)
            }
        },
        //删除其他的tab
        delTabOther(context, index, name) {
            let newTabs = context.state.tabs.filter(item => {
                return item.path == index
            })
            let newComs = context.state.includeCom.filter(item => {
                return item == name
            })
            context.commit('initTabs', newTabs)
                // let newComs = newTabs.map(val => {
                //         return val.includeCom
                //     })
                // 重新初始化includeCom
            context.commit('initIncludeCom', newComs)
        },
    }
}