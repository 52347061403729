<template>
    <!-- 中间顶部信息 -->
    <ul class="info-wrap">
        <li class="info-item">
            <div class="icon-wrap">
                <i class="com-icon icon-circle"></i>
                <i class="com-icon icon-contain icon-inner"></i>
            </div>
            <div class="info">
                <p class="name">装机容量</p>
                <p class="v-wrap">
                    <span class="value">{{capacity}}</span>
                    <span class="unit">MW</span>
                </p>
            </div>
        </li>
        <li class="info-item">
            <div class="icon-wrap">
                <i class="com-icon icon-circle move1"></i>
                <i class="com-icon icon-farmcount icon-inner"></i>
            </div>
            <div class="info">
                <p class="name">场站数量</p>
                <p class="v-wrap">
                    <span class="value">{{stationNum}}</span>
                    <span class="unit">个</span>
                </p>
            </div>
        </li>
        <li class="info-item">
            <div class="icon-wrap">
                <i class="com-icon icon-circle move2"></i>
                <i class="com-icon icon-turcount icon-inner"></i>
            </div>
            <div class="info">
                <p class="name">装机台数</p>
                <p class="v-wrap">
                    <span class="value">{{deviceCount}}</span>
                    <span class="unit">台</span>
                </p>
            </div>
        </li>
        <li class="info-item">
            <div class="icon-wrap">
                <i class="com-icon icon-circle move3"></i>
                <i class="com-icon icon-daypower icon-inner"></i>
            </div>
            <div class="info">
                <p class="name">日发电量</p>
                <p class="v-wrap">
                    <span class="value">{{dayGeneratingCapacity}}</span>
                    <span class="unit">万kWh</span>
                </p>
            </div>
        </li>
        <li class="info-item">
            <div class="icon-wrap">
                <i class="com-icon icon-circle move4"></i>
                <i class="com-icon icon-availbility icon-inner"></i>
            </div>
            <div class="info">
                <p class="name">可利用率</p>
                <p class="v-wrap">
                    <span class="value">{{tba}}</span>
                    <span class="unit">%</span>
                </p>
            </div>
        </li>
    </ul>
</template>

<script>
export default {
    data() {
        return {
            time:null,
            capacity:0, 
            deviceCount:0,
            stationNum:0,
            dayGeneratingCapacity:0,
            tba:0,
        };
    },
    created() {
        this.getBaseMessage()
        this.time = setInterval(()=>{
            this.getBaseMessage()
        },1000 * 5)
    },
    methods: {
        getBaseMessage(){//发电量
            this.$api.monitor.getBaseMessage().then(res =>{
                if(res.code ==20000){
                    this.capacity = res.data.capacity
                    this.deviceCount = res.data.deviceCount||0
                    this.stationNum = res.data.stationNum
                    this.dayGeneratingCapacity = res.data.dayGeneratingCapacity
                    this.tba = res.data.tba
                }
            }).catch(error=>{})
        },
    },
    beforeDestroy(){
        clearInterval(this.time)
        this.time = null
    }
};
</script>

<style lang="scss" scoped>
.icon-circle {
    background-image: url(@/assets/images/screem/round-wrap.png);
}
// 装机容量
.icon-contain {
    background-image: url(@/assets/images/screem/icon-contain.png);
}
// 场站数量
.icon-farmcount {
    background-image: url(@/assets/images/screem/icon-farmcount.png);
}
// 装机台数
.icon-turcount {
    background-image: url(@/assets/images/screem/icon-turcount.png);
}
// 日发电量
.icon-daypower {
    background-image: url(@/assets/images/screem/icon-daypower.png);
}
// 可利用率
.icon-availbility {
    background-image: url(@/assets/images/screem/icon-availbility.png);
}
.info-wrap {
    display: flex;
    justify-content: space-around;
    font-size: 15px;
    padding: 0 40px;
    .info-item {
        display: flex;
        .icon-wrap {
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 46px;
            height: 46px;
            .icon-circle {
                position: absolute;
                width: inherit;
                height: inherit;
                animation: moving 3s linear infinite;
            }
            .move1 {
                animation-delay: 0.2s;
            }
            .move2 {
                animation-delay: 0.4s;
            }
            .move3 {
                animation-delay: 0.6s;
            }
            .move4 {
                animation-delay: 0.8s;
            }
            .icon-inner {
                width: 36px;
                height: 36px;
            }
        }
        .info {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            margin-left: 12px;
            line-height: 15px;
            .name {
                font-weight: bold;
            }
            .v-wrap {
                .value {
                    font-weight: bold;
                }
                .unit {
                    font-weight: bold;
                    font-size: 12px;
                    transform: scale(0.833);
                }
            }
        }
    }
}
@keyframes moving {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
</style>