import { render, staticRenderFns } from "./block.vue?vue&type=template&id=6cdb058c&scoped=true"
import script from "./block.vue?vue&type=script&lang=js"
export * from "./block.vue?vue&type=script&lang=js"
import style0 from "./block.vue?vue&type=style&index=0&id=6cdb058c&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/.pnpm/vue-loader@15.11.1_vfczcwh3mga3knrujo6aj6bjq4/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6cdb058c",
  null
  
)

export default component.exports