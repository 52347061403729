import {
    post,
    get,
    post_old,
    postExportFile,
    postExport,
    Delete_old,
    Delete,

} from "../request"

export default {
    //场站
    getStationList(data) {
        return post_old('/monitor/matrix/queryStationSearchList', data);
    },
    //设备
    getDeviceList(data) {
        return post_old('/report/reportTemplate/queryDeviceList', data);
    },
    //chart位置
    getChartPostion(data) {
        return post_old('/station/enum', data);
    },

    //自定义报表-查询报表数据
    getReportData(data) {
        return post_old('/report/selfDefinedReport/queryReportData', data);
    },
    //自定义报表-查询报表list
    getReportList(data) {
        return post_old('/report/selfDefinedReport/vagueQueryReport', data);
    },
    //导出
    exportReport(data) {
        return postExport('/report/selfDefinedReport/exportReportDataListAndChart', data);
    },

    //自定义报表-查询图表--折线图-柱状图
    getReportChart(data) {
        return post('/report/selfDefinedReport/queryReportLineBar', data);
    },
    //自定义报表-查询图表--饼图
    getReportChartPie(data) {
        return post('report/selfDefinedReport/queryReportPie', data);
    },
    //自定义报表-查询图表--x轴
    getReportChartXYAxis(data) {
        return post_old('/report/selfDefinedReport/queryReportChartXYAxisList', data);
    },

    //基础报表--报表中心-发电量统计
    getElectricity(data) {
        return post('/report/electricityStatistics', data);
    },
    //导出
    exportElectricity(data) {
        return postExportFile('/report/exportElectricityStatistics', data);
    },

    //基础报表--报表中心--风机性能统计
    getfanPerformance(data) {
        return post('/report/fanPerformanceStatistics', data);
    },
    //导出
    exportFanPerformance(data) {
        return postExportFile('/report/energy/exportFanPerformanceStatistics', data);
    },

    //基础报表--报表中心--故障统计
    getFaultStatistics(data) {
        return post('/report/faultStatistics', data);
    },
    //导出
    exportFaultStatistics(data) {
        return postExportFile('/report/energy/exportFaultStatistics', data);
    },

    //基础报表--报表中心--光伏统计
    getPV(data) {
        return post('/report/photovoltaicStatistics', data);
    },
    //导出
    exportPV(data) {
        return postExportFile('/report/energy/exportPhotovoltaicStatistics', data);
    },


    //-------------------报表模板管理---------------------
    //报表模板管理-查询报表
    getReportTemplate(data) {
        return post('/report/reportTemplate/queryReportList', data);
    },
    //报表模板管理-查询报表详情
    getReportDetail(data) {
        return post_old('/report/reportTemplate/queryReportDetail', data);
    },
    //报表模板管理-查询标签点列表(kpi)
    getKpiList(data) {
        return post('/report/reportTemplate/queryKpiList', data);
    },
    // 报表模板管理-新增-编辑报表
    editReportTemplate(data) {
        return post('/report/reportTemplate/editReport', data);
    },
    //报表模板管理-删除报表
    delReportTemplate(data) {
        return Delete_old('/report/reportTemplate/delReport', data);
    },


    //-------------------计划电量填报---------------------

    //计划电量填报-查询报表详情
    getPlanPower(data) {
        return post('/plan/queryPlanPowerYearService', data);
    },
    //新增计划电量填报
    addPlanPower(data) {
        return post('/plan/addPlanPowerYearService', data);
    },
    //删除计划电量填报
    deletePlanPower(data) {
        return post('/plan/deletePlanPowerYearService', data);
    },
    //编辑计划电量填报
    editPlanPower(data) {
        return post('/plan/editPlanPowerYearService', data);
    },

    // --------------------------自由趋势图-------------------------
    //机组矩阵
    queryCurveSetMatrix(data) {
        return post('/report/freeTendency/queryCurveSetMatrix', data);
    },
    //测点矩阵
    queryCurveMapMatrix(data) {
        return post('/report/freeTendency/queryCurveMapMatrix', data);
    },
    //曲线
    queryCurve(data) {
        return post('/report/freeTendency/queryCurve', data);
    },
    //查询table
    queryCurveList(data) {
        return post('report/freeTendency/queryCurveList', data);
    },
    //查询模板列表
    queryTemplateList(data) {
        return post('/report/freeTendency/queryTemplateList', data);
    },
    //新增-编辑模板
    editTemplate(data) {
        return post('/report/freeTendency/editTemplate', data);
    },
    //删除模板
    delTemplate(data) {
        return Delete_old('/report/freeTendency/delTemplate', data);
    },
    //测导出曲线列表
    exportCurveList(data) {
        return postExportFile('/report/freeTendency/exportCurveList', data);
    },

    // --------------------------自动抄表-------------------------
    //设备类型下拉框
    getDeviceTypeList(data) {
        return get('/report/queryDevice', data);
    },
    //设备字段
    getDeviceFiled(data) {
        return post_old('/report/queryDeviceFiled', data);
    },
    //设备
    getDeviceModel(data) {
        return post_old('/report/queryDeviceModel', data);
    },
    //设备名称
    getDeviceName(data) {
      return post_old('/monitor/stationDetails/queryDeviceSearchList', data);
    },
    //查询
    getReportPasteData(data) {
        return post('/report/pageMeteReading', data);
    },
    //导出日报
    exportReportPaste(data) {
        return get('/report/queryDevice', data);
    },
    //导出记录
    getExportHistory(data) {
        return post('/report/export/pageExportHistory', data);
    },
    //删除记录
    deleteExportList(data) {
        return get('/report/export/delExportHis', data);
    },
    //导出
    ExportList(data) {
        return postExportFile('/report/exportData', data);
    },
    //导出
    checkDetailFile(data) {
        return post('/report/export/checkDetailFile', data);
    },
}