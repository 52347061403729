/*
 * @Author: liumeikun
 * @Date: 2023-09-20 18:29:18
 * @LastEditors: Do not edit
 * @LastEditTime: 2023-10-27 18:23:58
 * @filePath: Do not edit
 */
export default [{
        path: 'realTimeAlarm',
        title: '实时告警',
        name: "realTimeAlarm",
        component: () =>
            import ( /* webpackChunkName: "faultAlarm" */ '../views/faultAlarm/RealTimeAlarm.vue'),
        meta: {
            title: "实时告警",
            keepAlive: true
        },
    },
    {
        path: 'historicalAlarm',
        title: '历史告警',
        name: "historicalAlarm",
        component: () =>
            import ( /* webpackChunkName: "faultAlarm" */ '../views/faultAlarm/HistoricalAlarm.vue'),
        meta: {
            title: "历史告警",
            keepAlive: true
        },
    },
    {
        path: 'customAlarm',
        title: '自定义告警',
        name: "customAlarm",
        component: () =>
            import ( /* webpackChunkName: "faultAlarm" */ '../views/faultAlarm/customAlarm.vue'),
        meta: {
            title: "自定义告警",
            keepAlive: true
        },
    },
]