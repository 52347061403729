import {
    post,
    get,
    post_old,
    get_old,
    postExportFile
} from "../request"

export default {
    //历史告警-告警等级list
    getWarnLevelList(data) {
        return post_old('/station/enum', data);
    },
    //历史告警-告警状态list
    getWarnStatusList(data) {
        return post_old('/station/enum', data);
    },
    //历史告警-查询列表
    getHistoryWarnList(data) {
        return post('/fault/history/queryHistoryWarnList', data);
    },
    //历史告警--导出
    exportHistoryWarnList(data) {
        return postExportFile('/fault/history/exportHistoryWarnList', data);
    },


    // ---------------------共用-----------------------------
    //实时告警--操作
    doOperate(data) {
        return post('/fault/warn/editOperate', data);
    },
    //实时告警-查询列表list
    getRealTimeWarnList(data) {
        return post('/fault/realtime/queryRealTimeWarnList', data);
    },
    //实时告警-统计告警
    getFaultList(data) {
        return get('/fault/realtime/getRealTimeWarnCount', data);
    },
    //告警详情
    getAlarmDetails(data) {
        return get('/fault/warn/queryAlarmInfo', data);
    },
    // ---------------------共用-----------------------------


    //自定义告警-规则配置-查询
    getCustomAlarm(data) {
        return post('/alarm/pageCustomAlarm', data);
    },
    //自定义告警-规则配置-新增
    addCustomAlarm(data) {
        return post('/alarm/addCustomAlarm', data);
    },
    //自定义告警-规则配置-编辑
    editCustomAlarm(data) {
        return post('/alarm/editCustomAlarm', data);
    },
    //自定义告警-规则配置-详情
    getCustomAlarmDetail(data) {
        return get('/alarm/getCustomAlarmDetail', data);
    },
    //自定义告警-规则配置-删除
    deleteCustomAlarm(data) {
        return post_old('/alarm/deleteCustomAlarm', data);
    },
    //自定义告警-规则配置-io测点
    getTurbineTag(data) {
        return post('/alarm/getTurbineTagList', data);
    },
    //自定义告警-规则配置-规则名称下拉框
    getCustomRuleName(data) {
        return get('/alarm/getCustomAlarmBox', data);
    },
    //自定义告警-规则配置-规则名称唯一校验
    getvalidRuleName(data) {
        return get('/alarm/validRuleName', data);
    },


    //大屏首页告警信息
    getAlarmMessage(data) {
        return get('/fault/warn/queryAlarmList', data);
    },
    //大屏首页告警语音播放
    getReadVoice(id) {
        return get_old(`/alarm/warn/readVoice/${id}`);
    },

    // --------------------租户账号页面-------------
    //告警推送配置-查询
    getAlarmPushConfig(data) {
        return get('/alarm/warn/queryVoiceList', data);
    },
    //告警推送配置-修改
    editAlarmPushConfig(data) {
        return get('/alarm/warn/editVoice', data);
    },
}